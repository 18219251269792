import React, { useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { Dayjs } from 'dayjs';
import { TextField } from '@mui/material';
import Modal from '../../../../components/modal/Modal';

interface FormData {
  borewellId: string;
  meterSerialNo: string;
  lastCalibrationDate: Dayjs | null;
  dateOfInstallation: Dayjs | null;
  emailId: string;
}

function InstallationDetails() {
  const [formData, setFormData] = useState<FormData>({
    borewellId: '',
    meterSerialNo: '',
    lastCalibrationDate: null,
    dateOfInstallation: null,
    emailId: '',
  });
  const [showPopup, setShowPopup] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleIconClick = () => {
    setShowPopup(true);
  };

  const handleCloseClick = () => {
    setShowPopup(false);
  };

  const handleSaveClick = () => {
    console.log('this is handle save click');
  };

  const handleDateChange =
    (name: 'lastCalibrationDate' | 'dateOfInstallation') =>
    (date: Dayjs | null) => {
      setFormData((prevFormData) => ({ ...prevFormData, [name]: date }));
    };

  const displayFields = [
    { label: 'BOREWELL ID', value: formData.borewellId },
    { label: 'METER SERIAL NO', value: formData.meterSerialNo },
    {
      label: 'LAST CALIBRATION DATE',
      value: formData.lastCalibrationDate?.format('DD/MM/YYYY'),
    },
    {
      label: 'DATE OF INSTALLATION',
      value: formData.dateOfInstallation?.format('DD/MM/YYYY'),
    },
    { label: 'EMAIL ID', value: formData.emailId },
  ];

  return (
    <div className="bg-white shadow-md  border border-solid border-gray-300">
      <div className="px-4 text-[#333333] py-6">
        <div className="flex">
          <div className="w-[100%] h-auto">
            <h2 className="text-lg font-bold">Installation Details</h2>
          </div>
          <div className="cursor-pointer" onClick={handleIconClick}>
            <EditIcon />
          </div>
        </div>

        {/* Display Installation Details */}
        <div>
          {displayFields.map((field, index) => (
            <div key={index} className="flex mt-1 py-2">
              <span className="block text-xs font-medium leading-8">
                {field.label}:
              </span>
              <span className="block text-xs font-medium leading-8 ml-auto">
                {field.value || ''}
              </span>
            </div>
          ))}
        </div>
      </div>

      {/* Modal for editing details */}
      <Modal toggleModal={showPopup} onClose={handleCloseClick}>
        <div className="p-6">
          <div className="flex">
            <div className="w-[96%]">
              <p className="text-lg font-sans-serif text-[20px]">
                Edit Installation Details
              </p>
            </div>
            <div className="flex justify-end">
              <button
                className="text-gray-500 hover:text-gray-800"
                onClick={handleCloseClick}
              >
                <CloseIcon />
              </button>
            </div>
          </div>

          <div className="mt-3">
            <TextField
              name="borewellId"
              label="Borewell ID"
              value={formData.borewellId}
              onChange={handleChange}
              className="mt-2 w-full"
            />
            <div className="flex gap-4 mt-2">
              <TextField
                name="meterSerialNo"
                label="Meter Serial No"
                value={formData.meterSerialNo}
                onChange={handleChange}
                className="mt-2 w-full"
              />
            </div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div className="flex gap-4 mt-2">
                <DatePicker
                  label="Last Calibration Date"
                  value={formData.lastCalibrationDate}
                  onChange={handleDateChange('lastCalibrationDate')}
                />
                <DatePicker
                  label="Date Of Installation"
                  value={formData.dateOfInstallation}
                  onChange={handleDateChange('dateOfInstallation')}
                />
              </div>
            </LocalizationProvider>
            <div className="mt-2">
              <TextField
                name="emailId"
                label="Email ID"
                value={formData.emailId}
                onChange={handleChange}
                className="mt-2 w-full"
              />
            </div>

            <div className="mt-14 flex justify-end">
              <button
                className="border py-2 px-4 rounded-md font-sans-serif text-[14px]"
                onClick={handleCloseClick}
              >
                Cancel
              </button>
              <button
                className="border py-2 px-6 rounded-md ml-4 font-sans-serif text-[14px]"
                onClick={handleSaveClick}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default InstallationDetails;
