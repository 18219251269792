import React, { lazy, Suspense, useEffect, useState } from 'react'
import SearchAndActions from '../../../components/search-action/SearchAndActions'
import Modal from '../../../components/modal/Modal';
import { useAlertState } from '../../../app-context/alert-context/AlertState';
import { setAlert } from '../../../app-context/alert-context/AlertAction';
import { useCurrentUserState } from '../../../app-context/current-user-context/CurrentUserLoginState';
import { RoleBack } from '../../../types/Role';
import Loader from '../../../components/loader/Loader';
import { usePermissionState } from '../../../app-context/permission-context/PermissionState';
const LazyAddNewRealTimeAlert = lazy(() => import('./components/AddNewRealTimeAlert'))
const RealTimeAlertTable = lazy(() => import('./components/RealTimeAlertTable'));

const RealTimeAlert = () => {
  const { permissions } = usePermissionState()
  const canCreate = permissions?.alert?.canCreate || false
  const {dispatch,loading}=useAlertState()
  const {currentUser}=useCurrentUserState()
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [addRealTimeAlertModal, setAddRealTimeAlertModal] = useState(false)


  useEffect(()=>{
    setAlert(dispatch, {
      page: 1,
      limit: 10,
      organizationId: currentUser.organizationId || '',
      vendorId: currentUser.vendorId || '',
      userId: currentUser.role.name === RoleBack.USER ? currentUser._id : '',
      role: currentUser.role.name,
    })
  },[])
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleAddNew = () => {
    setAddRealTimeAlertModal(true);
  };

  const handleExport = () => {
    // Logic for exporting data
  };

  return (
    <div className="p-1 py-2">
      {loading &&   <Loader showGradient={loading}  />}
      <SearchAndActions
        searchTerm={searchTerm}
        onSearchChange={handleSearchChange}
        onAddNew={handleAddNew}
        onExport={handleExport}
        handelAdd={canCreate}
      />
      <Modal
        // sx={customSx}
        toggleModal={addRealTimeAlertModal}
        onClose={() => {
          setAddRealTimeAlertModal(false)
        }}
      >
        <Suspense fallback={<div>Loading...</div>}>
          <LazyAddNewRealTimeAlert
            onClose={() => {
              setAddRealTimeAlertModal(false)
            }}
            action='ADD_NEW'
          />
        </Suspense>
      </Modal>
      <Suspense fallback={<div>Loading...</div>}>
        <RealTimeAlertTable />
      </Suspense>
    </div>
  )
}

export default RealTimeAlert