import React from 'react';

// Define the types of status available
type Status = 'inactive' | 'pending' | 'active' | 'outstanding' | 'paid' | 'N/A' | 'Pending' | 'Resolved' | 'Closed' | 'Not Started' | 'Suspended';

// Define status styles with Tailwind classes
const statusStyles: Record<Status, { backgroundColor: string; textColor: string }> = {
  inactive: {
    backgroundColor: 'bg-gray-100',
    textColor: 'text-gray-500'
  },
  pending: {
    backgroundColor: 'bg-yellow-100',
    textColor: 'text-yellow-600'
  },
  active: {
    backgroundColor: 'bg-green-100',
    textColor: 'text-green-500'
  },
  outstanding: {
    backgroundColor: 'bg-orange-100',
    textColor: 'text-orange-500'
  },
  paid: {
    backgroundColor: 'bg-blue-100',
    textColor: 'text-blue-500'
  },
  "N/A": {
    backgroundColor: 'bg-purple-100',
    textColor: 'text-purple-500'
  },
  Pending: {
    backgroundColor: 'bg-yellow-100',
    textColor: 'text-yellow-700'
  },
  Resolved: {
    backgroundColor: 'bg-green-100',
    textColor: 'text-green-600'
  },
  Closed: {
    backgroundColor: 'bg-gray-100',
    textColor: 'text-gray-700'
  },
  "Not Started": {
    backgroundColor: 'bg-red-100',
    textColor: 'text-red-600'
  },
  Suspended: {
    backgroundColor: 'bg-purple-100',
    textColor: 'text-purple-600'
  }
};

interface StatusBadgeProps {
  status: Status;
}

const StatusBadge: React.FC<StatusBadgeProps> = ({ status }) => {
  const { backgroundColor, textColor } = statusStyles[status] || statusStyles.inactive;

  return (
    <span
      className={`inline-flex items-center justify-center w-24 h-8 rounded-lg text-sm font-semibold ${backgroundColor} ${textColor}`}
    >
      {status} {/* Display status directly as it is */}
    </span>
  );
};

export default StatusBadge;
