import React from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

interface SeriesData {
  name: string;
  data: number[];
  color?: string; // Color for the line
  yAxis?: number[];
  type?: string; // 'line', 'spline', 'column', etc.
  tooltip?: {
    valueSuffix?: string;
  };
  dataLabels?: boolean;
}

interface LineChartProps {
  title?: string;
  categories: string[];
  series: SeriesData[];
  xAxisTitle?: string;
  yAxisTitlePrimary?: string;
  yAxisTitleSecondary?: string;
  height?: number;
}

const DualAxisLineChart: React.FC<LineChartProps> = ({
  title,
  categories,
  series,
  height,
  yAxisTitlePrimary,
  yAxisTitleSecondary,
  xAxisTitle,
}) => {
  const formattedSeries = series.map((serie) => ({
    ...serie,
    data: serie.data.map((value) => {
      return typeof value === 'string' ? Number(value) : value;
    }),
  }));
  const chartOptions: Highcharts.Options = {
    chart: {
      type: 'line',
      height: height,
      zooming: {
        type: 'xy', // Enable zooming for both x and y axes
      },
    },
    title: {
      text: title,
    },
    credits: {
      enabled: false, // Disable watermark
    },
    xAxis: {
      categories: categories,
      title: {
        text: xAxisTitle || 'Categories',
      },
      crosshair: true,
    },
    yAxis: [
      {
       
        title: {
          text: yAxisTitlePrimary || 'Primary Axis',
        },
        labels: {
          format: '{value}',
        },
      },
      {
       
        title: {
          text: yAxisTitleSecondary || 'Secondary Axis',
        },
        labels: {
          format: '{value}',
        },
        opposite: true,
      },
    ],
    tooltip: {
      shared: true,
      formatter: function (): string {
        const points = this.points;
        if (!points) return '';

        return points
          .map((point) => {
            const value = point.y !== undefined ? point.y : 'N/A';
            return `${point.series.name}: ${value}`;
          })
          .join('<br/>');
      },
    },
    series: formattedSeries.map((s) => ({
      name: s.name,
      data: s.data,
      color: s.color || '#000000',
      yAxis: s.yAxis ?? 0, 
      type: s.type || 'line',
      tooltip: {
        valueSuffix: s.tooltip?.valueSuffix || '',
      },
      dataLabels: s.dataLabels
        ? {
            enabled: true,
            formatter: function (this: Highcharts.Point): string {
              return this.y !== undefined ? this.y.toString() : 'N/A';
            },
          }
        : undefined,
    })) as Highcharts.SeriesOptionsType[],
  };

  return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
};

export default DualAxisLineChart;


// const seriesData = [
//   {
//     name: 'Temperature',
//     data: [22, 25, 23, 21, 24], // Dummy temperature data
//     color: '#117DAE', // Color for the line
//     yAxis: 0, // Use primary Y-Axis for Temperature
//     tooltip: {
//       valueSuffix: '°C', // Add °C suffix for Temperature
//     },
//     dataLabels: true, // Enable data labels
//   },
//   {
//     name: 'Humidity',
//     data: [70, 65, 72, 75, 68], // Dummy humidity data
//     color: '#DD0000', // Color for the line
//     yAxis: 0, // Use primary Y-Axis for Humidity
//     tooltip: {
//       valueSuffix: '%', // Add % suffix for Humidity
//     },
//     dataLabels: true, // Enable data labels
//   },
//   {
//     name: 'Trigger Status',
//     data: [0, 1, 0, 1, 0], // Dummy binary trigger status data (0 = OFF, 1 = ON)
//     color: '#28A745', // Color for the line
//     yAxis: 1, // Use secondary Y-Axis for Trigger Status
//     tooltip: {
//       valueSuffix: '', // No suffix for Trigger Status
//     },
//     dataLabels: true, // Enable data labels
//   },
// ];