import React, {
  createContext,
  useReducer,
  ReactNode,
  Dispatch,
  useContext,
} from 'react'
import UserReducer, { UserState } from './UserReducer'
import { UserAction } from './UserAction'

interface UserContextProps {
  state: UserState
  dispatch: Dispatch<UserAction>
}

const initialState: UserState = {
  user: {
    limit: 0,
    user: [],
    currentPage: 0,
    totalPages: 0,
    previousPage: 0,
    totalItems: 0,
  },
  loading: false
}

export const UserContext = createContext<UserContextProps>({
  state: initialState,
  dispatch: () => {},
})

interface UserProviderProps {
  children: ReactNode
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(UserReducer, initialState)

  // useEffect(() => {
  //   setUser(dispatch, { page: 1, limit: 10 }) // Call setOrganization only once when the component mounts
  // }, []) // Empty dependency array ensures it only runs on mount, not on updates

  return (
    <UserContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export const useUserState = () => {
  const { state, dispatch } = useContext(UserContext)
  return { ...state, dispatch }
}
