import { TagData } from '../../types/Tag'
import { TagAction } from './TagAction'

export interface TagState {
  tag: TagData,
  loading: boolean
}

export default function TagReducer(
  state: TagState,
  action: TagAction,
): TagState {
  const { type, payload } = action

  switch (type) {
    case 'SET_LOADING':
      return {
        ...state,
        loading: payload,
      };
    case 'SET_TAG': {
      return { ...state, tag: payload,loading:false }
    }
    case 'ADD_TAG': {
      const { tag: newTag, totalItems } = payload
      //   let organizat
      //   if (
      //     state.organizations.organizations.length === 10 ||
      //     state.organizations.organizations.length === 25 ||
      //     state.organizations.organizations.length === 5
      //   ) {
      //     organizat = state.organizations.organizations.slice(0, -1)
      //   } else {
      //     organizat = state.organizations.organizations
      //   }

      const deviceName = newTag.deviceName || '';
      return {
        ...state,
        tag: {
          ...state.tag,
          tag: [{ ...newTag, deviceName }, ...state.tag.tag],
          totalItems: totalItems + 1
        },
      };
    }
    case 'UPDATE_TAG': {
      const { id: updateId, tag } = payload
      const updatedTag = state.tag.tag.map((t) =>
        t._id === updateId ? { ...t, ...tag } : t,
      )
      return {
        ...state,
        tag: {
          ...state.tag,
          tag: updatedTag,
        },
      }
    }
    default:
      return state
  }
}
