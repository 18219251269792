import React, {
    createContext,
    useReducer,
    ReactNode,
    Dispatch,
    useContext,
} from 'react'
import RouteReducer, { RouteState } from './RouteReducer'
import { RouteAction } from './RouteAction'



interface RouteContextProps {
    state: RouteState
    dispatch: Dispatch<RouteAction>
}

const initialState: RouteState = {
    route: {
        route: '',
    },
}

export const RouteContext = createContext<RouteContextProps>({
    state: initialState,
    dispatch: () => { },
})

interface RouteProviderProps {
    children: ReactNode
}

export const RouteProvider: React.FC<RouteProviderProps> = ({ children }) => {
    const [state, dispatch] = useReducer(RouteReducer, initialState)

    // useEffect(() => {
        // setDevice(dispatch, {page: 1, limit: 10}) // Call setOrganization only once when the component mounts
    // }, []) // Empty dependency array ensures it only runs on mount, not on updates

    return (
        <RouteContext.Provider
            value={{
                state,
                dispatch,
            }}
        >
            {children}
        </RouteContext.Provider>
    )
}

export const useRouteState = () => {
    const { state, dispatch } = useContext(RouteContext)
    return { ...state, dispatch }
}
