import { useEffect } from 'react';
import { useCurrentUserState } from '../../app-context/current-user-context/CurrentUserLoginState';
import { RoleBack } from '../../types/Role';
import SuperAdminOverview from './superadmin-overview/SuperAdminOverview';
import { useOverviewState } from '../../app-context/overview-context/OverviewState';
import { setOverviewSuperAdmin } from '../../app-context/overview-context/OverviewAction';
import UserDashboard from './end-user-overview/UserDashboard';
import Loader from '../../components/loader/Loader';
import VendorOverview from './vendor-overview/VendorOverview';
import OrganizationOverview from './organization-overview/OrganizationOverview';
// import AwfisDashboard from './end-user-awfis-dashboard/AwfisDashboard';
// import SubadminOverview from './superadmin-overview/SubadminOverview';

const Overviews = () => {
  const { currentUser ,loaded:loadCurrentUser} = useCurrentUserState();
  const { dispatch, loading,loaded } = useOverviewState()



  useEffect(() => {
    if (
      !loaded && 
      loadCurrentUser && 
      currentUser.role.name !== RoleBack.USER && 
      currentUser.role.name !== RoleBack.SITEADMIN
    ){
      setOverviewSuperAdmin(dispatch, { organizationId: currentUser.organizationId, vendorId: currentUser.vendorId, userId: currentUser._id, role: currentUser.role.name })
    }
    
  }, [loadCurrentUser])
  if (!currentUser || !currentUser.role || !currentUser.role.name) {
    return null;
  }

  return (
    <>
      {currentUser.role.name === RoleBack.ORGANIZATIONADMIN && (
      <OrganizationOverview />
    )}
     {loading && <Loader showGradient={loading}  />}
      {currentUser.role.name === RoleBack.SUPERADMIN && (
        <SuperAdminOverview />
      )}
      {currentUser.role.name === RoleBack.USER && (
        <UserDashboard />
      )}
      {currentUser.role.name === RoleBack.SITEADMIN && (
      <VendorOverview />
    )}

{![
        RoleBack.ORGANIZATIONADMIN,
        RoleBack.SUPERADMIN,
        RoleBack.SITEADMIN,
        RoleBack.USER,
      ].includes(currentUser.role.name as RoleBack) && (
        <SuperAdminOverview />
      )}
    </>
  )
}

export default Overviews