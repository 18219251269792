import React, { useState } from 'react';
import axios from 'axios';
import { baseUrlDeviceLog } from '../../../utils/env/env.config';
import { useLocation, useParams } from 'react-router-dom';

const FileUploadForm: React.FC = () => {
  const [file, setFile] = useState<File | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [uploadStatus, setUploadStatus] = useState<string | null>(null);
  const [progress, setProgress] = useState<number>(0);
  const { _id } = useParams();
  const location = useLocation();
  const { state: deviceState } = location;
  // SensorData.ts
  interface SensorData {
    displaySensorName: string;
    scalingFactor: number;
    sensorAlias: string;
    sensorUnit: string;
    status: boolean;
    tagId: string;
    tagName: string;
    unit: string;
    value: number; // Assuming the value is a number
  }


  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      const fileType = selectedFile.name.split('.').pop();
      if (fileType === 'csv' || fileType === 'xlsx') {
        setFile(selectedFile);
        setError(null);
      } else {
        setError('Please upload a valid CSV or Excel file.');
        setFile(null);
      }
    }
  };

  

  const handleFileUpload = async () => {
    if (file) {
      try {
        setUploadStatus("Uploading...");
        const formData = new FormData();
        formData.append("file", file);

        await axios.post(`${baseUrlDeviceLog}csv-upload?_id=${_id}`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
          onUploadProgress: (progressEvent) => {
            console.log(progressEvent);

            if (progressEvent.total) {
              const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              setProgress(percentCompleted);
              setUploadStatus(`Uploading: ${percentCompleted}%`);
            }
          },
        });

        setUploadStatus("File uploaded successfully!");
      } catch (err) {
        console.error("Upload error:", err);
        setUploadStatus("File upload failed.");
      }
    }
  };
  const defaultData = [
    { tagName: 'timeStamp', value: '2024-01-16 12:02:01' },
    { tagName: 'firmwareVersionId', value: 'V1' },
    { tagName: 'rssi', value: 1 },
  ];

  const sensorDataArray = deviceState.tags as SensorData[]
  const combinedData = [...defaultData, ...sensorDataArray];


  return (
    <>
      <div className="max-w-3xl mx-auto mt-10 p-6 bg-white shadow-md rounded-md"> {/* Increased max width */}
        <h2 className="text-2xl font-semibold text-gray-700 text-center mb-6">Upload CSV or Excel File</h2>
        <form className="space-y-4">
          <div>
            <label className="block text-gray-600 text-sm font-medium mb-2">Select File</label>
            <input
              type="file"
              accept=".csv, .xlsx"
              onChange={handleFileChange}
              className="block w-full text-sm text-gray-500
                file:mr-4 file:py-2 file:px-4
                file:rounded-full file:border-0
                file:text-sm file:font-semibold
                file:bg-blue-50 file:text-blue-700
                hover:file:bg-blue-100"
            />
            {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
          </div>
          <div className="text-center">
            <button
              type="button"
              onClick={handleFileUpload}
              className="w-full px-4 py-2 bg-blue-600 text-white rounded-md shadow hover:bg-blue-700 disabled:bg-gray-300 transition duration-200"
              disabled={!file}
            >
              Upload
            </button>
          </div>
          {uploadStatus && <p className="text-center mt-4 text-gray-600">{uploadStatus}</p>}
          {progress > 0 && <p className="text-center mt-4 text-gray-600">Progress: {progress}%</p>}
        </form>

        {/* Dummy Table for Expected Data Structure */}
      </div>

      <div className="overflow-x-auto p-2">
        <table className="min-w-full border border-gray-300 text-sm">
          <thead className="bg-gray-100">
            <tr>
              {combinedData.slice(0, 5).map((sensor, index) => ( // Show only first 5 columns
                <th key={index} className="border px-4 py-2 text-center font-medium text-gray-700 hover:bg-gray-200 transition duration-200">
                  {sensor.tagName}
                </th>
              ))}
              {combinedData.length > 5 && <th className="border px-4 py-2 text-center font-medium text-gray-700">More...</th>}
            </tr>
          </thead>
          <tbody>
            <tr>
              {combinedData.slice(0, 5).map((sensor, index) => (
                <td key={index} className="border px-4 py-2 text-center hover:bg-gray-50 transition duration-200">
                  {sensor?.value || 'data'}
                </td>
              ))}
              {combinedData.length > 5 && <td className="border px-4 py-2 text-center">...</td>}
            </tr>
          </tbody>
        </table>
        <span className='text-red-400 flex justify-center'>Note: (firmwareVersionId,rssi) are optional!! </span>
      </div>

    </>


  );
};

export default FileUploadForm;
