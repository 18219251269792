import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

interface SeriesData {
  name: string;
  data: number[];
  color?: string; // Optional color property
}

interface BarChartProps {
  title: string;
  categories: string[];
  series: SeriesData[];
  height?: number; // Optional height property
  // width?: number;  // Optional width property
  xAxisTitle?: string; // Prop for x-axis title
  yAxisTitle?: string; // New prop for y-axis title
}

const BarChart: React.FC<BarChartProps> = ({
  title,
  categories,
  series,
  height = 250,
  xAxisTitle = "Data", // Default value for x-axis title
  yAxisTitle = 'Values', // Default value for y-axis title
}) => {
  const options = {
    chart: {
      type: 'column', // Use 'column' for vertical bars
      style: {
        fontFamily: 'Arial, sans-serif', // Optional: Set font style
      },
      height: height,
    },
    title: {
      text: title,
    },
    accessibility: {
      enabled: false,
    },
    xAxis: {
      categories: categories,
      title: {
        text: xAxisTitle,
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: yAxisTitle,
      },
    },
    series: series,
    credits: {
      enabled: false, // Disable the watermark
    },
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default BarChart;



// const dummyData = {
//   title: 'Sales Overview',
//   categories: ['January', 'February', 'March', 'April', 'May'],
//   series: [
//     {
//       name: 'Product A',
//       data: [30, 20, 50, 40, 60],
//       color: '#117DAE', // Optional color
//     },
//     {
//       name: 'Product B',
//       data: [40, 30, 70, 60, 80],
//       color: '#A4A4A6', // Optional color
//     },
//   ],
// };

// // Example usage of BarChart with dummy data
// const ExampleUsage = () => (
//   <div>
//     <BarChart
//       title={dummyData.title}
//       categories={dummyData.categories}
//       series={dummyData.series}
//       height={300}
//       xAxisTitle="Months"
//       yAxisTitle="Sales"
//     />
//   </div>
// );

// export default ExampleUsage;
