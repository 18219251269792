import InputField from '../../../components/input-field/InputField';
import Button from '../../../components/button/Button';
import Container from '../../../components/container/Container';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { baseUrlUser } from '../../../utils/env/env.config';
import { login } from '../../../app-context/current-user-context/CurrentLoginUserAction';
import { useCurrentUserState } from '../../../app-context/current-user-context/CurrentUserLoginState';
import { LoginResponse } from '../../../types/CurrentLoginUser';
import LinearIndeterminate from '../../../ui/linear-loader/LinearLoader';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useToast } from '../../../components/custom-toast/CustomToastProvider';

const LoginForm = () => {
  const [loginData, setLoginData] = useState({
    email: '',
    password: '',
  });
  const { showToast } = useToast()
  const [showPassword, setShowPassword] = useState(false);
  const [submitting, setSubmitting] = useState(false)
  const { dispatch } = useCurrentUserState()
  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmitting(true)
    if (loginData.email === '' || loginData.password === '') {
      return;
    }
    try {
      const response = await axios.post<LoginResponse>(baseUrlUser + 'login', {
        email: loginData.email,
        password: loginData.password,
      });
      const { token, status } = response.data
      if (status === 401) {
      if (typeof response.data.message === 'string') showToast(response.data.message, 'error');
      }
      if (token) {
        login({ dispatch, token })
        showToast("Login successful", "success")
      }
    } catch (error) {
      if (typeof error === 'string') showToast(error, 'error');
    }
    setSubmitting(false)
  };

  const handlePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword)
  }

  return (
    <Container heading="Login">
      <form onSubmit={handleLogin} className="w-full">
        <div className='h-2 mb-2'>
          {submitting && <LinearIndeterminate />}
        </div>
        <div className='space-y-4'>
          <InputField
            name="email"
            type="email"
            label="Email"
            placeholder="Enter your email"
            required
            value={loginData.email}
            onChange={(e) => setLoginData({ ...loginData, email: e.target.value })}
          />
          <div className="relative">
            <InputField
              name="password"
              type={showPassword ? 'text' : 'password'}
              label="Password"
              placeholder="Enter your password"
              required
              value={loginData.password}
              onChange={(e) => setLoginData({ ...loginData, password: e.target.value })}
            // Optional: Add a visibility toggle here if needed
            />
            <div className="absolute inset-y-0 right-0 flex items-center pr-2 mt-1 cursor-pointer">
              {showPassword ? (
                <VisibilityOff onClick={() => handlePasswordVisibility()} className="text-black" />
              ) : (
                <Visibility onClick={() => handlePasswordVisibility()} className="text-black" />
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-col sm:flex-row justify-between items-center space-y-4 sm:space-y-0">
          <Link to="/forgot-password" className="text-sm text-deepcerulan-600 hover:text-deepcerulan-500 underline">
            Forgot your password?
          </Link>
        </div>
        <div className="mt-2">
          <Button type="submit" variant="primary" size="medium">
            Login
          </Button>
        </div>
      </form>
    </Container>
  );
};

export default LoginForm;
