import React from 'react';

type Status = 'inactive' | 'pending' | 'active' | 'outstanding' | 'paid';

// Define status styles with Tailwind classes
const statusStyles: Record<Status, { backgroundColor: string; textColor: string }> = {
  inactive: {
    backgroundColor: 'bg-red-100', // Light red
    textColor: 'text-red-500'      // Red text
  },
  pending: {
    backgroundColor: 'bg-yellow-100', // Yellow
    textColor: 'text-yellow-600'      // Dark yellow text
  },
  active: {
    backgroundColor: 'bg-green-100', // Light green
    textColor: 'text-green-500'      // Green text
  },
  outstanding: {
    backgroundColor: 'bg-red-100', // Light red
    textColor: 'text-red-500'      // Red text
  },
  paid: {
    backgroundColor: 'bg-green-100', // Light green
    textColor: 'text-green-500'      // Green text
  }
};

interface StatusBadgeProps {
  status: Status;
}

const StatusBadge: React.FC<StatusBadgeProps> = ({ status }) => {
  const { backgroundColor, textColor } = statusStyles[status] || statusStyles.inactive;

  return (
    <span
      className={`inline-flex items-center justify-center w-24 h-8 rounded-lg text-sm ${backgroundColor} ${textColor}`}
    >
      {status.charAt(0).toUpperCase() + status.slice(1)} {/* Capitalize the first letter */}
    </span>
  );
};

export default StatusBadge;
