import { lazy, Suspense, useEffect, useRef, useState } from 'react'
import { useTagState } from '../../app-context/tag-context/TagState'
import { useCurrentUserState } from '../../app-context/current-user-context/CurrentUserLoginState'
import { setTag } from '../../app-context/tag-context/TagAction'
import SearchAndActions from '../../components/search-action/SearchAndActions';
import Modal from '../../components/modal/Modal';
import Loader from '../../components/loader/Loader';
import { usePermissionState } from '../../app-context/permission-context/PermissionState';
const TagTable = lazy(() => import('./components/TagTable'));
const LazyAddNewTagForm = lazy(() => import('./components/AddNewTagForm'))

const Tag = () => {
  const { permissions } = usePermissionState()
  const canCreate = permissions?.tag?.canCreate || false
  const { dispatch, tag,loading } = useTagState()
  const { currentUser } = useCurrentUserState()
  const [addTagModal, setAddTagModal] = useState(false)
  const [searchTerm, setSearchTerm] = useState<string>('');
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (tag.tag.length === 0) {
      setTag(dispatch, { page: 1, limit: 10, organizationId: currentUser.organizationId || '' })
    }
  }, [])

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout((): void => {
      setTag(dispatch, { page: 1, limit: 10, search: searchTerm, organizationId: currentUser.organizationId || '' })
  }, 500);
  };

  const handleAddNew = () => {
    setAddTagModal(true);
  };

  const handleExport = () => {
    // Logic for exporting data
  };

  return (
    <div className="p-1 py-2">
      {loading &&   <Loader showGradient={loading}  />}

      {/* <div className="p-1 py-2" style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}> */}
      {/* <div style={{ flexShrink: 0 }}> */}
      <SearchAndActions
        searchTerm={searchTerm}
        onSearchChange={handleSearchChange}
        onAddNew={handleAddNew}
        onExport={handleExport}
        handelAdd={canCreate}
      />
      {/* </div> */}
      <div>
        {/* <div style={{ flexGrow: 1, overflowY: 'auto' }}> */}
        <Suspense fallback={<div>Loading...</div>}>
          <TagTable />
        </Suspense>
      </div>
      <Modal
        // sx={customSx}
        toggleModal={addTagModal}
        onClose={() => {
          setAddTagModal(false)
        }}
      >
        <Suspense fallback={<div>Loading...</div>}>
          <LazyAddNewTagForm
            onClose={() => {
              setAddTagModal(false)
            }}
            action='ADD_NEW'
          />
        </Suspense>
      </Modal>
    </div>
  )
}

export default Tag