import dayjs from "dayjs";
import { OptionType } from "./Dropdown";
import { Subscription } from "./Subscription";
import { Scheduling } from "./Scheduling";
import { User } from "./User";
import { Organization } from "./organization";
import { Vendor } from "./Vender";


export interface BatteryConfig {
  minRangeBatteryValue?: number;
  maxRangeBatteryValue?: number;
}
export interface DeviceFormValues {
  organizationId: string;
  vendorId: string;
  userId: string;
  deviceName: string;
  imeiNumber: string;
  longitude: string;
  latitude: string;
  templateId: string;
  deviceAlias: string;
  connectionTimeInterval: number;
  communicationTimeInterval: number;
  deviceCategory: string;
  tags: TemplateTag[]
  slaveId: number;
  batteryConfig?: BatteryConfig | null;
  startDate: dayjs.Dayjs | null;
  duration: string;
  dueDate: string | null;
  subscription: string
  shippingDate: dayjs.Dayjs | null;
}


export interface Device {
  _id: string;
  organizationId?: string,
  tenantId?: string,
  vendorId?: string,
  userId: string,
  deviceName: string,
  imeiNumber: string,
  deviceAlias: string,
  longitude: string,
  latitude: string,
  templateId: string,
  connectionTimeInterval: number;
  communicationTimeInterval: number;
  organizationName: string;
  deviceCategory: string;
  createdAt?: string;
  updatedAt?: string;
  slaveId: number;
  tags: TemplateTag[]
  __v?: number;
  status?: boolean;
  batteryConfig?: BatteryConfig | null;
  vendorDetails?: Vendor
  organizationDetails?: Organization
  userDetails?: User
  siteName?: string
  userName?: string
  templateName?: string
  subscription: string;
  subscriptionDetails: Subscription;
  startDate: dayjs.Dayjs | null;
  duration: string;
  dueDate: null | string;
  shippingDate: dayjs.Dayjs | null;
  deviceData: { [key: string]: string | number};
  schedulingDetails?: Scheduling

}

export interface DeviceData {
  device: Device[];
  limit?: number;
  currentPage: number;
  totalPages: number;
  previousPage: number;
  totalItems: number;
}

export interface ApiResponseDevice {
  page: number;
  limit: number;
  data: DeviceData;
}

export interface CreateOrganizationResponse {
  data: Device;
}

export const optionsSlave: OptionType[] = [
  { label: 'Primary', value: 'Primary' },
  ...Array.from({ length: 32 }, (_, i) => {
    const value = (i + 1).toString();

    return {
      label: value,
      value: value
    };
  })
];



export interface TemplateTag {
  tagId: string;
  tagName: string;
  unit: string;
  scalingFactor: number;
  sensorAlias: string;
  status: boolean;
  displaySensorName: string;
  sensorUnit: string;
}


export interface DeviceDropdown {
  _id: string;
  label: string;
  value: string;
  tags: { label: string, value: string }[];

}

interface Data {
  device: DeviceDropdown[];
  currentPage: number | null;
  totalPages: number | null;
  previousPage: number | null;
  totalItems: number;
}


export interface ResponseDeviceDropdown {
  page: number | null;
  limit: number | null;
  data: Data;
}
