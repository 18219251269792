import React, {
  createContext,
  useReducer,
  ReactNode,
  Dispatch,
  useContext,
} from 'react'
import CurrentUserReducer, {
  CurrentLoginUserState,
} from './CurrentUserLoginReducer'
import {
  CurrentUserAction,
} from './CurrentLoginUserAction'

interface CurrentUserContextProps {
  state: CurrentLoginUserState
  dispatch: Dispatch<CurrentUserAction>
}

const initialState: CurrentLoginUserState = {
  currentUser: {
    _id: '',
    name: '',
    email: '',
    roleId: '',
    tenantId: '',
    clientId: '',
    organizationId: '',
    vendorId: '',
    status: '',
    role: {
      name: '',
      _id: '',
      description: '',
      clientId: '',
      dashBoardName: '',
      __v: 0
    }, // Assuming decodedToken.role already conforms to RoleDetails interface
    permissions: [],
    appLogo: '',
    appName:''
  },
  loaded: false
}

export const CurrentUserContext = createContext<CurrentUserContextProps>({
  state: initialState,
  dispatch: () => {},
})

interface DeviceProviderProps {
  children: ReactNode
}

export const CurrentUserProvider: React.FC<DeviceProviderProps> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(CurrentUserReducer, initialState)
  // useEffect(() => {
  //   if (window.localStorage.getItem('token')) {
  //     setCurrentUserLogin(dispatch)
  //   } else {
  //     logout(dispatch)
  //   }
  // }, [])

  return (
    <CurrentUserContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </CurrentUserContext.Provider>
  )
}

export const useCurrentUserState = () => {
  const { state, dispatch } = useContext(CurrentUserContext)
  return { ...state, dispatch }
}
