import { Route, Routes } from 'react-router-dom'
import Organization from '../modules/organization/Organization'
import NotFoundPage from '../ui/not-found/NotFoundPage'
import Venders from '../modules/venders/Venders'
import Devices from '../modules/devices/Devices'
import Users from '../modules/users/Users'
import Reports from '../modules/reports/Reports'
import Alerts from '../modules/alert/Alerts'
import MqttWatcher from '../modules/mqtt-watcher/MqttWatcher'
import Overviews from '../modules/overview/Overviews'
import Tag from '../modules/tag/Tag'
import Template from '../modules/template/Template'
import Role from '../modules/role/Role'
import TemplateDashboardRouting from '../modules/template-dashboard/TemplateDashboardRouting'
import RbackUpdate from '../modules/role/components/RbackUpdate'
import DeviceData from '../modules/device-data/DeviceData'
import MeterReading from '../modules/reports/generate-reports/MeterReading'
import ConsumptionReport from '../modules/reports/generate-reports/ConsumptionReport'
import RealTimeAlert from '../modules/alert/realtime-alert/RealTimeAlert'
import ConsumptionAlert from '../modules/alert/consumption-alert/ConsumptionAlert'
import Modbus_Config from '../modules/modbus-config/Modbus_Config'
import ConsumptionSummary2 from '../modules/reports/generate-reports/ConsumptionSummary2'
import StatisticsSummary from '../modules/reports/generate-reports/StatisticsSummary'
import CsvUpload from '../components/csv-upload/CsvUpload'
import AwfisDashboard from '../modules/overview/end-user-awfis-dashboard/AwfisDashboard'
// import UserDashboard from '../modules/overview/end-user-overview/UserDashboard'
import Scheduling from '../modules/scheduling/Scheduling'
import Ticketing from '../modules/ticketing/Ticketing'


const PermissionRouteContent = () => {

  return (
    <Routes>
      <Route
        path='/ac_dashboard'
        element={<AwfisDashboard />}
      />
      <Route
        path='/overview'
        element={<Overviews />}
      />
      <Route
        path='/organization'
        element={<Organization />}
      />

      <Route
        path='/venders'
        element={<Venders />}
      />


      <Route
        path='/devices'
        element={<Devices />}
      />


      <Route
        path='/users'
        element={<Users />}
      />


      <Route
        path='/alerts'
        element={<Alerts />}
      />

      <Route
        path='/alerts/real-time-alert'
        element={<RealTimeAlert />}
      />

      <Route
        path='/alerts/consumption-alert'
        element={<ConsumptionAlert />}
      />
      <Route
        path='/reports'
        element={<Reports />}
      />
      <Route path="/reports/meter-reading" element={<MeterReading/>} /> 
      <Route path="/reports/consumption" element={<ConsumptionReport/>} /> 
      <Route path='/reports/consumption-summary2' element={<ConsumptionSummary2/>}/>
      <Route path='/reports/statistics-summary' element={<StatisticsSummary/>}/>
      <Route
        path='/tag'
        element={<Tag />}
      />

      <Route
        path='/watcher'
        element={<MqttWatcher />}
      />



      <Route
        path='/template'
        element={<Template />}
      />

      <Route
        path='/role'
        element={<Role />}
      />

      <Route path='/ticketing' element={<Ticketing/>}/>

      <Route path='/selected-device/:_id' element={<TemplateDashboardRouting />} />
      <Route path='/r-back/update/:roleId' element={<RbackUpdate />} />
      <Route path='/device-data/:_id' element={<DeviceData />} />
      <Route path='/modbusconfig/:_id' element={<Modbus_Config />} />
      <Route path='/csv-upload/:_id' element={<CsvUpload />} />
      <Route path='/scheduling' element={<Scheduling/>}/>


      <Route path='*' element={<NotFoundPage />} />
    </Routes>
  )
}

export default PermissionRouteContent
