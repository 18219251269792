import React, {
  createContext,
  useReducer,
  ReactNode,
  Dispatch,
  useContext,
} from 'react'
import PermissionReducer, { PermissionState } from './PermissionReducer'
import { PermissionAction } from './PermissionAction'

interface PermissionContextProps {
  state: PermissionState
  dispatch: Dispatch<PermissionAction>
}

const initialPermissionState: PermissionState = {
  permissions: {},
}

export const PermissionContext = createContext<PermissionContextProps>({
  state: initialPermissionState,
  dispatch: () => {},
})

interface PermissionProviderProps {
  children: ReactNode
}

export const PermissionProvider: React.FC<PermissionProviderProps> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(
    PermissionReducer,
    initialPermissionState,
  )

  return (
    <PermissionContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </PermissionContext.Provider>
  )
}

export const usePermissionState = () => {
  const { state, dispatch } = useContext(PermissionContext)
  return { ...state, dispatch }
}
