//For server
const baseUrlDevice = "https://f2-dms.frinso.io/device/api/v1/";
const baseUrlTenant = "https://f2-tenant.frinso.io/crm/api/v1/"
const baseUrlUser = "https://f2-ums.frinso.io/user-auth/api/v1/"
const baseUrlTenantImage = "https://f2-tenant.frinso.io/uploads/"
const baseUrlMqttWatcher = "https://f2-mwatcher.frinso.io/"
const baseUrlMqttWatcher1 = "https://f2-mwatcher.frinso.io/"
const baseUrlAlert = "https://f2-alert.frinso.io/alert/api/v1/"
const ROLE_USER_ID = "66bc7526caf014bfcbf619ad"
const baseUrlSubscription = "https://f2-subscription.frinso.io/sub/api/v1/"
const baseUrlDeviceLog = "https://f2-device.frinso.io/device-log/api/v1/"
const baseUrlReport = "https://f2-rms.frinso.io/report/api/v1/"
const baseUrlScheduling = "https://f2-scheduling.frinso.io/scheduling/api/v1/"
const baseUrlAcWS= 'https://f2-scheduling.frinso.io/' // 'http' for local development
const baseUrlTicketing= 'https://f2-ticketing.frinso.io/tickets/api/v1/'



//For Local
// const baseUrlTenant = "http://localhost:4002/crm/api/v1/"
// const baseUrlTenantImage = "http://localhost:4002/uploads/"
// const baseUrlUser = "http://localhost:4001/user-auth/api/v1/"
// const baseUrlDevice = "http://localhost:4003/device/api/v1/";
// const baseUrlMqttWatcher1 = "http://localhost:4005/"
// const baseUrlAlert = "http://localhost:4006/alert/api/v1/"
// const baseUrlSubscription = "http://localhost:4007/sub/api/v1/"
// const baseUrlDeviceLog = "http://localhost:4004/device-log/api/v1/";
// const baseUrlScheduling = "http://localhost:4009/scheduling/api/v1/"
// const baseUrlTicketing= 'http://localhost:4010/tickets/api/v1/'



export { baseUrlDevice, baseUrlUser, baseUrlTenant, baseUrlMqttWatcher, baseUrlTenantImage, baseUrlAlert, baseUrlMqttWatcher1, ROLE_USER_ID, baseUrlSubscription , baseUrlDeviceLog, baseUrlReport, baseUrlScheduling, baseUrlTicketing, baseUrlAcWS};
