import { DeviceData } from '../../types/Device'
import { DeviceAction } from './DeviceAction'

export interface DeviceState {
  device: DeviceData,
  loading: boolean
}

export default function DeviceReducer(
  state: DeviceState,
  action: DeviceAction,
): DeviceState {
  const { type, payload } = action

  switch (type) {
    case 'SET_LOADING':
      return {
        ...state,
        loading: payload,
      };
    case 'SET_DEVIVCE': {
      return { ...state, device: payload, loading:false }
    }
    case 'SET_DEVICE_WITH_SITE': {
      return { ...state, device: payload }
    }

    case 'ADD_DEVICE': {
      const { device: newDevice, totalItems } = payload;

      return {
        ...state,
        device: {
          ...state.device,
          device: [newDevice, ...(state.device?.device || [])], // Safely handle possible undefined
          totalItems: totalItems + 1,
        },
      };
    }

    case 'UPDATE_DEVICE': {
      const { id: updateId, device } = payload

      
      const updatedDevice = state.device.device.map((dev) =>
        dev._id === updateId ? { ...dev, ...device } : dev,
      )

      return {
        ...state,
        device: {
          ...state.device,
          device: updatedDevice,
        },
      }
    }
    default:
      return state
  }
}
