import React, { useState } from 'react'
import { Device } from '../../../types/Device';
import MobileTowerIcon from '../../../components/mobile-tower/MobileTowerIcon';
import SensorCard from '../../../components/card/SensorCard';
import LineChart from '../../../components/chart/line-chart/LineChart';
import BarChart from '../../../components/chart/bar-chart/BarChart';


interface DashboardEnergyMonitoringProps {
    deviceState: Device;
  }
const DashboardEnergyMonitoring: React.FC<DashboardEnergyMonitoringProps> = ({
    deviceState,
  }) => {
    console.log("Device state", deviceState);
    const flowValue = 33.202;

    const graphFlowData = [
      { name: 'Active Energy', data: [6.2, 7.2, 8.2, 6.2, 10.2, 6.2, 12.2, 6.2, 10.2], color: '#4682B4' },
      { name: 'Apparent Energy', data: [7.2, 8.2, 7.2, 9.2, 7.2, 11.2, 7.2, 11.2], color: '#D8BFD8' },
      { name: 'Reactive Energy', data: [8.2, 9.2, 10.2, 11.2, 12.2, 7.2, 13.2, 7.2, 11.2], color: '#7FFFD4' },
    ];

    const categories = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'];

    const [chartType, setChartType] = useState<'line' | 'bar'>('line');
    const handleChartTypeClick = (type: 'line' | 'bar') => {
      setChartType(type);
    };
    
  return (
    <div className='p-4 space-y-2'>
      <div className='flex flex-col md:flex-row justify-between space-y-2 md:space-y-0'>
       <div className="bg-white min-h-10 w-full md:w-1/2  p-1 rounded-lg shadow-md">
        <div className="flex flex-col md:flex-row items-center w-full mt-1 justify-between">
          <div className="text-deepcerulan-600 mb-0 md:mb-0 md:w-1/2">
            <span className="font-bold">Device ID:</span>{' '}
            {deviceState.imeiNumber}
          </div>
          <div className="flex items-center md:ml-0 md:w-1/2">
            <MobileTowerIcon levels={20} time={'2024-09-12 13:48'} />
            <p className="text-sm text-gray-500 ml-0 whitespace-nowrap">
              Last data received at: {new Date().toLocaleString()}
            </p>
          </div>
          </div>
        </div>
        <div className='flex'>
        <button  className='px-10 py-1 rounded-l-full bg-blue-500 shadow shadow-gray-300 text-white'>Analysis</button>
        <button className='px-10 py-1 rounded-r-full bg-white shadow shadow-gray-300'>Instantaneous</button>
      </div>
      </div>

      <div className='bg-white '>
      <div className="button-group p-4 flex flex-col md:flex-row items-center lg:justify-start md:justify-start sm:justify-center gap-2">
  <button
    onClick={() => handleChartTypeClick('line')}
    className={`w-full md:w-auto px-6 py-2 rounded-full ${
      chartType === 'line' ? 'bg-blue-500 text-white' : 'bg-white'
    } shadow shadow-gray-300 text-center`}
  >
    Meter Reading
  </button>
  <button
    onClick={() => handleChartTypeClick('bar')}
    className={`w-full md:w-auto px-6 py-2 rounded-full ${
      chartType === 'bar' ? 'bg-blue-500 text-white' : 'bg-white'
    } shadow shadow-gray-300 text-center`}
  >
    Daily Consumption
  </button>
</div>

      <div className="p-4 bg-white w-full grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">  
        <SensorCard
        sensor='Active Energy'
        value={flowValue}
        unit='kWh'
        />
      
      <SensorCard
        sensor='Apparent Energy'
        value={flowValue}
        unit='kWh'
        />

      <SensorCard
        sensor='Apparent Energy'
        value={flowValue}
        unit='kWh'
        />

      <SensorCard
        sensor='Import Active'
        value={flowValue}
        unit='kWh'
        />

    </div>
    </div>
    <div className='bg-white'> 
      <p className='p-8 text-sm font-semibold text-gray-700'>Energy Meter Reading Data Variation</p>
    {chartType === 'line' ? (
        <LineChart
          title=""
          categories={categories}
          series={graphFlowData}
          yAxisTitle="Data"
          xAxisTitle="Time"
          height={300}
        />
      ) : (
        <BarChart
          title=""
          categories={categories}
          series={graphFlowData}
          height={300}
          xAxisTitle="Time"
          yAxisTitle="Voltage (V)"
        />
      )}
      </div>

   
  </div>
  )
}

export default DashboardEnergyMonitoring