import React from 'react';
import Select, { Props as SelectProps, GroupBase, MultiValue, ActionMeta } from 'react-select';
import styles from './MultiSelectSearchable.module.css';
import { OptionType } from '../../types/Dropdown';

interface MultiSelectSearchableProps {
  options: OptionType[];
  value?: OptionType[]; // Keep value as array for multi-select
  onChange?: (selectedOptions: OptionType[]) => void; // Updated to handle selected options
  placeholder?: string;
  isDisabled?: boolean;
  className?: string;
  classNamePrefix?: string;
  isLoading?: boolean;
  selectProps?: Omit<SelectProps<OptionType, true, GroupBase<OptionType>>, 'options' | 'value' | 'onChange' | 'placeholder' | 'isDisabled' | 'className' | 'classNamePrefix' | 'isLoading'>; // Update for multi-select
}

const MultiSelectSearchable: React.FC<MultiSelectSearchableProps> = ({
  options,
  value = [], // Default to empty array for multi-select
  onChange,
  placeholder = 'Select...',
  isDisabled = false,
  className,
  classNamePrefix = 'multi-select-searchable',
  isLoading = false,
  selectProps,
}) => {
  // Handle the change event from react-select
  const handleChange = (newValue: MultiValue<OptionType>, _actionMeta: ActionMeta<OptionType>) => {
    // Call the onChange prop with the selected options as an array
    if (onChange) {
      onChange(newValue as OptionType[]); // Cast to OptionType[]
    }
  };

  return (
    <Select<OptionType, true, GroupBase<OptionType>>
      options={options}
      value={value}
      onChange={handleChange} // Use the new handleChange function
      placeholder={placeholder}
      isDisabled={isDisabled}
      className={`${styles[classNamePrefix]} ${className || ''}`}
      classNamePrefix={classNamePrefix}
      isLoading={isLoading}
      isMulti // Enable multi-select
      styles={{
        menu: (provided) => ({
          ...provided,
          zIndex: 9999, // Ensure menu is above other elements
        }),
      }}
      {...selectProps}
    />
  );
};

export default MultiSelectSearchable;


// FOR REFERENCE

// import React, { useState } from 'react';
// import MultiSelectSearchable from './MultiSelectSearchable';
// import { OptionType } from '../../types/Dropdown';

// const MyComponent = () => {
//   const [selectedOptions, setSelectedOptions] = useState<OptionType[]>([]);

//   const handleChange = (selected: OptionType[]) => {
//     setSelectedOptions(selected);
//   };

//   const options: OptionType[] = [
//     { value: 'option1', label: 'Option 1' },
//     { value: 'option2', label: 'Option 2' },
//     { value: 'option3', label: 'Option 3' },
//     // Add more options as needed
//   ];

//   return (
//     <div>
//       <h1>Select Options</h1>
//       <MultiSelectSearchable
//         options={options}
//         value={selectedOptions}
//         onChange={handleChange}
//         placeholder="Select options..."
//       />
//     </div>
//   );
// };

// export default MyComponent;
