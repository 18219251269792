import React, {
  createContext,
  useReducer,
  ReactNode,
  Dispatch,
  useContext,
  useEffect,
} from 'react'
import DeviceReducer, { DeviceState } from './DeviceReducer'
import { DeviceAction } from './DeviceAction'

interface DeviceContextProps {
  state: DeviceState
  dispatch: Dispatch<DeviceAction>
}

const initialState: DeviceState = {
  device: {
    limit: 0,
    device: [],
    currentPage: 0,
    totalPages: 0,
    previousPage: 0,
    totalItems: 0,
  },
  loading: false,
}

export const DeviceContext = createContext<DeviceContextProps>({
  state: initialState,
  dispatch: () => {},
})

interface DeviceProviderProps {
  children: ReactNode
}

export const DeviceProvider: React.FC<DeviceProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(DeviceReducer, initialState)

  useEffect(() => {
    // setDevice(dispatch, {page: 1, limit: 10}) // Call setOrganization only once when the component mounts
  }, []) // Empty dependency array ensures it only runs on mount, not on updates

  return (
    <DeviceContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </DeviceContext.Provider>
  )
}

export const useDeviceState = () => {
  const { state, dispatch } = useContext(DeviceContext)
  return { ...state, dispatch }
}
