import React from 'react';
import ReportCategoryCard from '../../../components/card/RepoortCategoryCard';


const GenerateReport = () => {
  return (
    <div>
      <div className='grid grid-cols-1 md:grid-cols-4 gap-5'>
        <ReportCategoryCard subtitle='Report Format' title='Meter Reading' iconBgClass='bg-purple-100' link={'/home/reports/meter-reading'}/>
        <ReportCategoryCard subtitle='Report Format' title='Consumption Summary' iconBgClass='bg-yellow-100' link={'/home/reports/consumption'}/>
        <ReportCategoryCard subtitle='Report Format' title='Consumption Summary 2' iconBgClass='bg-yellow-100' link={'/home/reports/consumption-summary2'}/>
        <ReportCategoryCard subtitle='Report Format' title='Statistics Summary' iconBgClass='bg-yellow-100' link={'/home/reports/statistics-summary'}/>
      </div>
    </div>
  )
}

export default GenerateReport
