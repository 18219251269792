import { DashboardData, OrgAdminDashboardData } from "../../types/overview";
import { OverviewAction } from "./OverviewAction";


export interface OverviewState {
  superadminoverview: DashboardData,
  loading: boolean,
  loaded: boolean,
  orgAdminOverview: OrgAdminDashboardData

  // subscription: DashboardDataSubscription
}

export default function OverviewReducer(
  state: OverviewState,
  action: OverviewAction,
): OverviewState {
  const { type, payload } = action

  switch (type) {
    case 'SET_LOADING':
      return {
        ...state,
        loading: payload,
      };
    case 'SET_OVERVIEW_SUPERADMIN': {
      return { ...state, superadminoverview: payload, loading: false , loaded: true }
    //   return { ...state, data: payload }
    }
    case 'SET_OVERVIEW_ORGADMIN': {
      return { ...state, orgAdminOverview: payload, loading: false , loaded: true }
    //   return { ...state, data: payload }
    }
    default:
      return state
  }
}
