import React, {
    createContext,
    useReducer,
    ReactNode,
    Dispatch,
    useContext,
  } from 'react'
import TicketReducer, { TicketState } from './TicketReducer'
import { TicketAction } from './TicketAction'

  
  interface TicketContextProps {
    state: TicketState
    dispatch: Dispatch<TicketAction>
  }
  
  const initialState: TicketState = {
    tickets: {
      limit: 0,
      tickets: [],
      currentPage: 0,
      totalPages: 0,
      previousPage: 0,
      totalItems: 0,
    },
    loading: false
  }
  
  export const TicketContext = createContext<TicketContextProps>({
    state: initialState,
    dispatch: () => {},
  })
  
  interface TicketProviderProps {
    children: ReactNode
  }
  
  export const TicketProvider: React.FC<TicketProviderProps> = ({ children }) => {
    const [state, dispatch] = useReducer(TicketReducer, initialState)
  
    // useEffect(() => {
    //   setDevice(dispatch, {page: 1, limit: 10}) // Call setOrganization only once when the component mounts
    // }, []) // Empty dependency array ensures it only runs on mount, not on updates
  
    return (
      <TicketContext.Provider
        value={{
          state,
          dispatch,
        }}
      >
        {children}
      </TicketContext.Provider>
    )
  }
  
  export const useTicketState = () => {
    const { state, dispatch } = useContext(TicketContext)
    return { ...state, dispatch }
  }
  