
import  { forwardRef } from 'react';
import TextField from '@mui/material/TextField';
import { TextFieldProps } from '@mui/material/TextField';
import styles from './InputField.module.css'; // Import CSS module

interface InputFieldProps extends Omit<TextFieldProps, 'error' | 'helperText'> {
  error?: string; 
  helperText?: string; 
  disabled?: boolean;
}


const InputField = forwardRef<HTMLInputElement, InputFieldProps>(({
  type = 'text',
  id,
  placeholder,
  value,
  onChange,
  className,
  required = false,
  error,
  label,
  style,
  helperText,
  disabled = false,
  ...rest
}, ref) => {
  
  return (
    <div className={`${styles.inputFieldWrapper} ${disabled ? styles.disabledWrapper : ''}`}>
      <TextField
        type={type}
        id={id}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        required={required}
        label={label}
        helperText={helperText} // Show error if present
        // variant="outlined"
        fullWidth
        style={style}
        className={`${styles.customTextField} ${className || ''} ${disabled ? styles.disabledField : ''}`}
        {...rest} // Spread any additional props to TextField
        size='small'
        disabled={disabled}
        // error={!!error} // Convert error message to boolean
        ref={ref} // Forward the ref to TextField
      />
      <div className={styles.errorText}>
        {error}
      </div>

    </div>
  );
});

InputField.displayName = 'InputField'; // Set a display name for the component

export default InputField;




// import React from 'react';

// interface InputFieldProps {
//   type?: string;
//   id?: string;
//   placeholder?: string;
//   value?: string;
//   onChange?: React.ChangeEventHandler<HTMLInputElement>;
//   className?: string; // ClassName prop for additional styles
//   required?: boolean;
//   error?: string;
//   label?: string;
//   style?: React.CSSProperties; // Inline styles
// }

// const InputField: React.FC<InputFieldProps> = (props) => {
//   const {
//     type = 'text',
//     id,
//     placeholder,
//     value,
//     onChange,
//     className,
//     required = false,
//     error,
//     label,
//     style,
//   } = props;

//   return (
//     <div className="flex flex-col">
//       {label && (
//         <label
//           htmlFor={id}
//           className="pl-1 block text-sm font-medium text-gray-700 mb-1"
//         >
//           {label}
//         </label>
//       )}
//       <input
//         type={type}
//         id={id}
//         placeholder={placeholder}
//         value={value}
//         onChange={onChange}
//         style={style}
//         className={`${className ? className : 'block w-full px-3 py-2 border-2 border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'}`}
//         required={required}
//       />
//       <p className="mt-1 h-1 text-sm text-red-600">{error && error}</p>
//     </div>
//   );
// };

// export default InputField;



// import  { forwardRef } from 'react';
// import TextField from '@mui/material/TextField';
// import { TextFieldProps } from '@mui/material/TextField';
// import styles from './InputField.module.css'; // Import CSS module

// interface InputFieldProps extends Omit<TextFieldProps, 'error' | 'helperText'> {
//   error?: string; 
//   helperText?: string; 
// }


// const InputField = forwardRef<HTMLInputElement, InputFieldProps>(({
//   type = 'text',
//   id,
//   placeholder,
//   value,
//   onChange,
//   className,
//   required = false,
//   error,
//   label,
//   style,
//   helperText,
//   ...rest
// }, ref) => {
//   console.log("RENDERING input field");
  
//   return (
//     <div className={styles.inputFieldWrapper}>
//       <TextField
//         type={type}
//         id={id}
//         placeholder={placeholder}
//         value={value}
//         onChange={onChange}
//         required={required}
//         label={label}
//         helperText={helperText} // Show error if present
//         variant="outlined"
//         fullWidth
//         style={style}
//         className={`${styles.customTextField} ${className || ''}`}
//         {...rest} // Spread any additional props to TextField
//         size='small'
//         // error={!!error} // Convert error message to boolean
//         ref={ref} // Forward the ref to TextField
//       />
//       <div className={styles.errorText}>
//         {error}
//       </div>

//     </div>
//   );
// });

// InputField.displayName = 'InputField'; // Set a display name for the component

// export default InputField;


// import React from 'react';
// import TextField from '@mui/material/TextField';
// import { TextFieldProps } from '@mui/material/TextField';
// import styles from './InputField.module.css'; // Import CSS module

// interface InputFieldProps extends Omit<TextFieldProps, 'error' | 'helperText'> {
//   error?: string;
//   helperText?: string; // Custom helper text
// }

// const InputField: React.FC<InputFieldProps> = ({
//   type = 'text',
//   id,
//   placeholder,
//   value,
//   onChange,
//   className,
//   required = false,
//   error,
//   label,
//   style,
//   helperText,
//   ...rest
// }) => {
//   return (
//     <div className={styles.inputFieldWrapper}>
//       <TextField
//         type={type}
//         id={id}
//         placeholder={placeholder}
//         value={value}
//         onChange={onChange}
//         required={required}
//         label={label}
//         // error={!!error} // Convert error message to boolean
//         helperText={helperText} // Always use custom helperText
//         variant="outlined"
//         fullWidth
//         style={style}
//         className={`${styles.customTextField} ${className || ''}`}
//         {...rest} // Spread any additional props to TextField
//         size='small'
//       />
//        <div className={styles.errorText}>
//         {error}
//       </div>

//     </div>
//   );
// };

// export default InputField;




// import React from 'react';

// interface InputFieldProps {
//   type?: string;
//   id?: string;
//   placeholder?: string;
//   value?: string;
//   onChange?: React.ChangeEventHandler<HTMLInputElement>;
//   className?: string; // ClassName prop for additional styles
//   required?: boolean;
//   error?: string;
//   label?: string;
//   style?: React.CSSProperties; // Inline styles
// }

// const InputField: React.FC<InputFieldProps> = (props) => {
//   const {
//     type = 'text',
//     id,
//     placeholder,
//     value,
//     onChange,
//     className,
//     required = false,
//     error,
//     label,
//     style,
//   } = props;

//   return (
//     <div className="flex flex-col">
//       {label && (
//         <label
//           htmlFor={id}
//           className="pl-1 block text-sm font-medium text-gray-700 mb-1"
//         >
//           {label}
//         </label>
//       )}
//       <input
//         type={type}
//         id={id}
//         placeholder={placeholder}
//         value={value}
//         onChange={onChange}
//         style={style}
//         className={`${className ? className : 'block w-full px-3 py-2 border-2 border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'}`}
//         required={required}
//       />
//       <p className="mt-1 h-1 text-sm text-red-600">{error && error}</p>
//     </div>
//   );
// };

// export default InputField;
