import React, { useEffect, useMemo, useState } from 'react'
import InputField from '../../../components/input-field/InputField'
import SearchableSelect from '../../../components/dropdown-select/SearchableSelect'
import DatePicker from '../../../components/custom-date/DatePicker'
import Button from '../../../components/button/Button'
import LinearWithValueLabel from '../../../components/linear-loader/LinearProgressBar'
import BackIcon from '../../../assets/images/back.png';
import { useNavigate } from 'react-router-dom'
import MultiSelectSearchable from '../../../components/dropdown-select/MultiSelectSearchable'
import { generateDayOptions } from '../../../utils/helper/generateDayOptions'
import { useFormik } from 'formik'
import { OptionType } from '../../../types/Dropdown'
import dayjs, { Dayjs } from 'dayjs'
// import { useOrganizationState } from '../../../app-context/organization-context/OrganizationState'
import { useCurrentUserState } from '../../../app-context/current-user-context/CurrentUserLoginState'
import { useOptions } from '../../../app-context/dropdown-option/OptionState'
import { handleGetAllDevicebyOrgVendorUser, handleGetAllSitesByOrganizationId, handleGetAllTemplate, handleGetAllUser } from '../../../services/api'
import { ROLE_USER_ID } from '../../../utils/env/env.config'
import { RoleBack } from '../../../types/Role'

interface MeterReadingProps {

}

interface OptionsState {
    vendor: OptionType[];
    user: OptionType[];
    device: OptionType[]
}

const reportFormatOptions = [
    { value: 'pdf', label: 'PDF' },
    { value: 'excel', label: 'Excel' },
]

const methodOptions = [
    { value: 'manual', label: 'Manual' },
    { value: 'auto_generated', label: 'Auto Generated' },
]

const reportGeneratedTypeOption = [
    { value: 'daily', label: 'Daily' },
    { value: 'weekly', label: 'Weekly' },
    { value: 'monthly', label: 'Monthly' }
];

const receivedTimeOptionWeekly = [
    { value: '1', label: 'Monday' },
    { value: '2', label: 'Tuesday' },
    { value: '3', label: 'Wednesday' },
    { value: '4', label: 'Thursday' },
    { value: '5', label: 'Friday' },
    { value: '6', label: 'Saturday' },
    { value: '7', label: 'Sunday' },
];

const MeterReading: React.FC<MeterReadingProps> = () => {
    const { currentUser } = useCurrentUserState();
    const [isLoaderVisible, setIsLoaderVisible] = useState(false);
    const recievedTimeOptionMonthly = useMemo(() => generateDayOptions(), []);
    const [selectedRecievedOption, setSelectedRecievedOption] = useState<OptionType[]>([]);
    const [selectedOrganization, setSelectedOrganization] = useState<OptionType | null>(null);
    const [selectedVendor, setSelectedVendor] = useState<OptionType | null>(null);
    const [selectedUser, setSelectedUser] = useState<OptionType | null>(null);
    const [selectedDevice, setSelectedDevice] = useState<OptionType | null>(null);
    const { fetchDropDownOptions, state } = useOptions();
    const { organization } = state
    const [options, setOptions] = useState<OptionsState>({
        vendor: [],
        user: [],
        device: []
    })
    const navigate = useNavigate();

    const handleChangeRecievedOption = (selected: OptionType[]) => {
        setSelectedRecievedOption(selected);
        console.log('printed selected REcieved option:-',selected);
    }

    const handleDateChange = (field: string, value: Dayjs | null) => {
        formik.setFieldValue(field, value);
    }

    const handleDownload = () => {
        setIsLoaderVisible(true);
        formik.handleSubmit();
    }

    const handleBackIcon = () => {
        navigate('/home/reports');
    }

    useEffect(() => {
        const isOrganizationEmpty = organization.length === 0;
        const isUserNotAdmin = !(currentUser.role.name === RoleBack.SITEADMIN || currentUser.role.name === RoleBack.ORGANIZATIONADMIN);
        if (isOrganizationEmpty && isUserNotAdmin) {
            fetchDropDownOptions('organization', '');
        }
    })

    const fetchOptions = async (key: string, _id: string) => {
        try {
            switch (key) {
                case 'site': {
                    if (_id) {
                        const data: OptionType[] = await handleGetAllSitesByOrganizationId(_id);
                        setOptions(prevOptions => ({
                            ...prevOptions,
                            vendor: data
                        }));
                    }
                }
                    break;
                case 'userbyorganization': {
                    if (_id) {
                        const data: OptionType[] = await handleGetAllUser(currentUser.clientId, '', _id, ROLE_USER_ID);
                        setOptions(prevOptions => ({
                            ...prevOptions,
                            user: data
                        }));
                    }
                }
                    break;
                case 'devicebyorganizationVendorUser': {
                    if(_id) {
                        const data: OptionType[] = await handleGetAllDevicebyOrgVendorUser(_id, selectedVendor?.value || '', selectedUser?.value || '');
                        setOptions(prevOptions => ({
                            ...prevOptions, 
                            device: data
                        }));
                    }
                }
                    break;
                case 'userbysite': {
                    if (_id) {
                        const data: OptionType[] = await handleGetAllUser(currentUser.clientId, _id, '', ROLE_USER_ID);
                        setOptions(prevOptions => ({
                            ...prevOptions,
                            user: data
                        }));
                    }
                }
                    break;
                case 'template': {
                    const data: OptionType[] = await handleGetAllTemplate();
                    setOptions((prevOptions) => ({
                        ...prevOptions,
                        template: data || [],
                    }));
                }
                    break;
                default:
                    console.warn(`No case found for key: ${key}`);
                    break;
            }
        } catch (err) {
            console.log(err)
        }
    }

    const formik = useFormik({
        initialValues: {
            selectedUser: '',
            reportHeader: '',
            selectedMethod: '',
            generatedType: '',
            receivedTime: '',
            reportFormat: '',
            startDate: dayjs(),
            endDate: dayjs(),
            dataInterval: '',
            selectedDevice: '',
            selectedVendor: '',
            selectedOrganization: '',
            selectedRecievedOption: ''
        },
        onSubmit: async (values) => {
            const formattedValues = {
                ...values,
                startDate: dayjs(values.startDate).format('DD-MM-YYYY'),
                endDate: dayjs(values.endDate).format('DD-MM-YYYY'),
                selectedOrganization: selectedOrganization ? selectedOrganization.value : '',
                selectedVendor: selectedVendor ? selectedVendor.value : '',
                selectedUser: selectedUser ? selectedUser.value : '',
                selectedDevice: selectedDevice ? selectedDevice.value : '',
                selectedRecievedOption: selectedRecievedOption.map(option => option.label),
            };
            console.log('form values:- ', formattedValues);
            setIsLoaderVisible(true);
        }
    });

    const isAutoGenerated = formik.values.selectedMethod === 'auto_generated';
    const isMonthly = formik.values.generatedType === 'monthly'
    const isWeekly = formik.values.generatedType === 'weekly'
    const isDaily = formik.values.generatedType === 'daily'

    return (
        <div className='md:px-10 md:py-4'>
            <div className='mt-10 px-10 py-5 md:bg-white md:rounded-xl md:border md:border-slate-200 md:relative'>
                <div className='border border-[#E2E8F0] p-4 rounded-full w-[60px] h-[60px] justify-center items-center bg-[#E8F2F7] cursor-pointer absolute top-[-30px] left-[-30px] hidden md:flex' onClick={handleBackIcon}>
                    <img src={BackIcon} alt="backicon" />
                </div>
                <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-x-20 gap-y-5'>
                        <SearchableSelect 
                            placeholder='Select Organization' 
                            options={organization} 
                            value={selectedOrganization} 
                            onChange={(option) => { 
                                setSelectedOrganization(option); 
                                fetchOptions('site', option?.value ?? ''); 
                                setSelectedVendor(null); 
                                setSelectedUser(null); 
                                fetchOptions('userbyorganization', option?.value ?? ''); 
                                fetchOptions('devicebyorganizationVendorUser', option?.value ?? '');
                            }} 
                            isLoading={false} 
                            isDisabled={false} 
                        />
                        <SearchableSelect placeholder='Select Vendor' options={options.vendor} value={selectedVendor} onChange={(option) => { setSelectedVendor(option); }} isLoading={false} />
                        <SearchableSelect placeholder='Select User' options={options.user} value={selectedUser} onChange={(option) => { setSelectedUser(option); }} isLoading={false} isDisabled={false} />
                        <InputField placeholder='Enter Report Header' name='reportHeader' label='Enter Report Header' value={formik.values.reportHeader} onChange={formik.handleChange} />
                        <SearchableSelect placeholder='Select Method' options={methodOptions} value={methodOptions.find(option => option.value === formik.values.selectedMethod) || null} onChange={value => formik.setFieldValue('selectedMethod', value?.value || '')} isLoading={false} isDisabled={false} />
                        <SearchableSelect placeholder='Select Report Format' options={reportFormatOptions} value={reportFormatOptions.find(option => option.value === formik.values.reportFormat) || null} onChange={value => formik.setFieldValue('reportFormat', value?.value || '')} isDisabled={false} isLoading={false} />
                        {!isAutoGenerated ?
                            <>
                                <DatePicker label='Start Date' value={formik.values.startDate} onChange={(date) => handleDateChange('startDate', date)} />
                                <DatePicker label='End Date' value={formik.values.endDate} onChange={(date) => handleDateChange('endDate', date)} />
                            </> :
                            <>
                                <SearchableSelect placeholder='Report Generated Type' options={reportGeneratedTypeOption} value={reportGeneratedTypeOption.find(option => option.value === formik.values.generatedType) || null} onChange={value => formik.setFieldValue('generatedType', value?.value || '')} isLoading={false} isDisabled={false} />
                                <MultiSelectSearchable placeholder='Received On' options={isDaily || isWeekly ? receivedTimeOptionWeekly : isMonthly ? recievedTimeOptionMonthly : []} value={selectedRecievedOption} onChange={handleChangeRecievedOption} />
                                <InputField label='Received Time' type='time' name='receivedTime' value={formik.values.receivedTime} onChange={formik.handleChange} />
                            </>
                        }
                        <InputField placeholder='Data Interval(minutes)' label='Data Interval(minutes)' name='dataInterval' value={formik.values.dataInterval} onChange={formik.handleChange} />
                        <SearchableSelect placeholder='Select Device' options={options.device} value={selectedDevice} onChange={(option) => {setSelectedDevice(option);}} isLoading={false} isDisabled={false}/>
                    </div>
                    <div className='button-group flex gap-x-2 md:gap-x-10 mt-8 md:w-1/2 md:mx-auto'>
                        <Button type='reset' variant='outline' size='medium'>Reset</Button>
                        <Button type='button' variant='outline' size='medium' onClick={handleDownload}>Download</Button>
                    </div>

                    {isLoaderVisible &&
                        <div className='flex justify-center'>
                            <LinearWithValueLabel />
                        </div>
                    }
                </form>
            </div>
        </div>
    )
}

export default MeterReading
