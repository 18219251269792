// import Organization from "../modules/organization/Organization"
import { ResponseDeviceDropdown, TemplateTag } from "../types/Device"
import { OptionType } from "../types/Dropdown"
import { ResponseDropdownOrganization } from "../types/organization"
import { ApiResponseRole } from "../types/Role"
import { GetAllTagsResponse } from "../types/Tag"
import { TemplateResponse } from "../types/TagGroup"
import {  ApiResponseTemplateDropdown } from "../types/Template"
import {  ApiResponseUserDropdown } from "../types/User"
import { ResponseDropdownVender } from "../types/Vender"
import { baseUrlDevice, baseUrlTenant, baseUrlUser } from "../utils/env/env.config"
import { http } from "../utils/helper/http"



export const handleGetAllOrganizations = async (): Promise<OptionType[]> => {
  try {
    const response = (await http(
      `${baseUrlTenant}organizations`,
    )) as ResponseDropdownOrganization
    const data = response.data
    return data.organizations
  } catch (error) {
    console.error('Error fetching organizations:', error);
    return [];
  }
}


export const handleGetAllSitesByOrganizationId = async (
  organizationId: string,
): Promise<OptionType[]> => {
  try {
    const response = (await http(
      `${baseUrlTenant}sites?organizationId=${organizationId}`,
    )) as ResponseDropdownVender
    const data = response.data
    return data.vendor
  } catch (error) {
    console.error('Error fetching sites:', error);
    return [];
  }
}


export const handleGetAllRoles = async (clientId: string): Promise<OptionType[]> => {
  try {
    const response = (await http(
      `${baseUrlUser}${clientId}/roles`,
    )) as ApiResponseRole
    const data = response.data
    const roles: OptionType[] = data.map((row) => ({
      value: row._id || '',
      label: row.name,
    }))
    return roles
  } catch (error) {
    console.error('Error fetching roles:', error)
    return [];
  }
}


export const handleGetAllUser = async (
  clientId: string,
  vendorId: string,
  organizationId: string,
  roleId: string,
): Promise<OptionType[]> => {
  try {

    const response = (await http(
      `${baseUrlUser}` +
      clientId +
      '/users?vendorId=' +
      vendorId +
      '&organizationId=' +
      organizationId + '&roleId=' + roleId,
    )) as ApiResponseUserDropdown

    const user: OptionType[] = response.data.user.map((row) => ({
      value: row.value,
      label: row.label,
    }))
    return user
  } catch (error) {
    console.error('Error fetching user:', error)
    return [];
  }
}



export const handleGetAllTemplate = async (): Promise<OptionType[]> => {
  try {
    const response = (await http(
      baseUrlDevice + `template`
    )) as ApiResponseTemplateDropdown
    const template = response.data.template
    return template
  } catch (error) {
    console.error('Error fetching templates:', error)
    return [];
  }
}




interface TagInfo {
  sensorAlias: string;
  scalingFactor: number;
  status: boolean;
  displaySensorName: string;
  sensorUnit: string
}

interface TagMap {
  [key: string]: TagInfo;
}

export const handleGetTagsByTemplate = async (templateId: string): Promise<TemplateTag[]> => {
  try {
    const response = await http(`${baseUrlDevice}template-tag/${templateId}`) as TemplateTag[];
    const tagMap: TagMap = {}
    const combinedVariable = response.map((item) => {
      if (tagMap[item.tagId]) {
        return {
          ...item,
          sensorAlias: tagMap[item.tagId].sensorAlias,
          scalingFactor: tagMap[item.tagId].scalingFactor,
          status: tagMap[item.tagId].status,
          displaySensorName: tagMap[item.tagId].displaySensorName,
          sensorUnit: tagMap[item.tagId].sensorUnit,
        }
      }
      return item
    })
    return combinedVariable;
  } catch (err) {
    console.error('Failed to get template tags:', err);
    return [];
  }
};



export const handleGetAllTag = async (): Promise<OptionType[]> => {
  try {
    const response = (await http(
      baseUrlDevice + `tags`
    )) as GetAllTagsResponse
    const tags = response.data.tag
    return tags
  } catch (error) {
    console.error('Error fetching roles:', error)
    return []
  }
}

export interface GetAllTagGroupsResponse {
  data: {
    currentPage: number;
    previousPage: string | null;
    tagGroup: TemplateResponse[];
  };
  limit: number;
  page: number;
}
export const handleGetTagGroupByTemplateId = async (
  templateId: string
) => {
  try {
    const response = (await http(
      baseUrlDevice + `tag-group?templateId=${templateId}`)
    ) as GetAllTagGroupsResponse
    return response
  } catch (error) {
    console.error('Error fetching roles:', error)
  }
}


export const handleGetAllDevice = async (): Promise<OptionType[]> => {
  try {
    const response = (await http(
      `${baseUrlDevice}devices`,
    )) as ResponseDeviceDropdown
    const data = response.data
    return data.device
  } catch (error) {
    console.error('Error fetching organizations:', error);
    return [];
  }
}

export const handleGetAllDeviceForScheduling = async (templateId: string, organizationId: string | null): Promise<OptionType[]> => {
  try {
    let queryParams = `?templateId=${templateId}&`
    if (organizationId) {
      queryParams += `organizationId=${organizationId}`
    }

    const response = (await http(
      `${baseUrlDevice}devices${queryParams}`,
    )) as ResponseDeviceDropdown
    const data = response.data
    return data.device
  } catch (error) {
    console.error('Error fetching organizations:', error);
    return [];
  }
}

export const handleGetAllDevicebyOrgVendorUser = async (
  organization: string, 
  vendor: string, 
  user: string
): Promise<OptionType[]> => {
  try {
    // Assuming 'http' is already an HTTP client instance (e.g., axios)
    const response = (await http(
      `${baseUrlDevice}devices?organizationId=${organization}&vendorId=${vendor}&userId=${user}`
    )) as ResponseDeviceDropdown;
    
    // Extract the device data from the response
    const data = response.data;

    // Return the device list
    return data.device;
  } catch (error) {
    console.error('Error fetching devices:', error);
    
    // Return an empty array in case of an error
    return [];
  }
}




