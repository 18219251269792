import { TemplateData } from '../../types/Template'
import { TemplateAction } from './TemplateAction'

export interface TemplateState {
  template: TemplateData,
  loading: boolean
}

export default function TemplateReducer(
  state: TemplateState,
  action: TemplateAction,
): TemplateState {
  const { type, payload } = action

  switch (type) {
    case 'SET_LOADING':
      return {
        ...state,
        loading: payload,
      };
    case 'SET_TEMPLATE':{
      return { ...state, template: payload ,loading: false}
    }
    case 'ADD_TEMPLATE':{
      const { template: newTemplate, totalItems } = payload
      return {
        ...state,
        template: {
          ...state.template,
          template: [newTemplate, ...state.template.template],
          totalItems: totalItems + 1,
        },
      }
    }
    case 'UPDATE_TEMPLATE':{
      const { id: updateId, template } = payload
      const updatedTemplate = state.template.template.map((temp) =>
        temp._id === updateId ? { ...temp, ...template } : temp,
      )
      return {
        ...state,
        template: {
          ...state.template,
          template: updatedTemplate,
        },
      }
    }
    default:
      return state
  }
}
