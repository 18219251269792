import ReportCategoryCard from "../../components/card/RepoortCategoryCard"
import realTimeAlert from '../../assets/alert/real-time-alert.svg'
import consumption from '../../assets/alert/cnsumption.png'
import { useNavigate } from "react-router-dom"


const Alerts = () => {
  const navigate=useNavigate()
  const handleCategoryClick = (category: string) => {
    navigate(`/home/alerts/${category}`);
  }
  return (
  
      <div className='grid grid-cols-1 md:grid-cols-3 gap-5 p-2'>
        <ReportCategoryCard  title='Real-Time Alert' iconBgClass='bg-purple-100' icon={realTimeAlert} onClick={() => handleCategoryClick('real-time-alert')} link={""} />
        <ReportCategoryCard  title='Consumption Alert' iconBgClass='bg-yellow-100' icon={consumption} onClick={() => handleCategoryClick('consumption-alert')} link={""} />
      </div>
    
  )
}

export default Alerts