import HandleHttp from "../axiso-interceptor/axios.interceptor";

const loadTime = 0;

interface HttpErrorResponse {
  status?: number;
  data?: {
    message?: string;
  };
}

interface HttpError extends Error {
  response?: HttpErrorResponse;
}

export const http = async (
  url: string,
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' = 'GET', // Restrict method to common HTTP methods
  reqData: Record<string, unknown> | FormData | string[] = {} 
) => {
  const getToken = () => localStorage.getItem('token') || '';
  const httpInstance = HandleHttp({ baseUrl: url, token: getToken() });

  if (!getToken()) {
    window.localStorage.removeItem('token');
    window.location.href = '/';
    return; 
  }

  try {
    const response = await httpInstance.request({
      url,
      method,
      data: reqData,
    });

    return new Promise((resolve) =>
      setTimeout(() => resolve(response.data), loadTime)
    );
  } catch (err: unknown) {
    const error = err as HttpError;
    const { status, data } = error.response || {};

    if (method === 'GET' && status === 401) {
      window.localStorage.removeItem('token');
      window.location.href = '/';
    }

    console.error("HTTP Error:", error); // Log the error object for debugging

    return new Promise((_, reject) =>
      setTimeout(() => reject(data?.message || 'An unexpected error occurred'), loadTime)
    );
  }
};
