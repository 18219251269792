import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { baseUrlTenant } from '../env/env.config';

interface DataRow {
  [key: string]: string | number;
}

// Fetch the image as Base64
const fetchImageAsBase64 = async (url: string): Promise<string> => {
  console.log("Backend Fetch Image URL:", url);
  
  // Use the backend API to get the image
  const response = await fetch(`${baseUrlTenant}fetch-image?url=${encodeURIComponent(url)}`);
  if (!response.ok) {
    throw new Error("Failed to fetch image from backend");
  }

  const blob = await response.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};


const generatePdf = async (columns: string[], data: DataRow[], logoImageUrl: string, appName = "Frinso"): Promise<void> => {
  const doc = new jsPDF();

  const currentDate = new Date();
  const formattedDate = `${currentDate.toLocaleDateString()} ${currentDate.toLocaleTimeString()}`;

  // Define the header (columns)
  const headers = columns.map(col => ({ title: col, dataKey: col }));

  // Map data rows based on the column names
  const dataRows = data.map(row =>
    columns.map(col => row[col] || '')
  );

  // Add content to the first page (App Name, Logo, Report Heading)
  doc.setFontSize(16);
  doc.text(appName, doc.internal.pageSize.width - 20, 10, { align: 'right' });

  // Fetch and add the logo if an S3 URL is provided
  if (logoImageUrl) {
    try {
      const logoBase64 = await fetchImageAsBase64(logoImageUrl);

      const logoWidth = 30; // Adjust size to reduce file size
      const logoHeight = 15;

      // Determine the extension type and add the image
      doc.addImage(logoBase64, 'PNG', 10, 10, logoWidth, logoHeight, undefined, 'FAST', 0.6);
    } catch (error) {
      console.error('Error loading the logo:', error);
    }
  }

  doc.setFontSize(20);
  doc.text('Report Heading', doc.internal.pageSize.width / 2, 30, { align: 'center' });

  // Calculate column widths dynamically based on the number of columns
  const totalWidth = doc.internal.pageSize.width - 20;
  const columnWidth = totalWidth / columns.length;

  // Add the table to the PDF with pagination
  doc.autoTable({
    head: [headers],
    body: dataRows,
    startY: 40,
    margin: { top: 10, left: 10, right: 10, bottom: 10 },
    theme: 'striped',
    tableWidth: 'auto',
    headStyles: { fillColor: [41, 128, 185] },
    bodyStyles: { fontSize: 10 },
    pageBreak: 'auto',
    showHead: 'everyPage',
    columnStyles: columns.reduce((styles: { [key: number]: { cellWidth: number } }, _, index) => {
      styles[index] = { cellWidth: columnWidth };
      return styles;
    }, {}),
  });

  // Add content to the last page (footer with report generated by and timestamp)
  doc.setFontSize(10);
  doc.text(`Report generated by Frinso on ${formattedDate}`, doc.internal.pageSize.width / 2, doc.internal.pageSize.height - 10, { align: 'center' });

  // Save the PDF
  doc.save('data.pdf');
};

export default generatePdf;
