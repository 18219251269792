import { OptionsState } from '../../types/Option';
import { OptionsAction } from './OptionsAction';

export const initialState: OptionsState = {
  organization: [],
  site: [],
  user: [],
  template: [],
  slave: [],
  loading: false,
};

export default function optionsReducer(
  state: OptionsState,
  action: OptionsAction,
): OptionsState {
  switch (action.type) {
    case 'SET_OPTIONS':
      return {
        ...state,
        ...action.payload,
      };
    case 'SET_LOADING':
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
}
