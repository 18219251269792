import React, { lazy, Suspense, useEffect, useRef, useState } from 'react';
import SearchAndActions from '../../components/search-action/SearchAndActions';
import Modal from '../../components/modal/Modal';
// import { usePermissionState } from '../../app-context/permission-context/PermissionState';
import { useCurrentUserState } from '../../app-context/current-user-context/CurrentUserLoginState';
import { useSchedulingState } from '../../app-context/scheduling-context/SchedulingState';
import { setScheduling } from '../../app-context/scheduling-context/SchedulingAction';
import Loader from '../../components/loader/Loader';
const SchedulingTable = lazy(() => import('./components/SchedulingTable'));
const AddSchedulingForm = lazy(() => import('./components/AddScheduling'));



function Scheduling() {
    // const { permissions } = usePermissionState();
    const canCreate = true
    const [searchTerm, setSearchTerm] = useState<string>('');
    const timerRef = useRef<NodeJS.Timeout | null>(null);
    const [addSchedulingModal, setAddSchedulingModal] = useState(false)
    const { currentUser } = useCurrentUserState()
    const {dispatch, schedulings, loading}=useSchedulingState()

    useEffect(() => {
        setScheduling(dispatch, { page: 1, limit: 10,search: '', organizationId:  currentUser.organizationId })
        console.log("SCHEDULING DATA", schedulings);

    }, [currentUser]);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
        if (timerRef.current) {
          clearTimeout(timerRef.current);
        }
        timerRef.current = setTimeout((): void => {
        //   loadDeviceData(event.target.value);
        }, 500);
    };

    const handleAddNew = () => {
        setAddSchedulingModal(true);
    }

    const handleExport = () => {}

    return (
        <div className='p-5'>
            {(loading) && <Loader showGradient={(loading)} />}
            <SearchAndActions 
                searchTerm={searchTerm} 
                onSearchChange={handleSearchChange} 
                onAddNew={handleAddNew} 
                onExport={handleExport}
                handelAdd={canCreate}
            />
            <div>
                <Suspense fallback={<div>Loading...</div>}>
                    <SchedulingTable/>
                </Suspense>
            </div>
            <Modal toggleModal={addSchedulingModal} onClose={() => setAddSchedulingModal(false)}>
                <Suspense fallback={<div>Loading...</div>}>
                    <AddSchedulingForm
                    action='ADD_NEW'
                    onClose={() =>{
                        setAddSchedulingModal(false)
                    }}/>
                </Suspense>
            </Modal>
            
        </div>
    );
}

export default Scheduling;
