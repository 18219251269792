import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import InputField from '../../components/input-field/InputField';
import Button from '../../components/button/Button';
import { OptionType } from '../../types/Dropdown';
import SearchableSelect from '../../components/dropdown-select/SearchableSelect';
import CommonTable from '../../components/table/Table';
import { Column } from '../../types/table';


interface OptionsState {
    stopBits: OptionType[];
    parityStatus: OptionType[];
    parameter: OptionType[];
    resgisterType: OptionType[];
    scallingFactor: OptionType[];
    modbusType: OptionType[];
    dataType: OptionType[];
    dataSize: OptionType[];
}

const columns: Column[] = [
    { id: 'parameter', label: 'Parameter Name' },
    { id: 'registerType', label: 'Register Type' },
    { id: 'address', label: 'Register Address', align: 'center' },
    { id: 'slaveId', label: 'Slave ID', align: 'center' },
    { id: 'scallingFactor', label: 'Scalling Factor', align: 'center'},
    { id: 'modbusType', label: 'Modbus Data Type', align: 'center'},
    { id: 'dataType', label: 'Data Type', align: 'center'},
    { id: 'dataSize', label: 'Data Size', align: 'center'},
    { id: 'action', label: 'Actions', align: 'center', action: true},
  ];
function Modbus_Config() {
    const { _id } = useParams();
    console.log(_id);
    const [selectedStopBits, setSelectedStopBits] = useState<OptionType | null>(null);
    const [selectedParityStatus, setSelectedParityStatus] = useState<OptionType | null>(null);
    const [selectedParameter, setSelectedParameter] = useState<OptionType | null>(null);
    const [selectedRegisterType, setSelectedRegisterType] = useState<OptionType | null>(null);
    const [selectedScallingFactor, setSelectedScallingFactor] = useState<OptionType | null>(null);
    const [selectedModbusType, setSelectedModbusType] = useState<OptionType | null>(null);
    const [selectedDataType, setSelectedDataType] = useState<OptionType | null>(null);
    const [selectedDataSize, setSelectedDataSize] = useState<OptionType | null>(null);
    const [options, setOptions] = useState<OptionsState>({
        stopBits: [],
        parityStatus: [],
        parameter: [],
        resgisterType: [],
        scallingFactor: [],
        modbusType: [],
        dataType: [],
        dataSize: [],
    });
    const sampleData = [
        {
            parameter: 'Temperature',
            registerType: 'Input',
            address: '0x01',
            slaveId: '1',
            scallingFactor: 0.1,
            modbusType: 'Float',
            dataType: 'Temperature',
            dataSize: 2,
        },
        {
            parameter: 'Pressure',
            registerType: 'Holding',
            address: '0x02',
            slaveId: '1',
            scallingFactor: 0.01,
            modbusType: 'Float',
            dataType: 'Pressure',
            dataSize: 2,
        },
        {
            parameter: 'Humidity',
            registerType: 'Input',
            address: '0x03',
            slaveId: '2',
            scallingFactor: 0.1,
            modbusType: 'Float',
            dataType: 'Humidity',
            dataSize: 2,
        },
        {
            parameter: 'Flow Rate',
            registerType: 'Holding',
            address: '0x04',
            slaveId: '3',
            scallingFactor: 0.01,
            modbusType: 'Float',
            dataType: 'Flow',
            dataSize: 2,
        },
        {
            parameter: 'Voltage',
            registerType: 'Input',
            address: '0x05',
            slaveId: '1',
            scallingFactor: 0.1,
            modbusType: 'Int16',
            dataType: 'Voltage',
            dataSize: 1,
        },
        {
            parameter: 'Current',
            registerType: 'Holding',
            address: '0x06',
            slaveId: '2',
            scallingFactor: 0.1,
            modbusType: 'Int16',
            dataType: 'Current',
            dataSize: 1,
        },
    ];
    useEffect(() => {
        setOptions((prevOption) => ({
            ...prevOption,
            stopBits: [
                { value: "1", label: "1" },
                { value: "2", label: "2" },
            ],
            parityStatus: [
                { value: "0", label: "None" },
                { value: "1", label: "Odd" },
                { value: "2", label: "Even" },
            ],
            parameter: [
                { value: "1", label: "hum" },
                { value: "2", label: "ac_status" },
                { value: "3", label: "flow" },
                { value: "4", label: "totalizer" },
            ],
            resgisterType: [
                { value: "1", label: "Holding Register" },
                { value: "2", label: "Input Register" },
                { value: "3", label: "Coil" },
            ],
            scallingFactor: [
                { value: "1", label: "1" },
                { value: "2", label: "0.1" },
                { value: "3", label: "0.01" },
            ],
            modbusType: [
                { value: "1", label: "RTU" },
                { value: "2", label: "ASCII" },
            ],
            dataType: [
                { value: "1", label: "int8" },
                { value: "2", label: "int16" },
                { value: "3", label: "int32" },
                { value: "4", label: "float32" },
            ],
            dataSize: [
                { value: "1", label: "1 byte" },
                { value: "2", label: "2 bytes" },
                { value: "3", label: "4 bytes" },
            ],
        }));
    }, [])

    return (
        <>
            <div className='flex flex-col ml-5 mt-5 gap-5'>
                <div className="flex flex-col md:flex-row gap-10">
                    <div className='flex flex-col md:flex-row justify-start gap-5'>
                        <InputField
                            type="text"
                            id="imei"
                            placeholder="IMEI"
                            label="IMEI"
                            value="MY IMEI NUMBER"
                            disabled
                        />
                        <InputField
                            type="text"
                            id="deviceName"
                            placeholder="Device Name"
                            label="Device Name"
                            value="MY Device Name"
                            disabled
                        />
                    </div>
                    <div className='flex flex-col md:flex-row justify-start gap-5'>
                        <div className='h-[50]'>
                            <Button type="submit" variant="primary" size="medium">
                                Retrieve
                            </Button>
                        </div>
                        <div className='h-[50]'>
                            <Button type="submit" variant="secondary" size="medium">
                                Submit Config
                            </Button>
                        </div>
                    </div>
                </div>
                <span className="font-inter font-medium text-[#393939] text-base md:text-lg lg:text-xl leading-8 md:leading-9 lg:leading-10">
                    Last data at Today
                </span>

                <div className='flex-grow grid grid-cols-1 md:grid-cols-2 gap-5'>
                    <div className='border p-2 rounded-sm flex flex-col'>
                        <div className="flex justify-between items-center">
                            <div className="p-4 rounded-md">
                                <div className="text-xl font-semibold text-gray-800">
                                    Modbus Config
                                </div>
                            </div>
                            <div className="p-2 rounded-md flex items-center justify-center border border-gray-300 bg-gray-300 cursor-pointer">
                                Reset
                            </div>
                        </div>
                        <div className='grid grid-cols-1 md:grid-cols-2 gap-3 items-center'>
                            <InputField
                                type="text"
                                id="baud-rate"
                                placeholder="Baud Rate"
                                label="Baud Rate"
                                value="99000"
                            />
                            <SearchableSelect
                                options={options.stopBits}
                                value={selectedStopBits}
                                onChange={(option) => setSelectedStopBits(option)}
                                placeholder="Select Stop Bits"
                            />
                        </div>
                        <div className='grid grid-cols-1 md:grid-cols-2 gap-3 items-center mt-4'>
                            <SearchableSelect
                                options={options.parityStatus}
                                value={selectedParityStatus}
                                onChange={(option) => setSelectedParityStatus(option)}
                                placeholder="Select Parity Status"
                            />
                            <InputField
                                type="text"
                                id="wordLen"
                                placeholder="Word Length"
                                label="Word Length"
                                value="9"
                            />
                        </div>

                        <div className='grid grid-cols-1 md:grid-cols-2 gap-3 items-center mt-4'>
                            <InputField
                                type="text"
                                id="sleepTime"
                                placeholder="Sleep Time"
                                label="Sleep Time (Minutes)"
                                value="18"
                            />
                            <InputField
                                type="text"
                                id="firmwareVer"
                                placeholder="Firmware Version"
                                label="Firmware Version Name"
                                value="Generic"
                            />
                        </div>
                    </div>

                    <div className='border p-2 rounded-sm flex flex-col'>
                        <div className="flex justify-between items-center">
                            <div className="p-4 rounded-md">
                                <div className="text-xl font-semibold text-gray-800">
                                    Sensor Config
                                </div>
                            </div>
                            <div className="p-2 rounded-md flex items-center justify-center border border-gray-300 bg-black text-white cursor-pointer">
                                <span className='text-white'>+ Add</span>
                            </div>
                        </div>
                        <div className='grid grid-cols-1 md:grid-cols-2 gap-3 items-center'>
                            <SearchableSelect
                                options={options.parameter}
                                value={selectedParameter}
                                onChange={(option) => setSelectedParameter(option)}
                                placeholder="Select Parameter Name"
                            />
                            <SearchableSelect
                                options={options.resgisterType}
                                value={selectedRegisterType}
                                onChange={(option) => setSelectedRegisterType(option)}
                                placeholder="Select Register Type"
                            />
                        </div>
                        <div className='grid grid-cols-1 md:grid-cols-2 gap-3 items-center mt-4'>
                            <InputField
                                type="text"
                                id="address"
                                placeholder="Register Address"
                                label="Register Address"
                                value="42001"
                            />
                            <InputField
                                type="text"
                                id="slaveId"
                                placeholder="Slave ID"
                                label="Slave ID"
                                value="01"
                            />
                        </div>
                        <div className='grid grid-cols-1 md:grid-cols-2 gap-3 items-center mt-4'>
                            <SearchableSelect
                                options={options.scallingFactor}
                                value={selectedScallingFactor}
                                onChange={(option) => setSelectedScallingFactor(option)}
                                placeholder="Select Scaling Factor"
                            />
                            <SearchableSelect
                                options={options.modbusType}
                                value={selectedModbusType}
                                onChange={(option) => setSelectedModbusType(option)}
                                placeholder="Select Modbus Data Type"
                            />
                        </div>
                        <div className='grid grid-cols-1 md:grid-cols-2 gap-3 items-center mt-4'>
                            <SearchableSelect
                                options={options.dataType}
                                value={selectedDataType}
                                onChange={(option) => setSelectedDataType(option)}
                                placeholder="Select Data Type"
                            />
                            <SearchableSelect
                                options={options.dataSize}
                                value={selectedDataSize}
                                onChange={(option) => setSelectedDataSize(option)}
                                placeholder="Select Data Size"
                            />
                        </div>
                    </div>
                </div>

                <div className='border p-2 rounded-sm'>
                    <CommonTable
                        columns={columns}
                        data={sampleData}
                        title="Overall Summary"
                    />
                </div>
            </div>


        </>
    )
}

export default Modbus_Config