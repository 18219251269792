import React, { Dispatch, ReactNode, useContext, useReducer, createContext } from 'react'
import RoleReducer, { RoleState } from './RoleReducer'
import { RoleAction } from './RoleAction'

interface RoleContextProps {
  state: RoleState
  dispatch: Dispatch<RoleAction>
}

const initialState: RoleState = {
    roles: [],
    loading: false,
}

export const RoleContext = createContext<RoleContextProps>({
    state: initialState,
    dispatch: () => {},
})

interface RoleProviderProps {
    children: ReactNode
}

export const RoleProvider: React.FC<RoleProviderProps> = ({
    children,
}) => {
    const [state, dispatch] = useReducer(RoleReducer, initialState)

    return (
        <RoleContext.Provider
            value={{
                state,
                dispatch,
            }}
        >
            {children}
        </RoleContext.Provider>
    )
}

export const useRoleState = () => {
  const { state, dispatch} = useContext(RoleContext)
  return {
    ...state,
    dispatch,
  }
}
