
import ForgotPasswordForm from './components/ForgotPasswordForm';

const ForgotPassword = () => {


    return (
        <div>
            <ForgotPasswordForm />
        </div>
    );
};

export default ForgotPassword;
