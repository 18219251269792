// src/components/CustomToast.tsx
import React, { useEffect } from 'react'
import SuccessIcon from '../../assets/CustomToast/Success.png'
import ErrorIcon from '../../assets/CustomToast/Failed.png'
import HintIcon from '../../assets/CustomToast/Hint.png'

interface ToastProps {
    message: string
    type: 'success' | 'error' | 'hint'
    duration: number
    onClose: () => void
}

const CustomToast: React.FC<ToastProps> = ({
    message,
    type,
    duration,
    onClose,
}) => {
    useEffect(() => {
        setTimeout(() => {
            onClose();
        }, duration);
    }, [duration, onClose]);

    let iconSrc = ''
    switch (type) {
        case 'success':
            iconSrc = SuccessIcon
            break
        case 'error':
            iconSrc = ErrorIcon
            break
        case 'hint':
            iconSrc = HintIcon
            break
        default:
            break
    }

    return (
        <div
            className={`fixed top-5 right-5 z-50 transition-transform transform ${message ? 'translate-x-0' : 'translate-x-full'
                } border-2 text-black rounded-lg shadow-lg flex flex-col justify-center items-center`}
            style={{
                backgroundColor: '#FFFFFF',
                zIndex: 9999,
            }}
        >
            <div className='flex w-full items-center gap-2 px-4 pt-3 pb-2'>
                <img src={iconSrc} alt={type} className='h-6 w-6' />
                <div className='flex-1'>
                    <div className='flex items-center justify-between mb-1'>
                        <strong
                            className='font-normal break-words'
                            style={{
                                fontSize: '15px',
                                color: '#263238',
                                maxWidth: '200px',
                                wordWrap: 'break-word',
                            }}
                        >
                            {message}
                        </strong>
                        <button onClick={onClose} className='text-black text-3xl ml-5'>
                            &times;
                        </button>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default CustomToast
