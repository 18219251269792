import org_count from '../../../assets/dashboard/org_count.png';
import device_count from '../../../assets/dashboard/device_count.png';
import offline_device from '../../../assets/dashboard/offline_device.png';
import online_device from '../../../assets/dashboard/online_device.png';
import alert_circle from '../../../assets/dashboard/alert_circle.png';
import React, { lazy, Suspense } from 'react';
import { useOverviewState } from '../../../app-context/overview-context/OverviewState';
// import RoundChart from '../../../components/chart/round-chart/RoundChart';
const ActiveInactiveChart = lazy(() => import('../../../components/chart/round-chart/ActiveInactiveChart'))
const Card = lazy(() => import('../../../components/card/Card'));
// import { useNavigate } from 'react-router-dom';
const BarChart = lazy(() => import('../../../components/chart/bar-chart/BarChart'));
// const ActiveInactiveChart = lazy(() => import('../../../components/chart/round-chart/ActiveInactiveChart'));
const LineChart = lazy(() => import('../../../components/chart/line-chart/LineChart'));

const SuperAdminOverview: React.FC = () => {
  const { superadminoverview, loaded } = useOverviewState();
  if(!loaded) return <>Loading</>

// if(!loaded) return null


  // const categories = ['Devices', 'Users', 'Organization'];
  // const seriesData = [
  //   {
  //     name: 'Paid',
  //     data: [3, 2, 0],
  //     color: '#117DAE', // Custom color for 2023 Sales
  //   },
  //   {
  //     name: 'Outstanding',
  //     data: [0, 0, 0],
  //     color: '#A4A4A6', // Custom color for 2024 Sales
  //   },
  // ];



  //   const navigate = useNavigate();


  // const handleCardClick = (cardType: string) => {
  //   switch (cardType) {
  //     case 'organization':
  //       navigate("/home/organization-management");
  //       break;
  //     case 'device':
  //     case 'onlineDevice':
  //     case 'offlineDevice':
  //       navigate("/home/device-provision");
  //       break;
  //     default:
  //       break;
  //   }
  // };



  

  return (
    <div className="h-screen mb-2">
      <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4 p-2 h-full">
        {/* Card 1 */}
        <div className=" rounded-lg bg-white shadow-md p-2 h-min lg:h-full">
          <h5 className="text-sm font-semibold mb-4 flex items-center">
            Summary
            <img src={alert_circle} alt="Alert icon" className="h-5 w-5 ml-2" />
          </h5>
          <div className='grid grid-cols-1 gap-4 md:grid-cols-2 '>
            <Suspense fallback={<div>Loading...</div>}>
              <Card iconBgClass='bg-purple-100' value={superadminoverview?.organizationsCount || 0} heading='Organization Count' bg_color='#ffffff' img={org_count} />
              <Card iconBgClass='bg-orange-100' value={superadminoverview?.devicesCount || 0} heading='Device Count' bg_color='#ffffff' img={device_count} />
              <Card iconBgClass='bg-green-100' value={superadminoverview?.onlineDevicesCount || 0} heading='Online Device' bg_color='#ffffff' img={online_device} />
              <Card iconBgClass='bg-red-100' value={superadminoverview?.offlineDevicesCount || 0} heading='Offline Device' bg_color='#ffffff' img={offline_device} />
            </Suspense>
          </div>

        </div>

        {/* Card 2 */}
        <div className=" rounded-lg bg-white shadow-md p-2 h-min lg:h-full">
          <h5 className="text-sm font-semibold mb-4 flex items-center">
            Subscription Summary
            <img src={alert_circle} alt="Alert icon" className="h-5 w-5 ml-2" />
          </h5>
          <Suspense fallback={<div>Loading...</div>}>
            <BarChart title="Overview" categories={superadminoverview?.subscriptionStat?.categories || []} series={superadminoverview?.subscriptionStat?.seriesData || []}
              height={250} // Custom height
            // width={600}  // Custom width
            />
          </Suspense>
        </div>

        {/* Card 3 */}
        <div className="rounded-lg bg-white shadow-md p-2 h-min lg:h-full">
          <h5 className="text-sm font-semibold mb-4 flex items-center">
            Device Count Info
            <img src={alert_circle} alt="Alert icon" className="h-5 w-5 ml-2" />
          </h5>
          <Suspense fallback={<div>Loading...</div>}>
            <ActiveInactiveChart
              data={[
                { name: 'Active Devices', count: superadminoverview?.onlineDevicesCount || 0, color: '#3B82F6' },
                { name: 'Inactive Devices', count: superadminoverview?.offlineDevicesCount || 0, color: '#EF4444' },
                { name: 'Communication Errors', count: 0, color: '#FBBF24' },
              ]}
              height={190} // Specify height if needed
            />
          </Suspense>
        </div>

        {/* Card 4 */}
        <div className="rounded-lg bg-white shadow-md p-2 h-min lg:h-full">
          <h5 className="text-sm font-semibold mb-4 flex items-center">
            Ticket Summary
            <img src={alert_circle} alt="Alert icon" className="h-5 w-5 ml-2" />
          </h5>
          <Suspense fallback={<div>Loading...</div>}>
            <LineChart yAxisTitle='Tickets Count' title="Overview" categories={superadminoverview.ticketStat?.categories || []} series={superadminoverview.ticketStat?.seriesData || []} height={250} />
          </Suspense>
        </div>
      </div>
    </div>
  );
};

export default SuperAdminOverview;
