export interface PermissionData {
  permissionName: string[]
  permissionValue: string
}

interface UpdatePermissionAction {
  type: 'UPDATE_PERMISSION'
  payload: PermissionData
}

export type PermissionAction = UpdatePermissionAction

export function UpdatePermission(
  dispatch: React.Dispatch<PermissionAction>,
  payload: PermissionData,
) {
  dispatch({
    type: 'UPDATE_PERMISSION',
    payload,
  })
}
