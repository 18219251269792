import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { WATER_METER_TEMPLATE, WATER_TOTALIZER_FLOW, ENERGY_1, AC_MONITORING, WATER_TOTALIZER_JOIN, TEMPERATURE_HUMIDITY_SINGLE, LEVEL_MONITORING_420, TEMPERATURE_HUMADITY_BOTH, LEVEL_MONITORING } from '../../utils/config-templateId/TemplateId';
import DashboardWaterMeter from './water-meter/DashboardWaterMeter';
import DashboardWaterTotalizerFlow from './Water-Totalizer_Flow/DashboardWaterTotalizerFlow';
import DashboardEnergyMonitoring from './Energy-Monitoring/DashboardEnergyMonitoring';
import DashboardACMonitoring from './AC-Controller/DashboardACMonitoring';
import DashboardWaterTotalizerJoin from './Water-Totalizer_Join/DashboardWaterTotalizerJoin';
import DashboardTempHumSingle from './Temperature-Humidity/DashboardTempHumSingle';
import Dashboard420MassLevel from './4-20MassLevel/Dashboard420MassLevel';
import DashboardTempHumDouble from './Temperature-Humidity/DashboardTempHumDouble';
import DashboardLevelMonitoring from './Level-Monitoring/DashboardLevelMonitoring';

const TemplateDashboardRouting = () => {
  // const { _id } = useParams()
  const location = useLocation();
  const state = location.state;
  const [selectedTemplate, setSelectedTemplate] = useState<string | null>(null);

  console.log("Selected Template", selectedTemplate);
  

  useEffect(() => {
    setSelectedTemplate(state.templateId);
  }, [state]);
  return (
    <div>
      {selectedTemplate === WATER_METER_TEMPLATE && (
        <DashboardWaterMeter deviceState={state} />
      )}
      {selectedTemplate === WATER_TOTALIZER_FLOW && (
        <DashboardWaterTotalizerFlow deviceState={state} />
      )}
      {selectedTemplate === ENERGY_1 && (
        <DashboardEnergyMonitoring deviceState={state} />
      )}
      {selectedTemplate === AC_MONITORING && (
        <DashboardACMonitoring deviceState={state} />
      )}
      {selectedTemplate === WATER_TOTALIZER_JOIN && (
        <DashboardWaterTotalizerJoin deviceState={state} />
      )}
      {selectedTemplate === TEMPERATURE_HUMIDITY_SINGLE && (
        <DashboardTempHumSingle deviceState={state} />
      )}
      {selectedTemplate === TEMPERATURE_HUMADITY_BOTH && (
        <DashboardTempHumDouble deviceState={state} />
      )}
      {selectedTemplate === LEVEL_MONITORING_420 && (
        <Dashboard420MassLevel deviceState={state} />
      )}
      {selectedTemplate === LEVEL_MONITORING && (
        <DashboardLevelMonitoring deviceState={state} />
      )}

    </div>
  );
};

export default TemplateDashboardRouting;
