import { Role } from "../../types/Role";
import { RoleAction } from "./RoleAction";

export interface RoleState {
    roles: Role[]
    loading: boolean
}

export default function RoleReducer(
    state: RoleState,
    action: RoleAction,
): RoleState {
    const { type, payload } = action

    switch (type) {
        case 'SET_LOADING':
            return {
                ...state,
                loading: payload,
            };
        case 'SET_ROLE':
            return { ...state, roles: payload,loading :false }
        case 'ADD_ROLE': {
            const { role: newRole } = payload;
            return {
                ...state,
                roles: [newRole, ...state.roles],
            };
        }
        default:
            return state
    }
}