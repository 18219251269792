import React, { useEffect, useState } from 'react'
import InputField from '../../../components/input-field/InputField'
import SearchableSelect from '../../../components/dropdown-select/SearchableSelect'
import DatePicker from '../../../components/custom-date/DatePicker'
import Button from '../../../components/button/Button'
import LinearWithValueLabel from '../../../components/linear-loader/LinearProgressBar'
import BackIcon from '../../../assets/images/back.png';
import { useNavigate } from 'react-router-dom'
import { OptionType } from '../../../types/Dropdown'
import MultiSelectSearchable from '../../../components/dropdown-select/MultiSelectSearchable'
import { generateDayOptions } from '../../../utils/helper/generateDayOptions'
import  { Dayjs } from 'dayjs'
import { useOptions } from '../../../app-context/dropdown-option/OptionState'
import { useCurrentUserState } from '../../../app-context/current-user-context/CurrentUserLoginState'
import { RoleBack } from '../../../types/Role'
import { handleGetAllDevicebyOrgVendorUser, handleGetAllSitesByOrganizationId, handleGetAllTemplate, handleGetAllUser } from '../../../services/api'
import { ROLE_USER_ID } from '../../../utils/env/env.config'



interface ConsumptionReportProps {

}

interface OptionsState {
    vendor: OptionType[];
    user: OptionType[];
    device: OptionType[];
    template: OptionType[];
    sensor: [];
    meterReading: [];
}

const reportFormatOptions = [
    { value: 'pdf', label: 'PDF' },
    { value: 'excel', label: 'Excel' },
]

const methodOptions = [
    { value: 'manual', label: 'Manual' },
    { value: 'auto_generated', label: 'Auto Generated' },
];

const reportGeneratedTypeOption = [
    { value: 'daily', label: 'Daily' },
    { value: 'weekly', label: 'Weekly' },
    { value: 'monthly', label: 'Monthly' },
    { value: 'month_to_date', label: 'Month To Date' },
    { value: 'year_to_date', label: 'Year To Date' },
];

const periodicityOption = [
    { value: 'hourly', label: 'Hourly' },
    { value: 'daily', label: 'Daily' },
    { value: 'weekly', label: 'Weekly' },
    { value: 'monthly', label: 'Monthly' },
];

const receivedTimeOptionWeekly = [
    { value: '1', label: 'Monday' },
    { value: '2', label: 'Tuesday' },
    { value: '3', label: 'Wednesday' },
    { value: '4', label: 'Thursday' },
    { value: '5', label: 'Friday' },
    { value: '6', label: 'Saturday' },
    { value: '7', label: 'Sunday' },
];

const ConsumptionReport: React.FC<ConsumptionReportProps> = () => {
    const [isLoaderVisible, setIsLoaderVisible] = useState(false);
    const [selectedOrganization, setSelectedOrganization] = useState<OptionType | null>(null);
    const [selectedVendor, setSelectedVendor] = useState<OptionType | null>(null);
    const [selectedUser, setSelectedUser] = useState<OptionType | null>(null);
    const [selectedDevice, setSelectedDevice] = useState<OptionType | null>(null);
    const [reportHeader, setReportHeader] = useState<string>('');
    const [selectedMethod, setSelectedMethod] = useState<OptionType | null>(null);
    const [selectedGeneratedType, setSelectedGeneratedType] = useState<OptionType | null>(null);
    const [selectedRecievedOption, setSelectedRecievedOption] = useState<OptionType[]>([]);
    const [startDate, setStartDate] = useState<Dayjs | null>(null);
    const [endDate, setEndDate] = useState<Dayjs | null>(null);
    const [recievedTime, setRecievedTime] = useState<string>('');
    const [filteredPeriodicity, setFilteredPeriodicity] = useState(periodicityOption);
    const [selectedPeriodicity, setSelectedPeriodicity] = useState<OptionType | null>(null);
    const [selectedTemplate, setSelectedTemplate] = useState<OptionType | null>(null);
    const [selectedSensor, setSelectedSensor] = useState<OptionType | null>(null);
    const [selectedMeterReading, setSelectedMeterReading] = useState<OptionType | null>(null);
    const [selectedReportFormt, setSelectedReportFormat] = useState<OptionType | null>(null);

    const recievedTimeOptionMonthly = generateDayOptions();
    const navigate = useNavigate();
    const { currentUser } = useCurrentUserState();
    const {fetchDropDownOptions, state} = useOptions();
    const {organization} = state;
    const [options, setOptions] = useState<OptionsState>({
        vendor: [],
        user: [],
        device: [],
        template: [],
        sensor: [],
        meterReading: []
    });

    useEffect(() => {
        const isOrganizationEmpty = organization.length === 0;
        const isUserNotAdmin = !(currentUser.role.name === RoleBack.SITEADMIN || currentUser.role.name === RoleBack.ORGANIZATIONADMIN);
        if (isOrganizationEmpty && isUserNotAdmin) {
            fetchDropDownOptions('organization', '');
        }
    })

    const handleMethodChange = (selected: OptionType | null) => {
        setSelectedMethod(selected);
    }

    const handleGeneratedTypeChange = (selected: OptionType | null) => {
        setSelectedGeneratedType(selected);

        if(selected?.value == 'daily'){
            setFilteredPeriodicity(periodicityOption.filter((item) => item.value === 'hourly' || item.value === 'daily'));
        } else if(selected?.value == 'weekly'){
            setFilteredPeriodicity(periodicityOption.filter((item) => item.value === 'hourly' || item.value === 'daily' || item.value === 'weekly'));
        } else if(selected?.value == 'monthly'){
            setFilteredPeriodicity(periodicityOption.filter((item) => item.value === 'hourly' || item.value === 'daily' || item.value === 'weekly'));
        } else if(selected?.value == 'month_to_date'){
            setFilteredPeriodicity(periodicityOption.filter((item) => item.value === 'hourly' || item.value === 'daily' || item.value === 'weekly'));
        } else if(selected?.value == 'year_to_date'){
            setFilteredPeriodicity(periodicityOption.filter((item) => item.value === 'weekly' || item.value === 'monthly'));
        } else {
            setFilteredPeriodicity(periodicityOption);
        }
    }

    const handleChangeRecievedOption = (selected: OptionType[]) => {
        setSelectedRecievedOption(selected);
    }

    const handleReportHeader = (event: React.ChangeEvent<HTMLInputElement>) => {
        setReportHeader(event.target.value);
    }

    const handleChangeRecievedTime = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRecievedTime(event.target.value);
    }

    const handleDateChange = (field: string, value: Dayjs | null) => {
        if (field === 'startDate') setStartDate(value);
        else if (field === 'endDate') setEndDate(value);
    }

    const handleReportFormatChange = (selected: OptionType | null) => {
        setSelectedReportFormat(selected);
    }

    const handlePeriodicityChange = (selected: OptionType | null) => {
        setSelectedPeriodicity(selected);
    }   

    // const handleDeviceChange = (selected: OptionType | null) => {

    // }

    const handleDownload = () => {
        setIsLoaderVisible(true);

        const formValues = {
            organization: selectedOrganization?.value || '',
            vendor: selectedVendor?.value || '',
            user: selectedUser?.value || '',
            reportHeader: reportHeader,
            method: selectedMethod?.value || '',
            generatedType: selectedGeneratedType?.value || '',
            startDate: startDate ? startDate.format('DD-MM-YYYY') : '',
            endDate: endDate ? endDate.format('DD-MM-YYYY') : '',
            recievedTime: recievedTime || '',
            recievedOptions: selectedRecievedOption.map(option => option.label),
            periodicity: selectedPeriodicity?.value || '',
            device: selectedDevice?.value || '',
            template: selectedTemplate?.value || '',
            sensor: selectedSensor?.value || '',
            meterReading: selectedMeterReading?.value || '',
            reportFormat: selectedReportFormt?.value || ''
        }

        console.log('Form Values:-- ', formValues);
        // write dwonload login here in setTime out
        setTimeout(() => {
            setIsLoaderVisible(false);
        }, 2000)
    }

    const fetchOptions = async (key: string, _id: string) => {
        try {
            switch (key) {
                case 'site': {
                    if (_id) {
                        const data: OptionType[] = await handleGetAllSitesByOrganizationId(_id);
                        setOptions(prevOptions => ({
                            ...prevOptions,
                            vendor: data
                        }));
                    }
                }
                    break;
                case 'userbyorganization': {
                    if (_id) {
                        const data: OptionType[] = await handleGetAllUser(currentUser.clientId, '', _id, ROLE_USER_ID);
                        setOptions(prevOptions => ({
                            ...prevOptions,
                            user: data
                        }));
                    }
                }
                    break;
                case 'devicebyorganizationVendorUser': {
                    if(_id) {
                        const data: OptionType[] = await handleGetAllDevicebyOrgVendorUser(_id, selectedVendor?.value || '', selectedUser?.value || '');
                        console.log('print device data:- ', data);
                        setOptions(prevOptions => ({
                            ...prevOptions, 
                            device: data
                        }));
                    }
                }
                    break;
                case 'userbysite': {
                    if (_id) {
                        const data: OptionType[] = await handleGetAllUser(currentUser.clientId, _id, '', ROLE_USER_ID);
                        setOptions(prevOptions => ({
                            ...prevOptions,
                            user: data
                        }));
                    }
                }
                    break;
                case 'template': {
                    const data: OptionType[] = await handleGetAllTemplate();
                    console.log('print templates:-', data);
                    setOptions((prevOptions) => ({
                        ...prevOptions,
                        template: data || [],
                    }));
                }
                    break;
                default:
                    console.warn(`No case found for key: ${key}`);
                    break;
            }
        } catch (err) {
            console.log(err)
        }
    }

    const handleBackIcon = () => {
        navigate('/home/reports');
    }

    const isAutoGenerated = selectedMethod?.value === 'auto_generated'
    const isMonthly = selectedGeneratedType?.value === 'monthly'
    const isWeekly = selectedGeneratedType?.value === 'weekly'
    const isDaily = selectedGeneratedType?.value === 'daily'

    return (
        <div className='md:px-10 md:py-4'>
            <div className='mt-10 px-10 py-5 md:bg-white md:rounded-xl md:border md:border-slate-200 md:relative'>
                <div className='border border-[#E2E8F0] p-4 rounded-full w-[60px] h-[60px] justify-center items-center bg-[#E8F2F7] cursor-pointer absolute top-[-30px] left-[-30px] hidden md:flex' onClick={handleBackIcon}>
                    <img src={BackIcon} alt="backicon" />
                </div>
                <form>
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-x-20 gap-y-5'>
                        <SearchableSelect placeholder='Select Organization' options={organization} value={selectedOrganization} onChange={(option) => {
                            setSelectedOrganization(option);
                            fetchOptions('site', option?.value ?? '');
                            setSelectedVendor(null);
                            setSelectedUser(null);
                            fetchOptions('userbyorganization', option?.value ?? '');
                            fetchOptions('devicebyorganizationVendorUser', option?.value ?? '');
                        }} />
                        <SearchableSelect placeholder='Select Vendor' options={options.vendor} value={selectedVendor} onChange={(option) => {setSelectedVendor(option)}} />
                        <SearchableSelect placeholder='Select User' options={options.user} value={selectedUser} onChange={(option) => {setSelectedUser(option)}} />
                        <InputField placeholder='Enter Report Header' label='Enter Report Header' value={reportHeader} onChange={handleReportHeader}/>
                        <SearchableSelect placeholder='Select Method' options={methodOptions} value={selectedMethod} onChange={handleMethodChange} isLoading={false} isDisabled={false} />
                        <SearchableSelect placeholder='Select Report Format' options={reportFormatOptions} value={selectedReportFormt} onChange={handleReportFormatChange} />
                        {!isAutoGenerated ?
                            <>
                                <DatePicker label='Start Date' value={startDate} onChange={(date) => handleDateChange('startDate', date)} />
                                <DatePicker label='End Date' value={endDate} onChange={(date) => handleDateChange('endDate', date)} />
                            </> :
                            <>
                                <SearchableSelect placeholder='Report Generated Type' options={reportGeneratedTypeOption} value={selectedGeneratedType} onChange={handleGeneratedTypeChange} isLoading={false} isDisabled={false}/>
                                <MultiSelectSearchable placeholder='Received On' options={isDaily ? receivedTimeOptionWeekly : isMonthly ? recievedTimeOptionMonthly : isWeekly ? receivedTimeOptionWeekly : []} value={selectedRecievedOption} onChange={handleChangeRecievedOption}/>
                                <InputField label='Received Time' type='time' name='receivedTime' value={recievedTime} onChange={handleChangeRecievedTime}/>
                            </>
                        }

                        <SearchableSelect placeholder='Select Periodicity' options={filteredPeriodicity} value={selectedPeriodicity} onChange={handlePeriodicityChange} />
                        <SearchableSelect placeholder='Select Template' options={options.template} value={selectedTemplate} onChange={(option) => {setSelectedTemplate(option)}}/>
                        <SearchableSelect placeholder='Select Device' options={options.device} value={selectedDevice} onChange={(option) => {setSelectedDevice(option)}} />
                        <SearchableSelect placeholder='Select Sensor' options={options.sensor} value={selectedSensor} onChange={(option) => {setSelectedSensor(option)}} />
                        <SearchableSelect placeholder='Meter Reading' options={options.meterReading} value={selectedMeterReading} onChange={(option) => {setSelectedMeterReading(option)}} />
                    </div>
                    <div className='button-group flex gap-x-2 md:gap-x-10 mt-8 md:w-1/2 md:mx-auto'>
                        <Button type='reset' variant='outline' size='medium'>Reset</Button>
                        <Button type='button' variant='outline' size='medium' onClick={handleDownload}>Download</Button>
                    </div>

                    {isLoaderVisible &&
                        <div className='flex justify-center'>
                            <LinearWithValueLabel />
                        </div>
                    }
                </form>
            </div>
        </div>
    )
}

export default ConsumptionReport
