import { CurrentUser } from '../../types/CurrentLoginUser'
import { CurrentUserAction } from './CurrentLoginUserAction'

export interface CurrentLoginUserState {
  currentUser: CurrentUser,
  loaded: boolean
}

export default function CurrentUserReducer(
  state: CurrentLoginUserState,
  action: CurrentUserAction,
): CurrentLoginUserState {
  const { type, payload } = action

  switch (type) {
    case 'SET_CURRENT_USERS': 
      return { ...state, currentUser: payload ,loaded: true }
    default:
      return state
  }
}
