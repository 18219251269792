import React, {
    createContext,
    useReducer,
    ReactNode,
    Dispatch,
    useContext,
  } from 'react';
  import optionsReducer, { initialState } from './OptionsReducer';
  import { OptionsAction } from './OptionsAction';
import { OptionsState, OptionType } from '../../types/Option';
// import { TemplateTag } from '../../types/Device';
import { handleGetAllOrganizations, handleGetAllSitesByOrganizationId, handleGetAllTemplate, handleGetAllUser } from '../../services/api';
import { useCurrentUserState } from '../current-user-context/CurrentUserLoginState';
import { ROLE_USER_ID } from '../../utils/env/env.config';
import { optionsSlave } from '../../types/Device';

  
  interface OptionsContextProps {
    state: OptionsState;
    dispatch: Dispatch<OptionsAction>;
    fetchDropDownOptions: (key: string, _id: string) => Promise<void>;
  }
  
  const OptionsContext = createContext<OptionsContextProps | undefined>(undefined);
  
  export const OptionsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [state, dispatch] = useReducer(optionsReducer, initialState);
    const {currentUser}=useCurrentUserState()
  
    const fetchDropDownOptions = async (key: string, _id: string) => {
      try {
        dispatch({ type: 'SET_LOADING', payload: true });
        let data: OptionType[]  | undefined;
  
        switch (key) {
          case 'organization': {
            data = await handleGetAllOrganizations();
            break;
          }
          case 'site': {
            if (_id) {
              data = await handleGetAllSitesByOrganizationId(_id);
            }
            break;
          }
          case 'userbyorganization': {
            if (_id) {
              data = await handleGetAllUser(currentUser.clientId, '', _id, ROLE_USER_ID);
            }
            break;
          }
          case 'userbysite': {
            if (_id) {
              data = await handleGetAllUser(currentUser.clientId, _id, '', ROLE_USER_ID);
            }
            break;
          }
          case 'template': {
            data = await handleGetAllTemplate();
            break;
          }
        //   case 'tagbytemplate': {
        //     data = await handleGetTagsByTemplate(_id);
        //     dispatch({
        //       type: 'SET_OPTIONS',
        //       payload: { templateTags: data || [] },
        //     });
        //     return; // Exit early for tagbytemplate as it has a different handling
        //   }
          case 'slave': {
            data = optionsSlave; // Assuming optionsSlave is available in scope
            break;
          }
          default:
            console.warn(`No case found for key: ${key}`);
            return;
        }
  
        if (data) {
          dispatch({
            type: 'SET_OPTIONS',
            payload: { [key]: data },
          });
        }
      } catch (error) {
        console.error('Error fetching options:', error);
      } finally {
        dispatch({ type: 'SET_LOADING', payload: false });
      }
    };
  
    return (
      <OptionsContext.Provider value={{ state, dispatch, fetchDropDownOptions }}>
        {children}
      </OptionsContext.Provider>
    );
  };
  
  export const useOptions = () => {
    const context = useContext(OptionsContext);
    if (!context) {
      throw new Error('useOptions must be used within an OptionsProvider');
    }
    return context;
  };
  