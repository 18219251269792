import React from 'react';
import styles from './Button.module.css'; // Import the CSS module

interface ButtonProps {
  type?: 'button' | 'submit' | 'reset';
  variant?: 'primary' | 'secondary' | 'danger' | 'outline' | 'ghost' | 'link';
  size?: 'small' | 'medium' | 'large';
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  children: React.ReactNode;
  width?: string; // Optional width prop
  height?: string; // Optional height prop
  disabled?: boolean; // Optional disabled prop to disable the button (default is false)
}

const Button: React.FC<ButtonProps> = ({
  type = 'button',
  variant = 'primary',
  size = 'medium',
  onClick,
  children,
  width,
  height,
  disabled = false, // Optional disabled prop
}) => {
  // Create className string based on props
  const buttonClass = `${styles[`btn-${variant}`]} ${styles[`btn-${size}`]}`;

  // Inline styles for dynamic width and height
  const buttonStyle = {
    width: width || '100%', // Default to full width
    height: height || 'auto', // Default to auto height
  };

  return (
    <button type={type} disabled={disabled} className={buttonClass} onClick={onClick} style={buttonStyle}>
      {children}
    </button>
  );
};

export default Button;
