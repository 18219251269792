import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TablePagination, TableSortLabel } from "@mui/material";
import { styled } from "@mui/material/styles";

interface Column {
  field: string;
  headerName: string;
}

interface SensorData {
  [key: string]: string | number; // Flexible key-value pairs
}

interface TabularComponentsProps {
  columns: Column[];
  rows: SensorData[];
  sorting?: boolean;
  pagination?: boolean;
  tableBodyHeight?: string;
}

const StylesTableCell = styled(TableCell)(({ theme }) => ({
  "&.MuiTableCell-root": {
    padding: theme.spacing(2),
  },
}));

const StyledTableSortLabel = styled(TableSortLabel)(() => ({
  '& .MuiTableSortLabel-icon': {
    opacity: 1,
  },
}));

const TabularComponents: React.FC<TabularComponentsProps> = ({
  columns,
  rows,
  sorting = true,
  pagination = true,
  tableBodyHeight = "250px"
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState<string>("");

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedRows = sorting
    ? [...rows].sort((a, b) => {
        const aValue = a[orderBy];
        const bValue = b[orderBy];

        if (typeof aValue === "string" && typeof bValue === "string") {
          return order === "asc"
            ? aValue.localeCompare(bValue)
            : bValue.localeCompare(aValue);
        }

        return order === "asc" ? (aValue as number) - (bValue as number) : (bValue as number) - (aValue as number);
      })
    : rows;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div style={{ width: "100%" }}>
      <TableContainer component={Paper} sx={{ height: tableBodyHeight, overflow: "auto",  width: "100%"}}>
        <Table sx={{   
            width: "100%", 
            tableLayout: "auto", }} stickyHeader  aria-label="simple table" >
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <StylesTableCell
                  key={column.field}
                  sortDirection={orderBy === column.field ? order : false}
                >
                  {column.field === "action" ? (
                    column.headerName
                  ) : (
                    <StyledTableSortLabel
                      active={orderBy === column.field}
                      direction={orderBy === column.field ? order : "asc"}
                      onClick={() => handleRequestSort(column.field)}
                    >
                      {column.headerName}
                    </StyledTableSortLabel>
                  )}
                </StylesTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedRows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, rowIndex) => (
                <TableRow
                  key={rowIndex}
                  sx={{
                    "&:last-child td, &:last-child th": { borderBottom: 0 },
                  }}
                >
                  {columns.map((column) => (
                    <StylesTableCell
                      key={column.field}
                      align="left"
                      sx={{ padding: "8px" }}
                    >
                      {row[column.field]} {/* Directly using the value */}
                    </StylesTableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {pagination && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={sortedRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </div>
  );
};

export default TabularComponents;


// const columns = [
//   { field: 'firstName', headerName: 'First Name' },
//   { field: 'lastName', headerName: 'Last Name' },
//   { field: 'age', headerName: 'Age' },
//   { field: 'status', headerName: 'Status' },
//   { field: 'action', headerName: 'Action' }
// ];

// const rows = [
//   { firstName: 'John', lastName: 'Doe', age: 28, status: 'Active', action: 'Edit' },
//   { firstName: 'Jane', lastName: 'Smith', age: 34, status: 'Inactive', action: 'Edit' },
//   { firstName: 'Alice', lastName: 'Johnson', age: 25, status: 'Active', action: 'Edit' },
//   { firstName: 'Bob', lastName: 'Brown', age: 40, status: 'Pending', action: 'Edit' },
//   { firstName: 'Carol', lastName: 'White', age: 29, status: 'Active', action: 'Edit' }
// ];

// const App: React.FC = () => {
//   return (
//     <div style={{ padding: 20 }}>
//       <h1>Tabular Components Demo</h1>
//       <TabularComponents
//         columns={columns}
//         rows={rows}
//         sorting={true}
//         pagination={true}
//         tableBodyHeight="300px"
//       />
//     </div>
//   );
// };

