import React, { lazy, Suspense, useEffect, useRef, useState } from 'react';
const OrganizationTable = lazy(() => import('./components/OrganizationTable'));
import SearchAndActions from '../../components/search-action/SearchAndActions';
import Modal from '../../components/modal/Modal';
import { useOrganizationState } from '../../app-context/organization-context/OrganizationState';
import { setOrganization } from '../../app-context/organization-context/OrganizationAction';
import { useCurrentUserState } from '../../app-context/current-user-context/CurrentUserLoginState';
// import ParentComponent from '../../components/loader/ParentComponent';
import Loader from '../../components/loader/Loader';
import { usePermissionState } from '../../app-context/permission-context/PermissionState';
import { baseUrlTenant } from '../../utils/env/env.config';
import downloadFile from '../../utils/csv-pdf-interceptor/download-file';
const LazyAddNewOrganizationForm = lazy(() => import('./components/AddNewOrganizationForm'))

const Organization: React.FC = () => {
  const { permissions } = usePermissionState()
  const canCreate = permissions?.organization?.canCreate || false
  const [searchTerm, setSearchTerm] = useState<string | undefined>();
  const [addOrganizationModal, setAddOrganizationModal] = useState(false)
  const { organizations, dispatch, loading } = useOrganizationState()
  const { currentUser } = useCurrentUserState()
  const [downloadProgress, setDownloadProgress] = useState(0);

  const timerRef = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    if (organizations.organizations.length === 0) {
      setOrganization(dispatch, {
        page: 1,
        limit: 10,
        search: '',
        role: currentUser.role.name,
        organizationId: currentUser.organizationId,
      })
    }
  }, [])

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = setTimeout((): void => {
      setOrganization(dispatch, {
        page: 1,
        limit: 10,
        search: event.target.value,
        role: currentUser.role.name,
        organizationId: currentUser.organizationId,
      });
    }, 500);
  };

  const handleAddNew = () => {
    setAddOrganizationModal(true);
  };

  const handleExport = async () => {
    try {
      let queryParams = `organizations/export-excel?`
      if (searchTerm !== '' && searchTerm !== undefined) {
        queryParams += `search=${searchTerm}`;
      }
      await downloadFile(
        baseUrlTenant + queryParams,
        (progress) => {
          setDownloadProgress(progress);
        },
        'GET',
        {},
        'organization.xlsx'
      );
      setDownloadProgress(0);
    } catch (error) {
      setDownloadProgress(0);
    } finally {
      setDownloadProgress(0);

    }
  };



  return (
    <div className="p-1 py-2">
      {(loading || downloadProgress > 0) && <Loader showGradient={(loading || downloadProgress > 0)} />}
      <SearchAndActions
        searchTerm={searchTerm || ''}
        onSearchChange={handleSearchChange}
        onAddNew={handleAddNew}
        onExport={handleExport}
        handelAdd={canCreate}
      />

      <div>
        <Suspense fallback={<div>Loading...</div>}>
          <OrganizationTable />
        </Suspense>
      </div>
      <Modal
        // sx={customSx}
        toggleModal={addOrganizationModal}
        onClose={() => {
          setAddOrganizationModal(false)
        }}
      >
        <Suspense fallback={<div>Loading...</div>}>
          <LazyAddNewOrganizationForm
            onClose={() => {
              setAddOrganizationModal(false)
            }}
            action='ADD_NEW'
          />
        </Suspense>
      </Modal>
      {/* <ParentComponent /> */}
    </div>
  );
};

export default Organization;
