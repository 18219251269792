import { ApiResponse, Organization, OrganizationData } from "../../types/organization";
import { RoleBack } from "../../types/Role";
import { baseUrlTenant } from "../../utils/env/env.config";
import { http } from "../../utils/helper/http";


interface AddOrganizationAction {
  type: 'ADD_ORGANIZATION'
  payload: { organization: Organization; totalItems: number }
}

interface SetOrganizationAction {
  type: 'SET_ORGANIZATIONS'
  payload: OrganizationData
}

interface SetOrganizationPageAction {
  type: 'SET_ORGANIZATION_WITH_PAGE'
  payload: OrganizationData
}

interface UpdateOrganizationAction {
  type: 'UPDATE_ORGANIZATION'
  payload: { id: string; organization: Organization }
}
interface SetLoadingAction {
  type: 'SET_LOADING';
  payload: boolean;
}

export type OrganizationAction =
  | AddOrganizationAction
  | SetOrganizationAction
  | SetOrganizationPageAction
  | UpdateOrganizationAction
  | SetLoadingAction;

export async function setOrganization(
  dispatch: React.Dispatch<OrganizationAction>,
  payload: {
    page: number
    limit: number
    search: string
    role: string
    organizationId: string
  },
) {
  try {
    dispatch({ type: 'SET_LOADING', payload: true });
    const { limit, page, organizationId, role, search } = payload
    let queryParams = `?page=${page}&limit=${limit}`
    if (role === RoleBack.ORGANIZATIONADMIN) {
      queryParams +=
        organizationId !== '' ? `&organizationId=${organizationId}` : ''
    }



    if (search !== undefined && search !== '') {
      queryParams += `&search=${search}`;
    }


    const response = (await http(
      `${baseUrlTenant}organizations${queryParams}`,
    )) as ApiResponse
    
    const { limit: itemPerPage } = response
    const { data } = response
    const dataWithLimit = { ...data, itemPerPage }
    dispatch({
      type: 'SET_ORGANIZATIONS',
      payload: dataWithLimit,
    })
  } catch (err) {
    console.error(err)
  } finally{
    dispatch({ type: 'SET_LOADING', payload: false });
  }
}

export function addNewOrganization(
  dispatch: React.Dispatch<OrganizationAction>,
  payload: { organization: Organization; totalItems: number },
) {
  dispatch({
    type: 'ADD_ORGANIZATION',
    payload,
  })
}

export function setOrganizationPageClick(
  dispatch: React.Dispatch<OrganizationAction>,
  payload: OrganizationData,
) {
  dispatch({
    type: 'SET_ORGANIZATION_WITH_PAGE',
    payload,
  })
}

export function updateOrganization(
  dispatch: React.Dispatch<OrganizationAction>,
  payload: { id: string; organization: Organization },
) {
  dispatch({
    type: 'UPDATE_ORGANIZATION',
    payload,
  })
}
