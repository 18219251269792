import { TicketData } from '../../types/Ticket';
import { TicketAction } from './TicketAction';

export interface TicketState {
    tickets: TicketData,
    loading: boolean
}

export default function TicketReducer(
    state: TicketState,
    action: TicketAction,
): TicketState {
    const { type, payload } = action

    switch (type) {
        case 'SET_LOADING':
            return {
                ...state,
                loading: payload,
            };
        case 'SET_TICKET': {
            return { ...state, tickets: payload, loading: false }
        }
        case 'ADD_TICKET': {
            const { tickets: newTickets, totalItems } = payload
            //   let organizat
            //   if (
            //     state.organizations.organizations.length === 10 ||
            //     state.organizations.organizations.length === 25 ||
            //     state.organizations.organizations.length === 5
            //   ) {
            //     organizat = state.organizations.organizations.slice(0, -1)
            //   } else {
            //     organizat = state.organizations.organizations
            //   }
      
            return {
              ...state,
              tickets: {
                ...state.tickets,
                tickets: [{ ...newTickets }, ...state.tickets.tickets],
                totalItems: totalItems + 1
              },
            };
          }
          case 'UPDATE_TICKET': {
            const { id: updateId, tickets } = payload
            const updatedTickets = state.tickets.tickets.map((t) =>
              t._id === updateId ? { ...t, ...tickets } : t,
            )
            return {
              ...state,
              tickets: {
                ...state.tickets,
                tickets: updatedTickets,
              },
            }
          }
        default:
            return state
    }
}
