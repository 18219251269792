import * as React from 'react';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

// Reusable component with customizable color and size
interface ProgressBarProps extends LinearProgressProps {
  value: number;
  color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  size?: string | number; // Size can be string or number to adjust the width
}

function LinearProgressWithLabel({
  value,
  color = 'primary', // default color is 'primary'
  size = '100%',      // default size is '100%' width
  ...props
}: ProgressBarProps) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', width: size }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" value={value} color={color} {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {`${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

export default function LinearWithValueLabel() {
  const [progress, setProgress] = React.useState(10);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10));
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Box sx={{ width: '100%' }}>
      {/* You can now pass color and size as props to customize */}
      <LinearProgressWithLabel value={progress} color="primary" size="100%" />
    </Box>
  );
}
