// permissionsUtils.js

import { Permission, Permissions, PermissionsObject } from "../../types/PermissionInterfaces";

// export const getPermissionStatus = (
//   permissionName: String,
//   permissionValue: any,
// ) => {
//   if (!Array.isArray(permissionValue)) {
//     return {
//       canView: false,
//       canEdit: false,
//       canDelete: false,
//       canCreate: false,
//     }
//   }
//   // Determine if the user has each permission
//   const canView = permissionValue.includes('View')
//   const canEdit = permissionValue.includes('Edit')
//   const canDelete = permissionValue.includes('Delete')
//   const canCreate = permissionValue.includes('Create')

//   return {
//     canView,
//     canEdit,
//     canDelete,
//     canCreate,
//   }
// }



/**
 * checkPermissions:
 * This function checks an array of permission names (`arrayOf`) against a list of user permissions (`permissions`).
 * It returns an object that maps each permission name to its corresponding CRUD (Create, Read, Update, Delete) permissions or menu access.
 * 
 * - For each permission name in `arrayOf`, the function looks for a matching permission object in the `permissions` array.
 * - If a matching permission is found, it checks for specific permissions like `View`, `Edit`, `Delete`, and `Create`, setting the appropriate flags (`canView`, `canEdit`, `canDelete`, `canCreate`).
 * - It also checks for the `Menu` permission, setting `canMenu` accordingly.
 * - If a permission name is not found in the `permissions` array, the function sets `canMenu` to `false` by default.
 * 
 * The resulting `PermissionsObject` is an object where the keys are the permission names from `arrayOf`, and the values are objects representing the CRUD permissions or menu access for each permission name.
 * 
 * Example Usage:
 * 
 * ```
 * const arrayOfPermissions = ['dashboard', 'settings'];
 * const userPermissions = [
 *   { permission_name: 'dashboard', permission_value: ['View', 'Edit'] },
 *   { permission_name: 'settings', permission_value: ['Menu', 'Create', 'Delete'] }
 * ];
 * 
 * const permissionsObject = checkPermissions(arrayOfPermissions, userPermissions);
 * 
 * // Resulting permissionsObject:
 * // {
 * //   dashboard: { canView: true, canEdit: true, canDelete: false, canCreate: false },
 * //   settings: { canView: false, canEdit: false, canDelete: true, canCreate: true }
 * // }
 * ```
 * 
 * @param arrayOf - Array of permission names to check.
 * @param permissions - Array of user permissions, each containing a `permission_name` and `permission_value`.
 * @returns PermissionsObject - An object mapping each permission name to its CRUD permissions or menu access.
 */

// interface Permission {
//   permission_name: string;
//   permission_value: string[];
// }

// interface CRUDPermissions {
//   canView: boolean;
//   canEdit: boolean;
//   canDelete: boolean;
//   canCreate: boolean;
// }

// interface PermissionsObject {
//   [key: string]: CRUDPermissions | { canMenu: Boolean };
// }

// export function checkPermissions(arrayOf: string[], permissions: Permission[]): PermissionsObject {
//   const result: PermissionsObject = {};

//   for (const permissionName of arrayOf) {
//     const permissionObj = permissions.find(permission => permission.permission_name === permissionName);

//     if (permissionObj) {
//       const sitePermissions: CRUDPermissions = { canView: false, canEdit: false, canDelete: false, canCreate: false };

//       for (const perm of ['Menu', 'View', 'Edit', 'Delete', 'Create'] as const) {
//         (sitePermissions as any)[`can${perm}`] = permissionObj.permission_value.includes(perm);
//       }
//       result[permissionName] = sitePermissions;
//     } else {
//       result[permissionName] = { canMenu: false };;
//     }
//   }

//   return result;
// }
// interface Permission {
//   permission_name: string;
//   permission_value: string[];
// }

// Combine CRUDPermissions and canMenu into one interface
// export interface Permissions {
//   canView: boolean;
//   canEdit: boolean;
//   canDelete: boolean;
//   canCreate: boolean;
//   canMenu: boolean; // Include canMenu here
// }



export function checkPermissions(arrayOf: string[], permissions: Permission[]): PermissionsObject {
  const result: PermissionsObject = {};

  for (const permissionName of arrayOf) {
    const permissionObj = permissions.find(permission => permission.permission_name === permissionName);

    if (permissionObj) {
      const sitePermissions: Permissions = {
        canView: false,
        canEdit: false,
        canDelete: false,
        canCreate: false,
        canMenu: false, 
      };

      for (const perm of ['Menu', 'View', 'Edit', 'Delete', 'Create'] as const) {
        sitePermissions[`can${perm}` as keyof Permissions] = permissionObj.permission_value.includes(perm);
      }
      result[permissionName] = sitePermissions;
    } else {
      // Default permissions when permissionObj is not found
      result[permissionName] = { canView: false, canEdit: false, canDelete: false, canCreate: false, canMenu: false };
    }
  }

  return result;
}
