import { SchedulingData } from '../../types/Scheduling';
import { SchedulingAction } from './SchedulingAction';

export interface SchedulingState {
    schedulings: SchedulingData,
    loading: boolean
}

export default function SchedulingReducer(
    state: SchedulingState,
    action: SchedulingAction,
): SchedulingState {
    const { type, payload } = action

    switch (type) {
        case 'SET_LOADING':
            return {
                ...state,
                loading: payload,
            };
        case 'SET_SCHEDULING': {
            return { ...state, schedulings: payload, loading: false }
        }

        case 'ADD_SCHEDULING': {
            const { scheduling: newScheduling, totalItems } = payload;

            return {
                ...state,
                schedulings: {
                    ...state.schedulings,
                    schedulings: [newScheduling,...(state.schedulings?.schedulings || [])],
                    totalItems: totalItems + 1,
                }
            }
        }

        case 'UPDATE_SCHEDULE': {
            const { id: updateId, scheduling } = payload

            const updatedScheduling = state.schedulings.schedulings.map((sched) =>
                sched._id === updateId? {...sched,...scheduling} : sched,
            )

            return {
                ...state,
                schedulings: {
                   ...state.schedulings,
                    schedulings: updatedScheduling,
                }
            }
        }
        default:
            return state
    }
}
