import { VendorData } from '../../types/Vender'
import { VendorAction } from './VendorAction'

export interface SiteState {
  vendor: VendorData,
  loading: boolean
}

export default function VendorReducer(
  state: SiteState,
  action: VendorAction,
): SiteState {
  const { type, payload } = action
  switch (type) {
    case 'SET_LOADING':
      return {
        ...state,
        loading: payload,
      };
    case 'SET_SITE': {
      return { ...state, vendor: payload, loading: false }
    }

    case 'ADD_SITE': {

      const { vendor: newSite, totalItems } = payload
      return {
        ...state,
        vendor: {
          ...state.vendor,
          vendor: [newSite, ...state.vendor.vendor],
          totalItems: totalItems + 1,
        },
      }
    }
    case 'UPDATE_SITE': {
      const { id: updateId, vendor } = payload
      const updatedSite = state.vendor.vendor.map((s) =>
        s._id === updateId ? { ...s, ...vendor } : s,
      )

      return {
        ...state,
        vendor: {
          ...state.vendor,
          vendor: updatedSite,
        },
      }
    }
    default:
      return state
  }
}
