import { ApiResponseDevice, Device, DeviceData } from '../../types/Device'
import { RoleBack } from '../../types/Role';
import { baseUrlDevice } from '../../utils/env/env.config';
import { http } from '../../utils/helper/http';

interface AddDeviceAction {
  type: 'ADD_DEVICE'
  payload: { device: Device; totalItems: number }
}

interface SetDeviceAction {
  type: 'SET_DEVIVCE'
  payload: DeviceData
}

interface SetDeciceBasedOnSiteAction {
  type: 'SET_DEVICE_WITH_SITE'
  payload: DeviceData
}

interface UpdateDeviceAction {
  type: 'UPDATE_DEVICE'
  payload: { id: string; device: Device }
}

interface SetLoadingAction {
  type: 'SET_LOADING';
  payload: boolean;
}

export type DeviceAction =
  | AddDeviceAction
  | SetDeviceAction
  | SetDeciceBasedOnSiteAction
  | UpdateDeviceAction
  | SetLoadingAction

export async function setDevice(
  dispatch: React.Dispatch<DeviceAction>,
  payload: {
    page: number
    limit: number
    search?: string
    organizationId: string
    vendorId: string
    userId?: string
    role: string,
  },
) {
  try {
    
    dispatch({ type: 'SET_LOADING', payload: true });
    const { limit, page, organizationId, vendorId, userId, search, role } = payload
    let queryParams = `?page=${page}&limit=${limit}`
    if (role === RoleBack.ORGANIZATIONADMIN) {
      queryParams +=
        organizationId !== '' ? `&organizationId=${organizationId}` : ''
    } else if (role === RoleBack.SITEADMIN) {
      queryParams += `&vendorId=${vendorId}`
    }
    if (role === RoleBack.USER) {
      queryParams += userId ? `&userId=${userId}` : ''
    }
    if (search !== undefined && search !== '') {
      queryParams += `&search=${search}`;
    }

    
    const response = (await http(
      `${baseUrlDevice}devices${queryParams}`,
    )) as ApiResponseDevice
    const { limit: itemPerPage } = response
    const { data } = response
    const dataWithLimit = { ...data, itemPerPage }
    dispatch({
      type: 'SET_DEVIVCE',
      payload: dataWithLimit,
    })
  } catch (err) {
    console.error(err)
  } finally{
    dispatch({ type: 'SET_LOADING', payload: false });
  }
}

export function addNewDevice(
  dispatch: React.Dispatch<DeviceAction>,
  payload: { device: Device; totalItems: number },
) {
  dispatch({
    type: 'ADD_DEVICE',
    payload,
  })
}

export function setDeviceBasedOneSite(
  dispatch: React.Dispatch<DeviceAction>,
  payload: DeviceData,
) {
  dispatch({
    type: 'SET_DEVICE_WITH_SITE',
    payload,
  })
}

export function updateDevice(
  dispatch: React.Dispatch<DeviceAction>,
  payload: { id: string; device: Device },
) {
  dispatch({
    type: 'UPDATE_DEVICE',
    payload,
  })
}
