import { useEffect, useState } from 'react';
import { logout } from '../../app-context/current-user-context/CurrentLoginUserAction';

const Countdown = () => {
    const [countdown, setCountdown] = useState(10); // Set initial countdown to 10

    useEffect(() => {
        const timer = setInterval(() => {
            setCountdown((prevCountdown) => {
                if (prevCountdown === 1) {
                    clearInterval(timer);
                    logout(); // Call logout once countdown reaches 0
                    return 0;
                }
                return prevCountdown - 1;
            });
        }, 1000);

        // Clear timer on component unmount
        return () => clearInterval(timer);
    }, []);

    return <div className="text-4xl font-bold text-red-500 mb-8">{countdown}</div>;
};

export default Countdown;
