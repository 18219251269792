import React, { useEffect, useState } from 'react'
import { useCurrentUserState } from '../../app-context/current-user-context/CurrentUserLoginState'
import { RoleBack } from '../../types/Role'

interface ProtectedComponentProp {
  roleName?: string[]
  children?: React.ReactNode
}

/**
 * ProtectedComponent:
 * This component conditionally renders its children based on the current user's role.
 * It takes an optional array of role names (`roleName`) as a prop and checks if the current user's role matches any of them.
 * 
 * - If the user's role matches one of the roles in `roleName`, the component will render its children.
 * - If the user's role does not match any of the roles in `roleName`, the component will hide its children.
 * 
 * This is useful for protecting certain parts of the UI, allowing access only to users with specific roles.
 * 
 * Props:
 * - roleName: An optional array of role names that are allowed to view the children. Defaults to `[RoleBack.SUPERADMIN]`.
 * - children: The React elements to conditionally render.
 */
const ProtectedComponent: React.FC<ProtectedComponentProp> = ({
  roleName = [RoleBack.SUPERADMIN],
  children,
}) => {
  const [allow, setAllow] = useState(false)
  const { currentUser } = useCurrentUserState()
  useEffect(() => {
    if (currentUser) {
      try {
        if (roleName.includes(currentUser.role.name)) {
          setAllow(true)
        }
      } catch (error) {
        console.log('Error decoding token: ', error)
      }
    }
  }, [roleName])

  return <>{allow ? children : ''}</>
}

export default ProtectedComponent
