import React, {
    createContext,
    useReducer,
    ReactNode,
    Dispatch,
    useContext,
  } from 'react'
import SchedulingReducer, { SchedulingState } from './SchedulingReducer'
import { SchedulingAction } from './SchedulingAction'

  
  interface SchedulingContextProps {
    state: SchedulingState
    dispatch: Dispatch<SchedulingAction>
  }
  
  const initialState: SchedulingState = {
    schedulings: {
    //   limit: 0,
      schedulings: [],
      currentPage: 0,
      totalPages: 0,
      previousPage: 0,
      totalItems: 0,
    },
    loading: false
  }
  
  export const ScedulingContext = createContext<SchedulingContextProps>({
    state: initialState,
    dispatch: () => {},
  })
  
  interface SchedulingProviderProps {
    children: ReactNode
  }
  
  export const SchedulingProvider: React.FC<SchedulingProviderProps> = ({ children }) => {
    const [state, dispatch] = useReducer(SchedulingReducer, initialState)
  
    return (
      <ScedulingContext.Provider
        value={{
          state,
          dispatch,
        }}
      >
        {children}
      </ScedulingContext.Provider>
    )
  }
  
  export const useSchedulingState = () => {
    const { state, dispatch } = useContext(ScedulingContext)
    return { ...state, dispatch }
  }
  