import React from 'react';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Modal from '../modal/Modal';

interface AlertDeleteProps {
  isModalOpen: boolean;
  handleCloseModal: () => void;
  handleConfirmDelete: () => void;
  disable?: boolean;
}

const AlertDelete: React.FC<AlertDeleteProps> = ({
  isModalOpen,
  handleCloseModal,
  handleConfirmDelete,
  disable
}) => {
  return (
    <Modal toggleModal={isModalOpen} onClose={handleCloseModal}>
      <div className="bg-white p-8 rounded-lg shadow-md max-w-md mx-auto">
        <div className="text-center">
          <WarningAmberIcon className="text-yellow-500 mr-2" />
        </div>
        <h2 className="text-2xl flex items-center justify-center mb-4">
          Are you sure?
        </h2>
        <span className="block mb-4 text-center">
          This item will be deleted permanently!
        </span>
        <div className="flex justify-center space-x-4">
          <button
            onClick={handleCloseModal}
            className="px-4 py-2 bg-gray-300 text-black rounded-md"
          >
            Cancel
          </button>
          <button
            disabled={disable}
            onClick={handleConfirmDelete}
            className="px-4 py-2 bg-red-500 text-white rounded-md"
          >
            Yes, Sure
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AlertDelete;
