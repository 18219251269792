// import {  useEffect, useState } from 'react' // Import useState
// import Navbar from '../navbar/Navbar'
// import Sidebar from '../sidebar/Sidebar'
// import { Outlet } from 'react-router-dom'
// import styles from './homepage.module.css'
// import { logout, setCurrentUserLogin } from '../../app-context/current-user-context/CurrentLoginUserAction'
// import { useCurrentUserState } from '../../app-context/current-user-context/CurrentUserLoginState'

// const HomePage = () => {
//   const [isSidebarOpen, setIsSidebarOpen] = useState(false)
//   const {dispatch}=useCurrentUserState()

//   const toggleSidebar = () => {
//     setIsSidebarOpen(!isSidebarOpen)
//   }


//   useEffect(() => {
    
//     if (window.localStorage.getItem('token')) {
//       setCurrentUserLogin(dispatch)
//     } else {
//       logout()
//     }
//   }, [dispatch])


//   const width = window.innerWidth
//   return (
//     <>
//       {/* AFTER LOGIN SUCCES THIS COMPONENT WILL CALL AND SET THE THE ROUTE BASED ON USER */}
//       {/* <PermissionRoute /> */}
//       <div className='flex flex-col md:flex-row h-screen overflow-hidden'>
//         <Sidebar
//           isSidebarOpen={isSidebarOpen}
//           toggleSidebar={toggleSidebar}
//           width={width}
//         />
//         <div className='relative flex-1 flex-col overflow-hidden ' style={{background:"#F5F6FA"}}>
//           <Navbar isSidebarOpen={isSidebarOpen} width={width} />
//           <main className='flex-1 overflow-y-auto z-10'>
//             <div
//               style={{
//                 overflowX: 'auto',
//                 maxHeight: width <= 500 ? '92vh' : '100vh',
//               }}
//               className={`sm:h-[90vh] lg:h-[85vh] xl:h-[85vh] p-1 ml-20 md:ml-20 sm:ml-[4rem] w-auto ${isSidebarOpen && width > 500 ? styles.ml_sidebar : ''}`}
//             >
//               <Outlet />
//             </div>
//           </main>
//         </div>
//       </div>
//     </>
//   )
// }

// export default HomePage



// import { useEffect, useState } from 'react';
// import Navbar from '../navbar/Navbar';
// import Sidebar from '../sidebar/Sidebar';
// import { Outlet } from 'react-router-dom';
// import styles from './homepage.module.css';
// import { logout, setCurrentUserLogin } from '../../app-context/current-user-context/CurrentLoginUserAction';
// import { useCurrentUserState } from '../../app-context/current-user-context/CurrentUserLoginState';

// const HomePage = () => {
//   const [isSidebarOpen, setIsSidebarOpen] = useState(false);
//   const { dispatch } = useCurrentUserState();

//   useEffect(() => {
//     const token = window.localStorage.getItem('token');
//     if (token) {
//       setCurrentUserLogin(dispatch);
//     } else {
//       logout();
//     }
//   }, [dispatch]);

//   const toggleSidebar = () => {
//     setIsSidebarOpen(prev => !prev);
//   };

//   const windowWidth = window.innerWidth;

//   return (
//     <div className="flex flex-col md:flex-row h-screen overflow-hidden">
//       <Sidebar
//         isSidebarOpen={isSidebarOpen}
//         toggleSidebar={toggleSidebar}
//         width={windowWidth}
//       />
//       <div className="relative flex-1 flex-col overflow-hidden" style={{ background: "#F5F6FA" }}>
//         <Navbar isSidebarOpen={isSidebarOpen} width={windowWidth} />
//         <main className="flex-1 overflow-y-auto h-full">
//           <div
//             style={{
//               overflowX: 'auto',
//               maxHeight: windowWidth <= 500 ? '92vh' : '100vh',
//             }}
//             className={` sm:h-[90vh] lg:h-[90vh] xl:h-[85vh] p-1 transition-all duration-300 ml-20 md:ml-20 sm:ml-[4rem] w-auto  ${isSidebarOpen && windowWidth > 500 ? styles.ml_sidebar : ''}`}
//           >
//             <Outlet />
//           </div>
//         </main>
//       </div>
//     </div>
//   );
// };

// export default HomePage;

import { useEffect, useState } from 'react';
import Navbar from '../navbar/Navbar';
import Sidebar from '../sidebar/Sidebar';
import { Outlet } from 'react-router-dom';
import styles from './homepage.module.css';
import { logout, setCurrentUserLogin } from '../../app-context/current-user-context/CurrentLoginUserAction';
import { useCurrentUserState } from '../../app-context/current-user-context/CurrentUserLoginState';
import { usePermissionState } from '../../app-context/permission-context/PermissionState';
import { UpdatePermission } from '../../app-context/permission-context/PermissionAction';

const HomePage = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { dispatch } = useCurrentUserState();
  const { dispatch: D } = usePermissionState()

  useEffect(() => {
    const token = window.localStorage.getItem('token');
    if (token) {
      setCurrentUserLogin(dispatch);
      UpdatePermission(D, {
        permissionName: [
          'site',
          'organization',
          'tenant',
          'device',
          'tag',
          'user',
          'auditlog',
          'alert',
          'template',
          'roleback'
        ],
        permissionValue: token,
      })
    } else {
      logout();
    }
  }, [dispatch]);

  const toggleSidebar = () => {
    setIsSidebarOpen(prev => !prev);
  };

  const windowWidth = window.innerWidth;

  return (
    <div className="flex flex-col md:flex-row h-screen overflow-hidden">
      <Sidebar
        isSidebarOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
        width={windowWidth}
      />
      <div className="relative flex-1 flex-col" style={{ background: "#F5F6FA" }}>
        <Navbar isSidebarOpen={isSidebarOpen} width={windowWidth} />
        <main className="flex-1 overflow-hidden">
          <div
            style={{
              // overflowX: 'auto',
              overflowY: 'auto', // Allow vertical scrolling
              height: 'calc(100vh - 64px)', // Adjust for your navbar height
              boxSizing: 'border-box', // Include padding in total width
            }}
            className={`transition-all  duration-300 ml-20 md:ml-20 sm:ml-[4rem] ${isSidebarOpen && windowWidth > 500  ? styles.ml_sidebar : ''} `}

          >
            <Outlet />
          </div>
        </main>
      </div>
    </div>
  );
};

export default HomePage;
