import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

interface DeviceData {
  name: string;
  count: number;
  color: string;
}

interface ActiveInactiveChartProps {
  data: DeviceData[];
  height?: number;
}

const ActiveInactiveChart: React.FC<ActiveInactiveChartProps> = ({
  data,
  height = 190,
}) => {
  const totalDevices = data.reduce((total, item) => total + item.count, 0);

  const chartOptions = {
    chart: {
      type: 'pie',
      options3d: {
        enabled: true,
        alpha: 10,
      },
    },
    accessibility: {
      enabled: false,
    },
    title: {
      text: `${totalDevices}`,
      align: 'center',
      verticalAlign: 'middle',
      style: {
        fontSize: '16px',
        fontWeight: 'bold',
      },
      floating: true,
    },
    plotOptions: {
      pie: {
        innerSize: '50%',
        depth: 45,
        dataLabels: {
          enabled: false,
        },
      },
    },
    series: [{
      name: 'Devices',
      data: data.map(item => ({
        name: item.name,
        y: item.count,
        color: item.color,
      })),
    }],
    credits: {
      enabled: false,
    },
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions}
        containerProps={{ style: { height: `${height}px`, width: '100%' } }}
      />
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', flexWrap: 'wrap' }}>
        {data.map(item => (
          <div key={item.name} style={{ display: 'flex', margin: '5px 15px' }}>
            <div
              style={{
                width: '20px', // Width for a horizontal line
                height: '3px', // Height for a thinner line
                backgroundColor: item.color,
                marginRight: '5px',
                alignSelf: 'center', // Center the line vertically
                marginBottom: "20px"
              }}
            />
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <span style={{ fontSize: '14px' }}>{item.name}</span>
              <span style={{ fontSize: '12px' }} className="font-bold">{item.count} Device</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ActiveInactiveChart;
