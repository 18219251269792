import React, {
  createContext,
  useReducer,
  ReactNode,
  Dispatch,
  useContext,
} from 'react'
import OrganizationReducer, { OrganizationState } from './OrganizationReducer'
import { OrganizationAction } from './OrganizationAction'

interface OrganizationContextProps {
  state: OrganizationState
  dispatch: Dispatch<OrganizationAction>
}

const initialState: OrganizationState = {
  organizations: {
    limit: 0,
    organizations: [],
    currentPage: 0,
    totalPages: 0,
    previousPage: 0,
    totalItems: 0,
  },
  loading: false,
}

export const OrganizationContext = createContext<OrganizationContextProps>({
  state: initialState,
  dispatch: () => {},
})

interface OrganizationProviderProps {
  children: ReactNode
}

export const OrganizationProvider: React.FC<OrganizationProviderProps> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(OrganizationReducer, initialState)

  // useEffect(() => {
  //   setOrganization(dispatch, {page: 1, limit: 10}) // Call setOrganization only once when the component mounts
  // }, []) // Empty dependency array ensures it only runs on mount, not on updates

  return (
    <OrganizationContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </OrganizationContext.Provider>
  )
}

export const useOrganizationState = () => {
  const { state, dispatch } = useContext(OrganizationContext)
  return { ...state, dispatch }
}
