import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

interface SeriesData {
  name: string;
  data: number[];
  color?: string;
}

interface ColumnLineChartProps {
  title: string;
  categories: string[];
  series: SeriesData[];
  xAxisTitle?: string;
  yAxisTitle?: string;
  height?: number;
}

const ColumnLineChart: React.FC<ColumnLineChartProps> = ({
  title,
  categories,
  series,
  height,
  yAxisTitle,
  xAxisTitle,
}) => {
  const options = {
    chart: {
      type: 'line',
      height: height,
    },
    title: {
      text: title,
    },
    xAxis: {
      categories: categories,
      title: {
        text: xAxisTitle ? xAxisTitle : 'Categories',
      },
      lineWidth: 0, // Hide x-axis line
      tickWidth: 0,
    },
    yAxis: {
      title: {
        text: yAxisTitle ? yAxisTitle : 'State', // Updated to reflect On/Off
      },
      labels: {
        formatter: function (this: Highcharts.AxisLabelsFormatterContextObject) {
          return this.value === 1 ? 'On' : 'Off'; // Display labels as "On" or "Off"
        },
      },
      min: 0,
      max: 1,
      tickInterval: 1, // Set interval to show ticks at 0 and 1
      gridLineWidth: 0, // Hide grid lines on y-axis
      lineWidth: 0,
    },
    tooltip: {
  formatter: function (this: Highcharts.TooltipFormatterContextObject) {
    const value = this.point.y ?? 0; // Use nullish coalescing to default to 0 if undefined
    return value === 1 ? 'On' : 'Off' // Display the value if greater than 0, otherwise show "Off"
  },
},
    plotOptions: {
      series: {
        step: 'center', // Add this for stair-step transitions
        marker: {
          enabled: false, // Disables the point markers
        },
        lineWidth: 2, // Adjust line thickness
      },
    },
    series: series.map((s) => ({
      ...s,
      color: s.color || undefined, // Use the color if provided, or default
      data: s.data.map(value => value > 0 ? 1 : 0), // Plot 1 for non-zero values and 0 for zeros
    })),
    credits: {
      enabled: false, // Disable the watermark
    },
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default ColumnLineChart;
