import React from 'react';

interface SensorCardProps {
    sensor: string;
    value: string | number;
    unit: string;
    sensorColor?: string; // Color for the sensor name
    valueColor?: string; // Color for the value
    bg_color?: string; // Background color
    unitcolor?: string; // Color for the unit
    onClick?: () => void;
}

const SensorCard: React.FC<SensorCardProps> = ({
    sensor,
    value,
    unit,
    sensorColor,
    valueColor,
    bg_color,
    onClick,
    unitcolor
}) => {
    
    return (
        <div
            className="relative rounded-lg border-stroke p-3 border cursor-pointer flex-1 mx-1 mb-2" // Added margin-bottom for spacing between cards
            style={{ backgroundColor: bg_color || 'white' }}
            onClick={onClick}
        >
            <div className="flex items-center">
                <p className="font-medium flex-1" style={{ color: sensorColor || "#202224" }}>
                    {sensor}
                </p>
            </div>
            <div className="mt-4 flex">
                <h4 className="text-title-md font-bold" style={{ color: valueColor || 'black' }}>
                    {value}
                    <span className="text-sm ml-1" style={{ color: unitcolor || 'gray' }}>{unit}</span>
                </h4>
            </div>
        </div>
    );
};

export default SensorCard;
