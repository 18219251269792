interface AddRouteAction {
    type: 'SET_ROUTE'
    payload: { route: string }
}

export type RouteAction =
    | AddRouteAction


export function changeRoute(
    dispatch: React.Dispatch<RouteAction>,
    payload: { route: string },
) {
    dispatch({
        type: 'SET_ROUTE',
        payload,
    })
}
