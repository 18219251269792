import React from 'react'
import { useNavigate } from 'react-router-dom';

interface ReportCategoryCardProps {
    subtitle?: string;
    title: string;
    icon?: string | React.ReactNode;
    iconBgClass?: string;
    onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
    link: string;
}

const ReportCategoryCard: React.FC<ReportCategoryCardProps> = ({subtitle, title, icon, iconBgClass = 'bg-purpe-200', onClick, link }) => {
  const navigate = useNavigate();
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (onClick) {
      onClick(event);
    }
    if (link) {
      navigate(link); // Navigate to the provided link
    }
  };
  return (
    <div className="flex items-center justify-between bg-white p-4 hover:shadow-lg rounded-lg w-full max-w-sm cursor-pointer" onClick={handleClick}>
      <div>
        <p className="text-gray-500 text-sm">{subtitle}</p>
        <h2 className="text-base md:text-xl font-semibold">{title}</h2>
      </div>
      <div className={`flex items-center justify-center w-12 h-12 ${iconBgClass} rounded-2xl`}>
      {typeof icon === 'string' ? (
          <img src={icon} alt="icon" className="w-8 h-8" />
        ) : (
          icon
        )}
      </div>
    </div>
  )
}

export default ReportCategoryCard;
