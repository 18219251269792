import ResetPasswordForm from './components/ResetPasswordForm';

const ResetPassword = () => {

  return (
    <div>
      <ResetPasswordForm />
    </div>
  );
};

export default ResetPassword;
