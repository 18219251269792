import { RoleBack } from '../../types/Role';
import { ApiResponseSite, Vendor, VendorData } from '../../types/Vender';
import { baseUrlTenant } from '../../utils/env/env.config';
import { http } from '../../utils/helper/http';

interface AddSiteAction {
  type: 'ADD_SITE'
  payload: { vendor: Vendor; totalItems: number }
}

interface SetSiteAction {
  type: 'SET_SITE'
  payload: VendorData
}

interface UpdateSiteAction {
  type: 'UPDATE_SITE'
  payload: { id: string; vendor: Vendor }
}

interface SetLoadingAction {
  type: 'SET_LOADING';
  payload: boolean;
}

export type VendorAction = AddSiteAction | SetSiteAction | UpdateSiteAction | SetLoadingAction

export async function setSite(
  dispatch: React.Dispatch<VendorAction>,
  payload: {
    page: number
    limit: number
    search: string
    organizationId: string
    vendorId: string
    role: string,
  },
) {
  try {
    dispatch({ type: 'SET_LOADING', payload: true });
    const { limit, page, organizationId, vendorId, role,search } = payload
    let queryParams = `?page=${page}&limit=${limit}`
    if (role === RoleBack.ORGANIZATIONADMIN) {
      queryParams +=
        organizationId !== '' ? `&organizationId=${organizationId}` : ''
    } else if (role === RoleBack.SITEADMIN) {
      queryParams += `&vendorId=${vendorId}`
    }
    if (search !== undefined && search !== '') {
      queryParams += `&search=${search}`;
    }

    const response = (await http(
      `${baseUrlTenant}/sites${queryParams}`,
    )) as ApiResponseSite
    const { limit: itemPerPage } = response
    const { data } = response
    const dataWithLimit = { ...data, itemPerPage }
    dispatch({
      type: 'SET_SITE',
      payload: dataWithLimit,
    })
  } catch (err) {
    console.error(err)
  } finally {
    dispatch({ type: 'SET_LOADING', payload: false });
  }
}

export function addNewSite(
  dispatch: React.Dispatch<VendorAction>,
  payload: { vendor: Vendor; totalItems: number },
) {
  dispatch({
    type: 'ADD_SITE',
    payload,
  })
}

// export function setOrganizationPageClick(
//   dispatch: React.Dispatch<OrganizationAction>,
//   payload: OrganizationData,
// ) {
//   dispatch({
//     type: 'SET_ORGANIZATION_WITH_PAGE',
//     payload,
//   })
// }

export function updateSite(
  dispatch: React.Dispatch<VendorAction>,
  payload: { id: string; vendor: Vendor },
) {
  dispatch({
    type: 'UPDATE_SITE',
    payload,
  })
}
