import { useNavigate } from "react-router-dom";
import { useDeviceState } from "../../../../app-context/device-context/DeviceState"
import StatusBadge from "../../../../components/status/StatusBadge";
import CommonTable from "../../../../components/table/Table";
import { Column, DataRow } from "../../../../types/table";
import { Device } from "../../../../types/Device";

const columns: Column[] = [
  { id: 'deviceAlias', label: 'Device Alias' },
  { id: 'deviceName', label: 'DEVICE NAME' },
  { id: 'status', label: 'STATUS', align: 'center' },
];
const DeviceTable = () => {
  const { device } = useDeviceState()
  const navigate = useNavigate()
  const data: DataRow[] =
    device.device.length > 0
      ? device.device.map((row) => ({
        ...row,
        deviceAlias: (
          <span className="cursor-pointer" onClick={() => { handleClick(row._id, row) }}>{row.deviceAlias}</span>
        ),
        status: <StatusBadge status={row.status ? 'active' : 'inactive'} />,
      }))
      : []
  const handleClick = (_id: string, deviceState: Device) => {
    navigate("/home/selected-device/" + _id, { state: deviceState })
  }
  const handlePageChange = (newPage: number) => {
    console.log('Page changed to:', newPage);
  };

  const handleChangeRowsPerPage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newRowsPerPage = +event.target.value;
    console.log('Rows per page changed to:', newRowsPerPage);
  };
  return (
    <div>

      <CommonTable
        columns={columns}
        data={data}
        pagination={{
          rowsPerPageOptions: [5, 10, 15],
          rowsPerPage: 10,
          page: 1,
          handlePageChange,
          handleChangeRowsPerPage,
          totalItems: device.totalItems,
          currentPage: device.currentPage,
          totalPages: device.totalPages,
        }}
        setHeight= "60vh"
      />
    </div>
  )
}

export default DeviceTable