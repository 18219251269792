// import { Link } from 'react-router-dom';
// import { useFormik } from 'formik';
// import * as Yup from 'yup';
// import Container from '../../../components/container/Container';
// import InputField from '../../../components/input-field/InputField';
// import Button from '../../../components/button/Button';

// const ForgotPasswordForm = () => {
//   const formik = useFormik({
//     initialValues: {
//       email: '',
//     },
//     validationSchema: Yup.object({
//       email: Yup.string()
//         .email('Invalid email address')
//         .required('Email is required'),
//     }),
//     onSubmit: async (values) => {
//       // Handle form submission logic here
//       console.log('Form submitted:', values);
//     },
//   });

//   return (
//     <Container heading="Forgot Password">
//       <form onSubmit={formik.handleSubmit} className="w-full">
//         <InputField
//           name="email"
//           type="email"
//           label="Email"
//           placeholder="Enter your email"
//           required
//           value={formik.values.email}
//           onChange={formik.handleChange}
//           onBlur={formik.handleBlur}
//           error={formik.touched.email && formik.errors.email ? formik.errors.email : ''}
//         />
//         <div className="flex flex-col sm:flex-row justify-between items-center space-y-4 sm:space-y-0">
//           <Link to="/login" className="text-sm text-deepcerulan-600 hover:text-deepcerulan-500 underline">
//             Back to Login
//           </Link>
//         </div>
//         <div className="mt-2">
//           <Button type="submit" variant="primary" size="medium">
//             Submit
//           </Button>
//         </div>
//       </form>
//     </Container>
//   );
// };

// export default ForgotPasswordForm;
import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Link } from 'react-router-dom';
import Container from '../../../components/container/Container';
import InputField from '../../../components/input-field/InputField';
import Button from '../../../components/button/Button';

interface ForgotPasswordFormValues {
  email: string;
}

const ForgotPasswordForm: React.FC = () => {
  const { register, handleSubmit, formState: { errors } } = useForm<ForgotPasswordFormValues>();

  const onSubmit: SubmitHandler<ForgotPasswordFormValues> = async (data) => {
    console.log('Form submitted:', data);
  };


  return (
    <Container heading="Forgot Password">
      <form onSubmit={handleSubmit(onSubmit)} className="w-full space-y-2">
        <InputField
          type="email"
          id="email"
          placeholder="Enter your email"
          label="Email"
          required
          {...register('email', {
            required: 'Email is required'
          })}
          error={errors.email?.message}
        />
        <div className="flex flex-col sm:flex-row justify-between items-center space-y-4 sm:space-y-0">
          <Link to="/" className="text-sm text-deepcerulan-600 hover:text-deepcerulan-500 underline">
            Back to Login
          </Link>
        </div>
        <div className="mt-2">
          <Button type="submit" variant="primary" size="medium">
            Submit
          </Button>
        </div>
      </form>
    </Container>
  );
};

export default ForgotPasswordForm;
