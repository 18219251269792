import { ApiResponseTemplate, Template, TemplateData } from "../../types/Template";
import { baseUrlDevice } from "../../utils/env/env.config";
import { http } from "../../utils/helper/http";

interface AddTemplateAction {
  type: 'ADD_TEMPLATE'
  payload: { template: Template; totalItems: number }
}

interface SetTemplateAction {
  type: 'SET_TEMPLATE'
  payload: TemplateData
}



interface UpdateTemplateAction {
  type: 'UPDATE_TEMPLATE'
  payload: { id: string; template: Template }
}

interface SetLoadingAction {
  type: 'SET_LOADING';
  payload: boolean;
}


export type TemplateAction = AddTemplateAction | SetTemplateAction | UpdateTemplateAction | SetLoadingAction

export async function setTemplate(
  dispatch: React.Dispatch<TemplateAction>,
  payload: { page: number; limit: number,search?: string},
) {
  try {
    dispatch({ type: 'SET_LOADING', payload: true });
    const { limit, page,search } = payload
    let queryParams = `?page=${page}&limit=${limit}`
    if (search !== undefined && search !== '') {
      queryParams += `&search=${search}`;
    }
    const response = (await http(
      `${baseUrlDevice}template${queryParams}`,
    )) as ApiResponseTemplate
    const { limit: itemPerPage } = response
    const { data } = response
    const dataWithLimit = { ...data, itemPerPage }
    dispatch({
      type: 'SET_TEMPLATE',
      payload: dataWithLimit,
    })
  } catch (err) {
    console.error(err)
  } finally {
    dispatch({ type: 'SET_LOADING', payload: false });
  }
}

export function addNewTemplate(
  dispatch: React.Dispatch<TemplateAction>,
  payload: { template: Template; totalItems: number },
) {
  dispatch({
    type: 'ADD_TEMPLATE',
    payload,
  })
}



export function updateTemplate(
  dispatch: React.Dispatch<TemplateAction>,
  payload: { id: string; template: Template },
) {
  dispatch({
    type: 'UPDATE_TEMPLATE',
    payload,
  })
}
