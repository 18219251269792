import axios, { AxiosError, AxiosInstance, InternalAxiosRequestConfig } from 'axios';

// Define the type for your HTTP request and error handlers
type RequestHandler = (request: InternalAxiosRequestConfig) => InternalAxiosRequestConfig;
type ErrorHandler = (error: AxiosError) => Promise<never>;

interface HandleHttpProps {
  baseUrl: string;
  token: string;
}

const HandleHttp = ({ baseUrl, token }: HandleHttpProps): AxiosInstance => {
  const http = axios.create({ baseURL: baseUrl, timeout: 300000 });

  const requestHandler: RequestHandler = (request) => {
    request.headers = request.headers || {};
    request.headers.Authorization = `Bearer ${token}`;
    return request;
  };

  const errorResponseHandler: ErrorHandler = (error) => {
    return Promise.reject(error);
  };

  const errorHandler: ErrorHandler = (error) => {
    return Promise.reject(error);
  };

  http.interceptors.request.use(
    requestHandler,
    errorHandler
  );

  http.interceptors.response.use(
    (response) => response,
    errorResponseHandler
  );

  return http;
};

export default HandleHttp;
