import React, {
  createContext,
  useReducer,
  ReactNode,
  Dispatch,
  useContext,
} from 'react'
import TagReducer, { TagState } from './TagReducer'
import { TagAction } from './TagAction'

interface TagContextProps {
  state: TagState
  dispatch: Dispatch<TagAction>
}

const initialState: TagState = {
  tag: {
    limit: 0,
    tag: [],
    currentPage: 0,
    totalPages: 0,
    previousPage: 0,
    totalItems: 0,
  },
  loading: false
}

export const TagContext = createContext<TagContextProps>({
  state: initialState,
  dispatch: () => {},
})

interface TagProviderProps {
  children: ReactNode
}

export const TagProvider: React.FC<TagProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(TagReducer, initialState)

  // useEffect(() => {
  //   setDevice(dispatch, {page: 1, limit: 10}) // Call setOrganization only once when the component mounts
  // }, []) // Empty dependency array ensures it only runs on mount, not on updates

  return (
    <TagContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </TagContext.Provider>
  )
}

export const useTagState = () => {
  const { state, dispatch } = useContext(TagContext)
  return { ...state, dispatch }
}
