import React from 'react';

interface CardProps {
  heading: string;
  value: string | number;
  bg_color?: string;
  onClick?: () => void;
  icon?: React.ReactNode;
  img?: string;
  iconBgClass?: string;
}

const Card: React.FC<CardProps> = ({ heading, value, bg_color, onClick, icon, img, iconBgClass }) => {
  return (
    <div
      className="relative rounded-lg  border-stroke p-3 border cursor-pointer"
      style={{ backgroundColor: bg_color || 'white' }}
      onClick={onClick}
    >
      <div className="flex items-center">
        <p className="font-medium flex-1" style={{ color: "#202224" }}>
          {heading}
        </p>
        <div className={`flex items-center justify-center w-12 h-12 ${iconBgClass} rounded-2xl`}>
          {icon && (
            <div className="">
              {icon}
            </div>
          )}
          {img && (
            <img src={img} alt="" className="w-8 h-8 object-cover" /> // Adjust size as needed
          )}
        </div>
      </div>
      <div className="mt-4 flex items-end justify-between">
        <h4 className="text-title-md font-bold text-black">{value}</h4>
      </div>
      {/* <span className="flex items-center gap-1 text-sm font-medium text-green-400 mt-2">
        {data}
      </span> */}
    </div>
  );
};

export default Card;
