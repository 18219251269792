import { RouteAction } from "./RouteAction";

export interface RouteData {
    route: string;
}

export interface RouteState {
    route: RouteData
}


export default function RouteReducer(
    state: RouteState,
    action: RouteAction,
): RouteState {
    const { type, payload } = action;

    switch (type) {
        case 'SET_ROUTE':
            return {
                ...state,
                route: {
                    ...state.route,
                    route: payload.route,
                },
            };
        default:
            return state;
    }
}