import React, { useEffect, useRef, useState } from 'react'
import { useDeviceState } from '../../app-context/device-context/DeviceState'
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';

interface Device {
    latitude: string;
    longitude: string;
    status?: boolean;
    label: string;
  }
function GoogleMapComponent() {
    const { device } = useDeviceState()
    const [hoveredDevice, setHoveredDevice] = useState<Device | null>(null);
    const [hoveredDevices, setHoveredDevices] = useState<Device[]>([]);
    const [mapCenter, setMapCenter] = useState<{ lat: number, lng: number }>({
        lat: 0,
        lng: 0
    });
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: 'AIzaSyC16Rib7of6NTJlwxLP2myOcq-w9sI4-CA',
    });
    const mapRef = useRef<google.maps.Map | null>(null);

    const data = device.device.map(device => ({
        latitude: device.latitude,
        longitude: device.longitude,
        status: device.status ?? false,
        label: device.deviceAlias
    }));

    useEffect(() => {
        if (device.device.length > 0) {
            setMapCenter({
                lat: parseFloat(device.device[0].latitude),
                lng: parseFloat(device.device[0].longitude)
            });
        }
    }, [device]);
    

    if (!isLoaded) {
        return <div>Loading...</div>;
    }

    const redMarkerUrl = 'https://maps.google.com/mapfiles/ms/icons/red-dot.png';
    const greenMarkerUrl = 'https://maps.google.com/mapfiles/ms/icons/green-dot.png';

    return (
            <GoogleMap
                center={mapCenter}
                zoom={12}
                mapContainerStyle={{ width: '100%', height: '68vh', borderRadius: '15px', overflow: 'hidden' }}
                onLoad={(map: google.maps.Map) => { mapRef.current = map; }}
                options={{ draggable: true, disableDoubleClickZoom: true }}
            >
                {data.map((devices, index) => {
                    const position = {
                        lat: parseFloat(devices.latitude),
                        lng: parseFloat(devices.longitude),
                    };

                    const markerIcon = devices.status
                        ? { url: greenMarkerUrl, scaledSize: new window.google.maps.Size(33, 30) }
                        : { url: redMarkerUrl, scaledSize: new window.google.maps.Size(33, 30) };

                    return (
                        <Marker
                            key={index}
                            position={position}
                            icon={markerIcon}
                            onMouseOver={() => {
                                setHoveredDevice(devices);
                                setHoveredDevices(
                                    data.filter(
                                        (d) =>
                                            d.latitude === devices.latitude && d.longitude === devices.longitude
                                    )
                                );
                            }}
                            onMouseOut={() => {
                                setHoveredDevice(null);
                                setHoveredDevices([]);
                            }}
                            onClick={() => {
                                setMapCenter(position);
                            }}
                        >
                            {hoveredDevice === devices && (
                                <div
                                    style={{
                                        backgroundColor: 'white',
                                        padding: '10px',
                                        borderRadius: '10px',
                                        boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
                                        position: 'absolute',
                                        top: '150px',
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                        zIndex: 1,
                                    }}
                                >
                                    {hoveredDevices.map((d, i) => (
                                        <div key={i}>
                                            <p>
                                                <strong>Device Name:</strong> {d.label}
                                            </p>
                                            <p>
                                                <strong>Status:</strong>{' '}
                                                <span style={{ color: d.status ? 'green' : 'red' }}>
                                                    {d.status ? 'Active' : 'Inactive'}
                                                </span>
                                            </p>
                                            <hr />
                                        </div>
                                    ))}
                                </div>
                            )}
                        </Marker>
                    );
                })}
            </GoogleMap>
    )
}

export default GoogleMapComponent