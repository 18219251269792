import React, { useState } from 'react'
import Button from '../../components/button/Button'
import GenerateReport from './generate-reports/GenerateReport';
import ScheduleReportList from './schedule-report/ScheduleReportList';
// import { Outlet } from 'react-router-dom';

const Reports = () => {

  const [activeGeneralReport, setIsActiveGeneralReport] = useState(true);
  const [activeScheduleReport, setIsActiveScheduleReport] = useState(false);

  const handleGeneralReportClick = () => {
    setIsActiveGeneralReport(true);
    setIsActiveScheduleReport(false);
  };

  const handleScheduleReportClick = () => {
    setIsActiveGeneralReport(false);
    setIsActiveScheduleReport(true);
  };


  return (
    <>
      <div className='py-4 px-8'>
        <div className='switch-container flex'>
          <Button type='button' variant='outline' size='medium' width='200px' onClick={handleGeneralReportClick}>Generate Report</Button>
          <span className='p-4'></span>
          <Button type='button' variant='outline' size='medium' width='200px' onClick={handleScheduleReportClick}>Schedule Report List</Button>
        </div>
        <div className='py-8'>
          {activeGeneralReport && (
            <div className='generate-report-section'>
              <GenerateReport />
            </div>
          )}
          {activeScheduleReport && (
            <div className='schedule-report-section'><ScheduleReportList/></div>
          )}
        </div>
        {/* Nested routes for ReportFormats will be rendered here
        <Outlet /> */}
      </div>
    </>
  )
}

export default Reports