export interface RoleFormValues{
  dashBoardName: string
  name: string
  description: string

}



export interface Role {
  _id?: string
  dashBoardName: string
  name: string
  description: string
  permissions?:[]
  clientId?:string
  createdAt?: string
  updatedAt?: string
  __v?: number
}

export interface RoleData {
  roles: Role[]
}

export interface ApiResponseRole {
  page: number
  limit: number
  data: Role[]
}

export interface CreatePermissionResponse {
  data: Role
}








// roles.ts
export enum RoleBack {
  SUPERADMIN = 'ROLE_SUPER_ADMIN',
  ORGANIZATIONADMIN = 'ROLE_ORGANIZATION_ADMIN',
  SITEADMIN = 'ROLE_SITE',
  USER = 'ROLE_USER',
}


export interface ActionOption {
  action: string[];
  dropdownAction: string[];
}