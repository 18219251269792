import React, { useEffect, useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import '../sidebar.css';
import { useCurrentUserState } from '../../../app-context/current-user-context/CurrentUserLoginState';

interface LogoProps {
    logo: string;       // Default logo if imageUrl is not provided
    isSidebarOpen: boolean;
    toggleSidebar: () => void;
}

const Logo: React.FC<LogoProps> = ({ logo, isSidebarOpen, toggleSidebar }) => {
    const [imageUrl, setImageUrl] = useState('');
    const { currentUser } = useCurrentUserState();
    const [imageChange, setImageChange] = useState(false);

    const changeFavicon = (src: string) => {
        const link = document.getElementById('dynamic-favicon') as HTMLLinkElement;
        if (link) {
            link.href = src;
        } else {
            const newLink = document.createElement('link');
            newLink.id = 'dynamic-favicon';
            newLink.rel = 'icon';
            newLink.type = 'image/jpeg';
            newLink.href = src;
            document.head.appendChild(newLink);
        }
    };

    const fetchAndChangeFavicon = async (appName: string, logo: string) => {
        try {
            if (logo !== 'N/A' && logo !== "frinso") {
                // const url = logo;
                // const response = await fetch(url);
                // if (!response.ok) {
                //     throw new Error('Network response was not ok');
                // }
                // const blob = await response.blob();
                // const blobUrl = URL.createObjectURL(blob);
                // changeFavicon(url);
                changeFavicon(logo);
            }
            document.title = (appName === 'N/A' || appName === "Frinso") ? 'Frinso' : appName;
            setImageChange(true);
        } catch (error) {
            console.error('Error fetching the favicon:', error);
        }
    };

    const fetchImage = async () => {
        try {
            if (currentUser.appLogo && currentUser.appLogo.startsWith('http')) {
                // const response = await axios.get(baseUrlTenantImage + currentUser.appLogo);
                setImageUrl(currentUser.appLogo || '');
            }
        } catch (error) {
            console.error('Error fetching image:', error);
        }
    };

    useEffect(() => {
        if (currentUser.appLogo && !imageChange) {
            fetchAndChangeFavicon(currentUser.appName, currentUser.appLogo);
            fetchImage();
        }
    }, [currentUser.appLogo, currentUser.appName, imageChange]);


    return (
        <div className='logo-details'>
            <i className='bx icon'>
                <div className='rounded-full'>
                    <img
                        src={imageUrl || logo}
                        alt='Logo'
                        className='w-[7.7rem] h-11 mt-2 ml-1'
                    />
                </div>
            </i>
            <i
                className={`bx ${isSidebarOpen ? 'open' : ''}`}
                id='btn'
                onClick={toggleSidebar}
            >
                <MenuIcon />
            </i>
        </div>
    );
};

export default React.memo(Logo, (prevProps, nextProps) => {
    return (
        prevProps.logo === nextProps.logo &&
        prevProps.isSidebarOpen === nextProps.isSidebarOpen &&
        prevProps.toggleSidebar === nextProps.toggleSidebar
    );
});
