import React from 'react'

const StatisticsSummary = () => {
  return (
    <div>
      Statistics Summary
    </div>
  )
}

export default StatisticsSummary
