import styles from './navbar.module.css'
import Profile from './components/Profile'
import { useRouteState } from '../../app-context/route/RouteState'

interface NavbarProps {
  isSidebarOpen: boolean,
  width: number
}

const Navbar: React.FC<NavbarProps> = ({ isSidebarOpen, width }) => {
  const { route } = useRouteState()
  


  return (
    <>
      <header
        className={`sticky top-0 z-40 h-14 flex w-full bg-white drop-shadow-1 shadow-lg ${isSidebarOpen ? styles.ml_sidebar : ''}`}
      >
        <div className='flex flex-grow items-center justify-between py-4 px-4 shadow-2 md:px-6 2xl:px-11'>
          <div className='flex items-center gap-3 2xsm:gap-7 z-20'>
            {/* Other elements here */}
            {width > 500 &&
              <p style={{ marginLeft: isSidebarOpen ? "0px" : "60px", color:"#0778AA" }} className='font-bold'>
                {route?.route || "Overview" }
              </p>

            }
          </div>

          <ul
            className={`flex items-center gap-2 2xsm:gap-4 transition ${isSidebarOpen ? 'fixed right-10 transition-all duration-300' : 'mr-5 transition-all duration-300'}`}
          >
            {/* <li>
              <NotificationsIcon />
            </li> */}
            <li>
              <div className='flex items-center justify-center'>
                <Profile />
              </div>
            </li>
          </ul>
        </div>
      </header>
    </>
  )
}

export default Navbar
