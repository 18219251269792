import Countdown from './Countdown';  // Import the Countdown component

const ErrorFallback = () => {
    return (
        <div className="min-h-screen bg-gray-100 flex flex-col justify-center items-center">
            <div className="max-w-md w-full bg-white shadow-md rounded-lg p-8">
                <h1 className="text-3xl font-bold mb-4">Oops! Something went wrong.</h1>
                <p className="text-gray-600 mb-4">
                    An unexpected error has occurred. You will be logged out in:
                    <Countdown /> {/* Include the Countdown component */}
                </p>
                <button
                    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    onClick={() => window.location.reload()} // Refresh page on button click
                >
                    Refresh Page
                </button>
            </div>
        </div>
    );
};

export default ErrorFallback;
