import React, { useState } from 'react';
import CommonTable from '../../../components/table/Table';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Column, DataRow } from '../../../types/table';
import { Switch } from '@mui/material';


interface ReportData {
  reportType: string;
  reportFormat: string;
  periodicityTime: number;
  receivedTime: string;
  email: string;
  status: boolean;
}


const ScheduleReportList: React.FC = () => {
  const initialData: ReportData[] = [
    {
      reportType: "Monthly Report",
      reportFormat: "PDF",
      periodicityTime: 30,
      receivedTime: "2024-10-11T10:00:00Z",
      email: "example@example.com",
      status: true,
    },
    {
      reportType: "Weekly Report",
      reportFormat: "Excel",
      periodicityTime: 60,
      receivedTime: "2024-10-10T09:30:00Z",
      email: "sample@domain.com",
      status: false
    },
    {
      reportType: "Daily Report",
      reportFormat: "PDF",
      periodicityTime: 15,
      receivedTime: "2024-10-11T12:00:00Z",
      email: "test@domain.com",
      status: true
    }
  ];

  const [data, setData] = useState<ReportData[]>(initialData);


  const handleEdit = (row: ReportData) => {

    console.log("Editing row:", row);
  };


  const handleDeleteModal = (index: number) => {

    console.log("Deleting row with index:", index);
  };


  const handleStatusChange = (index: number) => {
    const newData = data.map((item, i) =>
      i === index ? { ...item, status: !item.status } : item
    );
    setData(newData);
  };


  const dataRow: DataRow[] = data.map((row, index) => ({
    ...row,
    actions: (
      <span style={{ border: "0.2px solid #D5D5D5", padding: "3px" }} className="rounded-md cursor-pointer">
        <EditIcon onClick={() => handleEdit(row)} style={{ marginRight: '5px' }} />
        <DeleteOutlineOutlinedIcon style={{ color: "red" }} onClick={() => handleDeleteModal(index)} />
      </span>
    ),
    status: (
      <Switch
        checked={row.status}
        onChange={() => handleStatusChange(index)}
        color="primary"
      />
    ),
  }));

  const columns: Column[] = [
    { id: 'reportType', label: 'Report Type' },
    { id: 'reportFormat', label: 'Report Format' },
    { id: 'periodicityTime', label: 'Periodicity Time (min)' },
    { id: 'receivedTime', label: 'Received Time' },
    { id: 'email', label: 'Email' },
    { id: 'status', label: 'Status' },
    { id: 'actions', label: 'Actions' }
  ];

  return (
    <div>
      <CommonTable
        columns={columns}
        data={dataRow}
        title="Schedule Report List"
        pagination={{
          rowsPerPageOptions: [5, 10, 25],
          rowsPerPage: 5,
          page: 1,
          totalItems: dataRow.length,
          handlePageChange: (newPage: number) => console.log(newPage),
          handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => console.log(event.target.value)
        }}
        setHeight="400px"
      />
    </div>
  );
};

export default ScheduleReportList;
