import React, { useEffect, useRef, useState } from 'react'
import { Device } from '../../../types/Device';
import Loader from '../../../components/loader/Loader';
import { http } from '../../../utils/helper/http';
import { baseUrlDevice } from '../../../utils/env/env.config';
import MobileTowerIcon from '../../../components/mobile-tower/MobileTowerIcon';
import SensorCard from '../../../components/card/SensorCard';
import { SensorInfo } from '../../../workers/device-data-worker/dataWorker';
import DualAxisLineChart from '../../../components/chart/line-chart/DualAxisLineChart';

interface DashboardTempHumDoubleProps {
    deviceState: Device;
}

interface SensorData {
    [key: string]: string | number;
}

interface ResponseData {
    deviceData: SensorData[];
}

const DashboardTempHumDouble: React.FC<DashboardTempHumDoubleProps> = ({
    deviceState,
}) => {
    const [data, setData] = useState<SensorData[]>([]);
    const [dashBoardSts, setDashboardSts] = useState<SensorData>();
    const [sensorInfo, setSensorInfo] = useState<SensorInfo>();
    const workerRefSensor = useRef<Worker | null>(null);
    const workerRefLineChart = useRef<Worker | null>(null);
    const [loading, setLoading] = useState(false);
    const [tempHumSingleLineChart, setTempHumSingleLineChart] = useState<{
        seriesData: {
            name: string;
            data: number[];
            color: string;
            yAxis: number[];
        }[];
        categories: string[];
    }>({
        categories: [],
        seriesData: [],
    });


    const fetchData = async () => {
        try {
            setLoading(true);
            const response = await http(
                `${baseUrlDevice}devices-data/influxData/last24hour?deviceId=${deviceState._id}`
            );
            const dataAsSensor = response as ResponseData;
            setData(dataAsSensor.deviceData);
            setDashboardSts(dataAsSensor.deviceData[0]);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        } finally {
            setLoading(false);

        }
    };

    useEffect(() => {
        workerRefSensor.current = new Worker(
            new URL('../../../workers/sensor-worker/sensorWorker.ts', import.meta.url)
        );
        workerRefSensor.current.onmessage = function (e) {
            const { sensorInfo } = e.data;
            setSensorInfo(sensorInfo);

        };

        workerRefLineChart.current = new Worker(
            new URL('../../../workers/chart-worker/chartWorker.ts', import.meta.url)
        );
        workerRefLineChart.current.onmessage = function (e) {
            const { seriesData, categories } = e.data;
            setTempHumSingleLineChart({ seriesData, categories })
        };
    }, []);

    useEffect(() => {
        if (workerRefLineChart.current && data.length > 0) {
            const names: string[] = [];
            const keyNames: string[] = [];
            const colors = ['117DAE', 'DD0000'];
            const yAxis: number[] = [];

            if (sensorInfo?.Temperature.status) {
                names.push(sensorInfo.Temperature.displaySensorName);
                keyNames.push('Temperature');
                yAxis.push(0);
            }
            if (sensorInfo?.Humidity.status) {
                names.push(sensorInfo.Humidity.displaySensorName);
                keyNames.push('Humidity');
                yAxis.push(1);
            }

            workerRefLineChart.current.postMessage({
                data: data,
                names,
                keyNames,
                colors,
                yAxis,
            })
        }
    }, [data])


    useEffect(() => {
        if (deviceState.tags && workerRefSensor.current) {
            workerRefSensor.current.postMessage({ tags: deviceState.tags });
        }
        fetchData();
    }, [deviceState]);
    return (
        <>
            {loading && <Loader showGradient={loading} />}
            <div className="p-4 space-y-2">
                <div className="bg-white min-h-10 w-full md:w-[60%]  p-1 rounded-lg shadow-md">
                    <div className="flex flex-col md:flex-row items-center w-full mt-1">
                        <div className="text-deepcerulan-600 mb-0 md:mb-0 md:w-[45%]">
                            <span className="font-bold">Device ID:</span>{' '}
                            {deviceState.imeiNumber}
                        </div>
                        <div className="flex items-center md:ml-0 md:w-1/2">
                            <MobileTowerIcon levels={parseInt(dashBoardSts?.rssi as string)} time={dashBoardSts?.timeStamp as string || null} />
                            <p className="text-sm text-gray-500 ml-0 whitespace-nowrap">
                                Last data received at: {dashBoardSts?.timeStamp as string}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="p-2 bg-white w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 md:grid-cols-3  gap-4">
                    {sensorInfo?.['Temperature']?.status && (
                        <SensorCard
                            sensor={sensorInfo.Temperature.status ? sensorInfo.Temperature.displaySensorName : "Temperature"}
                            value={dashBoardSts ? dashBoardSts.Temperature : "N/A"}
                            unit={sensorInfo.Temperature.status ? sensorInfo.Temperature.displaySensorUnit : "°C"}
                        />
                    )}
                    {sensorInfo?.['Humidity']?.status && (
                        <SensorCard
                            sensor={sensorInfo.Humidity.status ? sensorInfo.Humidity.displaySensorName : "Humidity"}
                            value={dashBoardSts ? dashBoardSts.Humidity : "N/A"}
                            unit={sensorInfo.Humidity.status ? sensorInfo.Humidity.displaySensorUnit : "%"}
                        />
                    )}

                </div>

                <div className='bg-white p-4'>
                    <div className="relative">
                        <div className="absolute top-0 right-0">
                            {/* <LongMenu
              options={["Download as CSV", "Download as PDF"]}
              onOptionClick={handleExportBarChart}
              id={1}
            /> */}
                        </div>
                    </div>
                    <p>
                        Temperature and Humidity data variation with time (between{" "}
                        {data.length > 0
                            ? data[data.length - 1].timeStamp +
                            " to " +
                            data[0].timeStamp
                            : " "}
                        )
                    </p>
                    <DualAxisLineChart
                        categories={tempHumSingleLineChart.categories}
                        series={tempHumSingleLineChart.seriesData}
                        yAxisTitlePrimary="Temperature"
                        yAxisTitleSecondary="Humidity"
                        xAxisTitle="Time"
                        height={450}
                    />
                </div>
            </div>

        </>
    )
}

export default DashboardTempHumDouble