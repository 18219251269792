import { User, UserData } from '../../types/User'
import { UserAction } from './UserAction'

export interface UserState {
  user: UserData,
  loading: boolean
}

export default function UserReducer(
  state: UserState,
  action: UserAction,
): UserState {
  const { type, payload } = action

  switch (type) {
    case 'SET_LOADING':
      return {
        ...state,
        loading: payload,
      };
    case 'SET_USER': {
      return { ...state, user: payload,  loading: false  }
    }
    case 'ADD_USER': {
      const { user: newUser, totalItems } = payload
      return {
        ...state,
        user: {
          ...state.user,
          user: [newUser, ...state.user.user],
          totalItems: totalItems + 1,
        },
      }
    
    }
    case 'UPDATE_USER': {
      const { id: updateId, user } = payload
      const updatedUser = state.user.user.map((u) =>
        u._id === updateId ? { ...u, ...user } : u,
      )
      return {
        ...state,
        user: {
          ...state.user,
          user: updatedUser,
        },
      }
    }
    case "UPDATE_USER_STATUS": {
      const { id, status } = payload;
      const alertToUpdate = state.user.user.find((user: User) => user._id === id);
      if (alertToUpdate) {
        alertToUpdate.status = status;
        const updatedAlertAfterStatusUpdate = [...state.user.user];
        return {
          ...state,
          user: {
            ...state.user,
            user: updatedAlertAfterStatusUpdate,
          },
        };
      }
      return state;
    }
    default: {
      return state
    }
  }
}
