import React, {
  createContext,
  useReducer,
  ReactNode,
  Dispatch,
  useContext,
} from 'react'
import VendorReducer, { SiteState } from './VendorReducer'
import { VendorAction } from './VendorAction'

interface SiteContextProps {
  state: SiteState
  dispatch: Dispatch<VendorAction>
}

const initialState: SiteState = {
  vendor: {
    limit: 0,
    vendor: [],
    currentPage: 0,
    totalPages: 0,
    previousPage: 0,
    totalItems: 0,
  },
  loading: false
}

export const SiteContext = createContext<SiteContextProps>({
  state: initialState,
  dispatch: () => { },
})

interface SiteProviderProps {
  children: ReactNode
}

export const SiteProvider: React.FC<SiteProviderProps> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(VendorReducer, initialState)

  // useEffect(() => {
  //   setSite(dispatch, { page: 1, limit: 10 }) // Call setOrganization only once when the component mounts
  // }, []) // Empty dependency array ensures it only runs on mount, not on updates

  return (
    <SiteContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </SiteContext.Provider>
  )
}

export const useSiteState = () => {
  const { state, dispatch } = useContext(SiteContext)
  return { ...state, dispatch }
}
