import {  jwtDecode } from "jwt-decode"
import { CurrentUser } from "../../types/CurrentLoginUser"



export function decodeToken(token: string): CurrentUser {
  const decodedToken = jwtDecode<CurrentUser>(token)

  const user: CurrentUser = {
    _id: decodedToken._id,
    name: decodedToken.name,
    email: decodedToken.email,
    roleId: decodedToken.roleId,
    organizationId: decodedToken.organizationId,
    clientId: decodedToken.clientId,
    tenantId: decodedToken.tenantId,
    vendorId: decodedToken.vendorId,
    status: decodedToken.status,
    role: decodedToken.role,
    permissions: decodedToken.permissions,
    appLogo: decodedToken.appLogo,
    appName: decodedToken.appName
  }

  return user
}
