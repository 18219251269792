import { CurrentUser } from '../../types/CurrentLoginUser'
import { decodeToken } from '../../utils/decode-token/TokenDecode'
interface SetCurrentUserAction {
  type: 'SET_CURRENT_USERS'
  payload: CurrentUser
}

export type CurrentUserAction = SetCurrentUserAction

export const login = ({
  // dispatch,
  token,
}: {
  dispatch: React.Dispatch<CurrentUserAction>
  token: string
}) => {
  window.localStorage.setItem('token', token)
  // const decodedData = decodeToken(token)
  const currentUrl = window.location.href;
  if(currentUrl === 'https://awtest.frinso.io/'){
    window.location.href = '/home/ac_dashboard'
  }else{
    window.location.href = '/home/overview'
  }
  
}

export const logout = () => {
  window.localStorage.removeItem('token')
  // window.location.href = '/#'
  window.location.href = '/'

}

export async function setCurrentUserLogin(
  dispatch: React.Dispatch<CurrentUserAction>,
) {
  try {
    
    const token = window.localStorage.getItem('token')
    if (!token) {
      window.localStorage.removeItem('token')
      logout()
    }
    const data: CurrentUser = decodeToken(token || '')
    
    dispatch({
      type: 'SET_CURRENT_USERS',
      payload: data,
    })
  } catch (err) {
    window.localStorage.removeItem('token')
    logout()
  }
}

