import { OrganizationData } from '../../types/organization';
import { OrganizationAction } from './OrganizationAction';

export interface OrganizationState {
  organizations: OrganizationData;
  loading: boolean;
}

export default function OrganizationReducer(
  state: OrganizationState,
  action: OrganizationAction,
): OrganizationState {
  const { type, payload } = action;

  switch (type) {
    case 'SET_LOADING':
      return {
        ...state,
        loading: payload,
      };
    case 'SET_ORGANIZATIONS':
      return { ...state, organizations: payload, loading: false };

    case 'SET_ORGANIZATION_WITH_PAGE':
      return { ...state, organizations: payload };

    case 'ADD_ORGANIZATION': {
      const { organization: newOrganization, totalItems } = payload;
      let organizat;

      if (
        state.organizations.organizations.length === 10 ||
        state.organizations.organizations.length === 25 ||
        state.organizations.organizations.length === 5
      ) {
        organizat = state.organizations.organizations.slice(0, -1);
      } else {
        organizat = state.organizations.organizations;
      }

      return {
        ...state,
        organizations: {
          ...state.organizations,
          organizations: [newOrganization, ...organizat],
          totalItems: totalItems + 1,
        },
      };
    }

    case 'UPDATE_ORGANIZATION': {
      const { id: updateId, organization } = payload;
      const updatedCustomer = state.organizations.organizations.map((org) =>
        org._id === updateId ? { ...org, ...organization } : org,
      );

      return {
        ...state,
        organizations: {
          ...state.organizations,
          organizations: updatedCustomer,
        },
      };
    }

    default:
      return state;
  }
}
