import React, {
  createContext,
  useReducer,
  ReactNode,
  Dispatch,
  useContext,
} from 'react'
import TemplateReducer, { TemplateState } from './TemplateReducer'
import { TemplateAction } from './TemplateAction'

interface TemplateContextProps {
  state: TemplateState
  dispatch: Dispatch<TemplateAction>
}

const initialState: TemplateState = {
  template: {
    limit: 0,
    template: [],
    currentPage: 0,
    totalPages: 0,
    previousPage: 0,
    totalItems: 0,
  },
  loading: false,
}

export const TemplateContext = createContext<TemplateContextProps>({
  state: initialState,
  dispatch: () => {},
})

interface TemplateProviderProps {
  children: ReactNode
}

export const TemplateProvider: React.FC<TemplateProviderProps> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(TemplateReducer, initialState)

  // useEffect(() => {
  //   setUser(dispatch, { page: 1, limit: 10 }) // Call setOrganization only once when the component mounts
  // }, []) // Empty dependency array ensures it only runs on mount, not on updates

  return (
    <TemplateContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </TemplateContext.Provider>
  )
}

export const useTemplateState = () => {
  const { state, dispatch } = useContext(TemplateContext)
  return { ...state, dispatch }
}
