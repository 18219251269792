import React, {
    createContext,
    useReducer,
    ReactNode,
    Dispatch,
    useContext,
    useEffect,
} from 'react'
import OverviewReducer, { OverviewState } from './OverviewReducer'
import { OverviewAction } from './OverviewAction'


interface OverviewContextProps {
    state: OverviewState
    dispatch: Dispatch<OverviewAction>
}

const initialState: OverviewState = {
    superadminoverview: {
        organizationsCount: 0,
        devicesCount: 0,
        onlineDevicesCount: 0,
        offlineDevicesCount: 0,
        subscriptionStat:{
            seriesData: [],
            categories: []
        },
        // seriesData:[],
        // categories: [],
        ticketStat:{
            seriesData: [],
            categories: []
          }
    },
    orgAdminOverview:{
        userCount: 0,
        devicesCount: 0,
        onlineDevicesCount: 0,
        offlineDevicesCount: 0,
        subscriptionStat:{
            seriesData: [],
            categories: []
        },
        ticketStat:{
            seriesData: [],
            categories: []
          }
    },
    loading: false,
    loaded: false
}

export const OverviewContext = createContext<OverviewContextProps>({
    state: initialState,
    dispatch: () => { },
})

interface OverviewProviderProps {
    children: ReactNode
}

export const OverviewProvider: React.FC<OverviewProviderProps> = ({ children }) => {
    const [state, dispatch] = useReducer(OverviewReducer, initialState)

    useEffect(() => {
        // setDevice(dispatch, {page: 1, limit: 10}) // Call setOrganization only once when the component mounts
    }, []) // Empty dependency array ensures it only runs on mount, not on updates

    return (
        <OverviewContext.Provider
            value={{
                state,
                dispatch,
            }}
        >
            {children}
        </OverviewContext.Provider>
    )
}

export const useOverviewState = () => {
    
    const { state, dispatch } = useContext(OverviewContext)
    return { ...state, dispatch }
}
