import { ApiResponseTag, Tag, TagData } from "../../types/Tag";
import { baseUrlDevice } from "../../utils/env/env.config";
import { http } from "../../utils/helper/http";


interface AddTagAction {
  type: 'ADD_TAG'
  payload: { tag: Tag; totalItems: number }
}

interface SetTagAction {
  type: 'SET_TAG'
  payload: TagData
}



interface UpdateTagAction {
  type: 'UPDATE_TAG'
  payload: { id: string; tag: Tag }
}

interface SetLoadingAction {
  type: 'SET_LOADING';
  payload: boolean;
}


export type TagAction = AddTagAction | SetTagAction | UpdateTagAction | SetLoadingAction

export async function setTag(
  dispatch: React.Dispatch<TagAction>,
  payload: {
    page: number
    limit: number
    search?: string
    organizationId: string,
  },
) {
  try {
    dispatch({ type: 'SET_LOADING', payload: true });
    const { limit, page,search } = payload
    let queryParams = `?page=${page}&limit=${limit}`
    if (search !== undefined && search !== '') {
      queryParams += `&search=${search}`;
    }
    const response = (await http(`${baseUrlDevice}tags${queryParams}`)) as ApiResponseTag
    const { limit: itemPerPage } = response
    const { data } = response
    const dataWithLimit = { ...data, itemPerPage }
    dispatch({
      type: 'SET_TAG',
      payload: dataWithLimit,
    })
  } catch (err) {
    console.error(err)
  } finally {
    dispatch({ type: 'SET_LOADING', payload: false });
  }
}



export function addNewTag(
  dispatch: React.Dispatch<TagAction>,
  payload: { tag: Tag; totalItems: number },
) {
  dispatch({
    type: 'ADD_TAG',
    payload,
  })
}

export function updateTag(
  dispatch: React.Dispatch<TagAction>,
  payload: { id: string; tag: Tag },
) {
  dispatch({
    type: 'UPDATE_TAG',
    payload,
  })
}
