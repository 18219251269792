
import {  Scheduling, SchedulingData } from "../../types/Scheduling";
import { baseUrlScheduling } from "../../utils/env/env.config";
import { http } from "../../utils/helper/http";


interface AddSchedulingAction {
  type: 'ADD_SCHEDULING';
  payload: { scheduling: Scheduling; totalItems: number }
}

interface SetSchedlingAction {
    type: 'SET_SCHEDULING'
    payload: SchedulingData
}

interface SetLoadingAction {
  type: 'SET_LOADING';
  payload: boolean;
}

interface UpdateScheduleAction {
  type: 'UPDATE_SCHEDULE';
  payload: { id: string; scheduling: Scheduling }
}

export type SchedulingAction =  SetLoadingAction | SetSchedlingAction | AddSchedulingAction | UpdateScheduleAction
export async function setScheduling(
  dispatch: React.Dispatch<SchedulingAction>,
  payload: {
    page: number
    limit: number
    search?: string
    organizationId: string,
  },
) {
  try {
    dispatch({ type: 'SET_LOADING', payload: true });
    const { limit, page,search } = payload
    let queryParams = `?page=${page}&limit=${limit}`
    if (search !== undefined && search !== '') {
      queryParams += `&search=${search}`;
    }
    const response = (await http(`${baseUrlScheduling}scheduling${queryParams}`)) as SchedulingData    
   
    dispatch({
      type: 'SET_SCHEDULING',
      payload: response,
    })

  } catch (err) {
    console.error(err)
  } finally {
    dispatch({ type: 'SET_LOADING', payload: false });
  }
}

export function addScheduling(
  dispatch: React.Dispatch<SchedulingAction>,
  payload: {scheduling : Scheduling; totalItems: number}
){
  dispatch({
    type: 'ADD_SCHEDULING',
    payload,
  })
}

export function updateSchedule(
  dispatch: React.Dispatch<SchedulingAction>,
  payload: { id: string; scheduling: Scheduling }
){
  dispatch({
    type: 'UPDATE_SCHEDULE',
    payload,
  })
}
