// // Container.tsx
// import React, { useEffect } from 'react';
// import backgroundImage from '../../assets/images/login_backgroud.png'; // Import the background image
// import defaultLogo from '../../assets/images/frinsologoblue.svg'; // Import the logo
// import defaultIco from '../../assets/images/favicon.ico'; // Import the logo


// interface ContainerProps {
//     children: React.ReactNode;
//     heading: string; // Custom heading for the container (optional)
// }

// const Container: React.FC<ContainerProps> = ({ children, heading }) => {

//     const currentUrl = window.location.href;
//     const isAwTestEndpoint = currentUrl === 'https://awtest.frinso.io/';
    
//     const containerStyle = {
//         backgroundImage: `url(${!isAwTestEndpoint ? 'https://frinso.s3.amazonaws.com/bgimage/sp8swkm80c.png' : backgroundImage})`,
//         backgroundSize: 'cover', // Cover the entire container
//         backgroundPosition: 'center', // Center the image
//         backgroundRepeat: 'no-repeat', // Prevent repeating the image
//     };
//     const logo = !isAwTestEndpoint 
//         ? 'https://frinso.s3.amazonaws.com/applogos/ih4asc6vwn.png' // Replace with your alternate logo URL
//         : defaultLogo;


//         useEffect(() => {
//             // Dynamically change the favicon based on the endpoint
//             const favicon = document.querySelector("link[rel='icon']");
//             if (favicon) {
//                 favicon.setAttribute(
//                     'href', 
//                     !isAwTestEndpoint 
//                         ? 'https://frinso.s3.amazonaws.com/favicon/nkfzp3ro92g.ico' // Alternate favicon URL
//                         : defaultIco // Default favicon path
//                 );
//             }
//         }, [isAwTestEndpoint]);
//     return (
//         <div
//             className="flex items-center justify-center min-h-screen bg-cover bg-center p-4"
//             style={containerStyle}
//         >
//             <div className="flex flex-col items-center w-full max-w-md p-6 bg-white rounded-lg shadow-lg space-y-6">
//                 <img src={logo} alt="Logo" className="justify-start mb-2 w-32 sm:w-36" />
//                 <h1 className="text-xl  sm:text-2xl font-bold mb-4 sm:mb-6">{heading}</h1>
//                 {children}
//             </div>
//         </div>
//     );
// };

// export default Container;
import React, { useEffect } from 'react';
import backgroundImage from '../../assets/images/login_backgroud.png'; // Default background image
import defaultLogo from '../../assets/images/frinsologoblue.svg'; // Default logo
import defaultIco from '../../assets/images/favicon.ico'; // Default favicon

interface ContainerProps {
    children: React.ReactNode;
    heading: string;
}

// Configuration object mapping each endpoint to its specific assets
const endpointConfig: Record<string, { background: string; logo: string; favicon: string }> = {
    'https://awtest.frinso.io/': {
        background: 'https://frinso.s3.amazonaws.com/bgimage/sp8swkm80c.png',
        logo: 'https://frinso.s3.amazonaws.com/applogos/ih4asc6vwn.png',
        favicon: 'https://frinso.s3.amazonaws.com/favicon/nkfzp3ro92g.ico',
    },
    'https://anotherendpoint.frinso.io/': {
        background: 'https://frinso.s3.amazonaws.com/bgimage/another-bg.png',
        logo: 'https://frinso.s3.amazonaws.com/applogos/another-logo.png',
        favicon: 'https://frinso.s3.amazonaws.com/favicon/another-favicon.ico',
    },
    // Add more endpoints as needed
};

const Container: React.FC<ContainerProps> = ({ children, heading }) => {
    const currentUrl = window.location.href;
    const config = endpointConfig[currentUrl] || {
        background: backgroundImage,
        logo: defaultLogo,
        favicon: defaultIco,
    };

    const containerStyle = {
        backgroundImage: `url(${config.background})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    };

    useEffect(() => {
        // Dynamically set the favicon based on the endpoint
        const favicon = document.querySelector("link[rel='icon']");
        if (favicon) {
            favicon.setAttribute('href', config.favicon);
        }
    }, [config.favicon]);

    return (
        <div
            className="flex items-center justify-center min-h-screen bg-cover bg-center p-4"
            style={containerStyle}
        >
            <div className="flex flex-col items-center w-full max-w-md p-6 bg-white rounded-lg shadow-lg space-y-6">
                <img src={config.logo} alt="Logo" className="justify-start mb-2 w-32 sm:w-36" />
                <h1 className="text-xl sm:text-2xl font-bold mb-4 sm:mb-6">{heading}</h1>
                {children}
            </div>
        </div>
    );
};

export default Container;
