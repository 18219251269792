import { DashboardData, OrgAdminDashboardData, TicketStats } from '../../types/overview';
import { RoleBack } from '../../types/Role';
import { DashboardDataSubscription } from '../../types/SeriesData';
import { baseUrlSubscription, baseUrlTenant, baseUrlTicketing } from '../../utils/env/env.config';
import { http } from '../../utils/helper/http';


interface SetOverviewActionSuperSubAdmin {
  type: 'SET_OVERVIEW_SUPERADMIN'
  payload: DashboardData
}

interface SetOverviewOrgAdmin {
  type: 'SET_OVERVIEW_ORGADMIN'
  payload: OrgAdminDashboardData
}

interface SetLoadingAction {
  type: 'SET_LOADING';
  payload: boolean;
}
export type OverviewAction =
  | SetOverviewActionSuperSubAdmin | SetLoadingAction | SetOverviewOrgAdmin


  export async function setOverviewSuperAdmin(
    dispatch: React.Dispatch<OverviewAction>,
    payload: {
      organizationId: string;
      vendorId: string;
      userId?: string;
      role: string;
    },
  ) {
    try {
      dispatch({ type: 'SET_LOADING', payload: true });
      const { role, organizationId } = payload;
  
      let query = null;
      if (role === RoleBack.ORGANIZATIONADMIN && organizationId) {
        query = `organizationId=${organizationId}`;
      }
  
      // Fetch the data for dashboardStats, dashboardStatsSub, and dashboardStats from their respective services
      const results = await Promise.allSettled([
        http(`${baseUrlTenant}dashboardStats?${query}`) as Promise<DashboardData>,
        http(`${baseUrlSubscription}dashboardStatsSub?${query}`) as Promise<DashboardDataSubscription>,
        http(`${baseUrlTicketing}dashboardStats?${query}`) as Promise<TicketStats>,
      ]);
  
      // Extract the results of the API calls
      const response1 = results[0];
      const response2 = results[1];
      const response3 = results[2];
  
      // Combine the payloads into one object
      const combinedPayload = {
        ...(response1.status === 'fulfilled' ? response1.value : {}),
        ...(response2.status === 'fulfilled' ? response2.value : {}),
        ...(response3.status === 'fulfilled' ? response3.value : {}),
      };
  
      // Now map the necessary data into the required structure for superadminoverview
      const superAdminOverview = {
        organizationsCount: combinedPayload.organizationsCount || 0,
        devicesCount: combinedPayload.devicesCount || 0,
        onlineDevicesCount: combinedPayload.onlineDevicesCount || 0,
        offlineDevicesCount: combinedPayload.offlineDevicesCount || 0,
        userCount: combinedPayload.userCount || 0,
  
        // Mapping the subscription statistics if available
        subscriptionStat: {
          seriesData: response2.status === 'fulfilled' ? response2.value.seriesData : [],
          categories: response2.status === 'fulfilled' ? response2.value.categories : [],
        },
  
        // Mapping the ticket statistics if available
        ticketStat: {
          seriesData: response3.status === 'fulfilled' ? response3.value.seriesData : [],
          categories: response3.status === 'fulfilled' ? response3.value.categories : [],
        },
      };
  
      // Dispatching the state based on the user's role
      if (role === RoleBack.ORGANIZATIONADMIN) {
        dispatch({
          type: 'SET_OVERVIEW_ORGADMIN',
          payload: superAdminOverview,
        });
      } else {
        if (Object.keys(superAdminOverview).length > 0) {
          dispatch({
            type: 'SET_OVERVIEW_SUPERADMIN',
            payload: superAdminOverview,
          });
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      dispatch({ type: 'SET_LOADING', payload: false });
    }
  }
  
  