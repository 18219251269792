import { RoleBack } from '../../types/Role';
import { ApiResponseUser, User, UserData } from '../../types/User';
import { baseUrlUser } from '../../utils/env/env.config';
import { http } from '../../utils/helper/http';

interface AddUserAction {
  type: 'ADD_USER'
  payload: { user: User; totalItems: number }
}

interface SetUserAction {
  type: 'SET_USER'
  payload: UserData
}

interface UpdateUserAction {
  type: 'UPDATE_USER'
  payload: { id: string; user: User }
}

interface UpdateUserStatusAction {
  type: "UPDATE_USER_STATUS";
  payload: { id: string, status: boolean };
}

interface SetLoadingAction {
  type: 'SET_LOADING';
  payload: boolean;
}



export type UserAction = AddUserAction | SetUserAction | UpdateUserAction | UpdateUserStatusAction | SetLoadingAction

export async function setUser(
  dispatch: React.Dispatch<UserAction>,
  payload: {
    page: number
    limit: number
    search?: string
    clientId: string
    organizationId: string
    vendorId: string
    role: string
  },
) {
  try {
    dispatch({ type: 'SET_LOADING', payload: true });
    const { limit, page, organizationId, vendorId, role, search, clientId } = payload
    let queryParams = `?page=${page}&limit=${limit}`
    if (role === RoleBack.ORGANIZATIONADMIN) {
      queryParams +=
        organizationId !== '' ? `&organizationId=${organizationId}` : ''
    }
    if (role === RoleBack.SITEADMIN) {
      queryParams += `&vendorId=${vendorId}&organizationId=${organizationId}`
    }
    if (search !== undefined && search !== '') {
      queryParams += `&search=${search}`;
    }

    
    const response = (await http(
      `${baseUrlUser + clientId + '/users'}` + queryParams,
    )) as ApiResponseUser

    const { data } = response


    dispatch({
      type: 'SET_USER',
      payload: data,
    })
  } catch (err) {
    console.error(err)
  } finally{
    dispatch({ type: 'SET_LOADING', payload: false });
  }
}

export function addNewUser(
  dispatch: React.Dispatch<UserAction>,
  payload: { user: User; totalItems: number },
) {
  dispatch({
    type: 'ADD_USER',
    payload,
  })
}

export function updateUser(
  dispatch: React.Dispatch<UpdateUserAction>,
  payload: { id: string; user: User },
) {
  dispatch({
    type: 'UPDATE_USER',
    payload,
  })
}

export function updateUserStatus(
  dispatch: React.Dispatch<UserAction>,
  payload:  { id: string, status: boolean}
) {
  dispatch({
    type: "UPDATE_USER_STATUS",
    payload,
  });
}