// import React from 'react';
// import Select, { Props as SelectProps, GroupBase } from 'react-select';
// import styles from './SearchableSelect.module.css';
// import { OptionType } from '../../types/Dropdown';

// interface SearchableSelectProps {
//   options: OptionType[];
//   value?: OptionType | null;
//   onChange?: (selectedOption: OptionType | null) => void;
//   placeholder?: string;
//   isDisabled?: boolean;
//   className?: string;
//   classNamePrefix?: string;
//   isLoading?: boolean;
//   selectProps?: Omit<SelectProps<OptionType, false, GroupBase<OptionType>>, 'options' | 'value' | 'onChange' | 'placeholder' | 'isDisabled' | 'className' | 'classNamePrefix' | 'isLoading'>;
// }

// const SearchableSelect: React.FC<SearchableSelectProps> = ({
//   options,
//   value,
//   onChange,
//   placeholder = 'Select...',
//   isDisabled = false,
//   className,
//   classNamePrefix = 'searchable-select',
//   isLoading = false,
//   selectProps,
// }) => {
  
//   return (
//     <Select<OptionType, false, GroupBase<OptionType>>
//     options={options}
//     value={value}
//     onChange={onChange}
//     placeholder={placeholder}
//     isDisabled={isDisabled}
//     className={`${styles[classNamePrefix]} ${className || ''}`}
//     classNamePrefix={classNamePrefix}
//     isLoading={isLoading}
//     styles={{
//       menu: (provided) => ({
//         ...provided,
//         zIndex: 9999, // Ensure menu is above other elements
//       }),
//     }}
//     {...selectProps}
//   />
  
//   );
// };

// export default SearchableSelect;


import React from 'react';
import Select, { Props as SelectProps, GroupBase } from 'react-select';
import styles from './SearchableSelect.module.css';
import { OptionType } from '../../types/Dropdown';

interface SearchableSelectProps {
  options: OptionType[];
  value?: OptionType | null;
  onChange?: (selectedOption: OptionType | null) => void;
  placeholder?: string;
  isDisabled?: boolean;
  className?: string;
  classNamePrefix?: string;
  isLoading?: boolean;
  selectProps?: Omit<SelectProps<OptionType, false, GroupBase<OptionType>>, 'options' | 'value' | 'onChange' | 'placeholder' | 'isDisabled' | 'className' | 'classNamePrefix' | 'isLoading'>;
  disabled?: boolean;
}

const SearchableSelect: React.FC<SearchableSelectProps> = ({
  options,
  value,
  onChange,
  placeholder = 'Select...',
  isDisabled = false,
  className,
  classNamePrefix = 'searchable-select',
  isLoading = false,
  selectProps,
  disabled=false
}) => {
  return (
    <Select<OptionType, false, GroupBase<OptionType>>
      options={options}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      isDisabled={isDisabled || disabled}
      className={`${styles[classNamePrefix]} ${className || ''}`}
      classNamePrefix={classNamePrefix}
      isLoading={isLoading}
      isClearable // Using the built-in isClearable
      styles={{
        menu: (provided) => ({
          ...provided,
          zIndex: 9999, // Ensure menu is above other elements
        }),
      }}
      {...selectProps}
      
    />
  );
};

export default SearchableSelect;
