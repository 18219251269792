import CancelIcon from '@mui/icons-material/Cancel'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import { useRef, useState } from 'react'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { logout } from '../../../app-context/current-user-context/CurrentLoginUserAction';
import { useCurrentUserState } from '../../../app-context/current-user-context/CurrentUserLoginState';
const Profile = () => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)
  const [isOpen, setIsOpen] = useState(false)
  const {currentUser}=useCurrentUserState()

  const handleImageClick = () => {
    setIsOpen(!isOpen)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleMouseEnter = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    setIsOpen(true)
  }

  const handleMouseLeave = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = setTimeout(() => {
      setIsOpen(false)
    }, 200) // Adjust the delay time as needed
  }

  const handleDropdownMouseEnter = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
  }

  const handleDropdownMouseLeave = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = setTimeout(() => {
      setIsOpen(false)
    }, 200) // Adjust the delay time as needed
  }



  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {/* <img
        src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ87XxUGe-FD274ak-HKpGM9bTyVthPTC_3cNYujfjKGngPQjPi91zp2BQ4Eg&s'
        alt='User'
        className='rounded-full h-12 w-12 object-cover'
        onClick={handleImageClick}
      /> */}
      <div
        className='relative flex items-center cursor-pointer p-2 hover:bg-gray-100 rounded-lg'
        onClick={handleImageClick}
      >
        <img
          src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ87XxUGe-FD274ak-HKpGM9bTyVthPTC_3cNYujfjKGngPQjPi91zp2BQ4Eg&s'
          alt='User'
          className='h-12 w-12 object-cover'
          style={{ borderRadius: '4px' }} // Square or slightly rounded corners
        />
        <div className='ml-2 flex flex-col justify-center'>
          <p className='text-black text-sm font-medium leading-tight'>
            {currentUser.name}
          </p>
          <p className='text-black text-sm font-medium mt-0.5'>
            Name
          </p>
        </div>
        <ArrowDropDownIcon className='text-black ml-2' />
      </div>
      {/* </div> */}
      {isOpen && (
        <div
          onMouseEnter={handleDropdownMouseEnter}
          onMouseLeave={handleDropdownMouseLeave}
          className='fixed'
          style={{ right: 50, top: 58, width: '220px' }}
        >
          <div className='bg-white p-4 rounded shadow-lg'>
            {/* Profile picture */}
            <img
              src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ87XxUGe-FD274ak-HKpGM9bTyVthPTC_3cNYujfjKGngPQjPi91zp2BQ4Eg&s'
              alt='User'
              className='rounded-full h-20 w-20 object-cover mx-auto mb-4'
            />
            <CancelIcon
              onClick={handleClose}
              className='cursor-pointer'
              style={{
                position: 'absolute',
                right: '10px',
                top: '10px',
                color: 'red',
              }}
            />
            <div className='flex items-center mb-2'>
              {/* Role icon */}
              <AccountBoxIcon style={{ color: '#93AEC8', fontSize: '35px' }} />
              {/* Role text */}
              <div className='ml-2'>
                <p>Name</p>
                <p className='text-gray-500 text-sm'>{currentUser.name}</p>
              </div>
            </div>
            {/* Profile details */}
            <div className='flex items-center mb-2'>
              {/* Role icon */}
              <ManageAccountsIcon
                style={{ color: '#91C467', fontSize: '35px' }}
              />
              {/* Role text */}
              <div className='ml-2'>
                <p>Role</p>
                <p className='text-gray-500 text-sm break-all' style={{ wordBreak: 'break-all' }}>rolename</p>
              </div>
            </div>
            <div className='flex items-center mb-2'>
              {/* Role icon */}
              <MailOutlineIcon style={{ color: '#93AEC8', fontSize: '35px' }} />
              {/* Role text */}
              <div className='ml-2'>
                <p>Email</p>
                <p className='text-gray-500 text-sm'>{currentUser.email}</p>
              </div>
            </div>

            <button
              className='block w-full mt-4 bg-red-500 text-white py-2 rounded hover:bg-red-600'
              onClick={() => {
                logout()
              }}
            >
              Logout
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default Profile
