import { Dialog, DialogProps } from '@mui/material'
import React, { ReactNode, useEffect, useState } from 'react'
import './Modal.css'

interface ModalProps extends Omit<DialogProps, 'open' | 'onClose'> {
  toggleModal: boolean
  onClose: () => void
  children?: ReactNode
}

const Modal: React.FC<ModalProps> = ({
  toggleModal,
  onClose,
  children,
  sx,
  className,
  style,
}) => {
  const [isOpen, setIsOpen] = useState(toggleModal)

  useEffect(() => {
    setIsOpen(toggleModal)
  }, [toggleModal])
  return (
    <Dialog
      sx={sx}
      className={`modal-dialog ${isOpen ? 'modal-enter' : 'modal-exit'} ${className}`}
      style={style}
      open={toggleModal}
      onClose={onClose}
    >
      {children}
    </Dialog>
  )
}

export default Modal
