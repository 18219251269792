import React, { useEffect, useRef, useState } from 'react'
import { Device } from '../../../types/Device';
import { baseUrlAcWS, baseUrlDevice } from '../../../utils/env/env.config';
import { http } from '../../../utils/helper/http';
import Loader from '../../../components/loader/Loader';
import MobileTowerIcon from '../../../components/mobile-tower/MobileTowerIcon';
import editLogo from '../../../assets/images/edit_1159633.png'
import temp from '../../../assets/images/temp2.jpeg'
import hum from '../../../assets/images/humidity.jpeg'
import { CircularProgress, Switch, TextField } from '@mui/material';
import Card from '../../../components/card/Card';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '../../../components/modal/Modal';
import { io, Socket } from "socket.io-client";
import { useDeviceState } from '../../../app-context/device-context/DeviceState';
import LineChart from '../../../components/chart/line-chart/LineChart';
import { SensorInfo } from '../../../workers/device-data-worker/dataWorker';


interface DashboardACMonitoringProps {
  deviceState: Device;
}

interface SensorData {
  [key: string]: string | number;
}

interface ResponseData {
  deviceData: SensorData[];
}

const DashboardACMonitoring: React.FC<DashboardACMonitoringProps> = ({ deviceState }) => {
  const [data, setData] = useState<SensorData[]>([]);
  const [dashBoardSts, setDashboardSts] = useState<SensorData>();
  const [sensorInfo, setSensorInfo] = useState<SensorInfo>();
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const chartRef = useRef<HTMLDivElement>(null);
  const modIORef = useRef<Socket | null>(null);
  const workerRefSensor = useRef<Worker | null>(null);
  const workerRefLineChart = useRef<Worker | null>(null);
  const [tempData1, setTempData1] = useState<string>("");
  const [tempData, setTempData] = useState<string>("");
  const [openSetTemp, setOpenSetTemp] = useState<boolean>(false);
  const [openLoader, setOpenLoader] = useState<boolean>(false);
  const responseTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const { device: d } = useDeviceState();
  const [acLineChart, setACLineChart] = useState<{
    seriesData: {
      name: string;
      data: number[];
      color: string;
    }[];
    categories: string[];
  }>({
    categories: [],
    seriesData: [],
  });



  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await http(
        `${baseUrlDevice}devices-data/influxData/last24hour?deviceId=${deviceState._id}`
      );
      const dataAsSensor = response as ResponseData;
      setData(dataAsSensor.deviceData);
      setDashboardSts(dataAsSensor.deviceData[0]);
      setChecked(dataAsSensor.deviceData[0].status === "ON" ? true : false);
      setTempData1(dataAsSensor.deviceData[0].set_temp as string)
      setTempData(dataAsSensor.deviceData[0].set_temp as string)
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    } finally {
      setLoading(false);

    }
  };

  const publishToMQTT = () => {
    if (modIORef.current) {
      modIORef.current.disconnect();
      modIORef.current = null;
    }
    const IO = io(`${baseUrlAcWS}ac-config?deviceId=${deviceState._id}`);

    modIORef.current = IO;


    IO.on("ac-response", (data) => {
      if (data) {
        console.log("daaatataa", data);

        // clearTimeout(responseTimeoutRef.current);
        setOpenLoader(false);
        // if(data.ac_status) {
        //   if(data.status === "SUCCESS"){
        //     // toast.success(`AC ${data.ac_status} successfully.`);
        //     setChecked(data.ac_status === "ON" ? true : false);
        //   }else if(data.status === "ERROR_0"){
        //     // toast.error("Automation Failed. Ensure remote pairing is configured correctly or consult the administrator.")
        //   } else{
        //     // toast.error("Automation failed");
        //   }
        // } else if(data.set_Value){
        //   if(data.status === "SUCCESS"){
        //     toast.success("Set Temp successfully.");
        //     setTempData1(data.set_Value)
        //   } else if (data.status === "ERROR_0"){
        //     toast.error("Automation Failed. Ensure remote pairing is configured correctly or consult the administrator.")
        //   }else if(data.status === "ERROR_1"){
        //     toast.error(`The set temperature must be between ${data.temp_min}°C and ${data.temp_max}°C.`)
        //   }else{
        //     toast.error("Automation failed");
        //   }
        // }
      }
    });

    return () => {
      if (modIORef.current) {
        modIORef.current.disconnect();
        modIORef.current = null;
      }
    };
  };

  useEffect(() => {
    workerRefSensor.current = new Worker(
      new URL('../../../workers/sensor-worker/sensorWorker.ts', import.meta.url)
    );
    workerRefSensor.current.onmessage = function (e) {
      const { sensorInfo } = e.data;
      setSensorInfo(sensorInfo);

    };

    workerRefLineChart.current = new Worker(
      new URL('../../../workers/chart-worker/chartWorker.ts', import.meta.url)
    );
    workerRefLineChart.current.onmessage = function (e) {
      const { seriesData, categories } = e.data;
      setACLineChart({ seriesData, categories })
    };
  }, []);

  // const temperatureObject = data.reduce((acc, entry) => {
  //   acc[entry.timeStamp] = entry.temp;
  //   return acc;
  // }, {});


  const handleChangeSwitch = async () => {
    setOpenLoader(true);
    const device: Device = d.device.find((d) => d._id === deviceState._id) as Device;
    const message = {
      status: checked ? "OFF" : "ON",
      IMEI: device?._id,
    };
    try {
      modIORef.current?.emit("ac-config-data", message);
      responseTimeoutRef.current = setTimeout(() => {
        // toast.warning("Gateway communication failed.");
        setOpenLoader(false);
      }, 30000);
    } catch (error) {
      console.error(error);
    }

  }

  const handleClickOpenSetTemp = () => {
    setOpenSetTemp(true);
  };

  const handleClickCloseSetTemp = () => {
    setOpenSetTemp(false);
  }

  const handleChangeTesxt = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTempData(e.target.value);
  };

  const handleSaveClick = () => {
    console.log("save", tempData);
    setOpenSetTemp(false);
    setOpenLoader(true);

  }

  const handleClickCloseLoader = () => {
    setOpenLoader(false)
  }


  useEffect(() => {
    if (workerRefLineChart.current && data.length > 0) {
      const names: string[] = [];
      const keyNames: string[] = [];
      const colors = ['117DAE', 'DD0000'];

      if (sensorInfo?.hum.status) {
        names.push(sensorInfo.hum.displaySensorName);
        keyNames.push('hum');
      }
      if (sensorInfo?.temp.status) {
        names.push(sensorInfo.temp.displaySensorName);
        keyNames.push('temp');
      }

      workerRefLineChart.current.postMessage({
        data: data,
        names,
        keyNames,
        colors,
      })
    }
  }, [data])

  useEffect(() => {
    if (deviceState.tags && workerRefSensor.current) {
      workerRefSensor.current.postMessage({ tags: deviceState.tags });
    }
    fetchData();
    publishToMQTT();
  }, [deviceState]);


  return (
    <>
      {loading && <Loader showGradient={loading} />}
      <div className="bg-white min-h-10 w-full md:w-[70%]  p-1 rounded-lg shadow-md">
        <div className="flex flex-col md:flex-row items-center w-full mt-1">
          <div className="text-deepcerulan-600 mb-0 md:mb-0 md:w-[45%]">
            <span className="font-bold">Device ID:</span>{' '}
            {deviceState.imeiNumber}
          </div>
          <div className="flex items-center md:ml-0 md:w-1/2">
            <MobileTowerIcon levels={parseInt(dashBoardSts?.rssi as string)} time={dashBoardSts?.timeStamp as string} />
            <p className="text-sm text-gray-500 ml-0 whitespace-nowrap">
              Last data received at: {dashBoardSts?.timeStamp}
            </p>
          </div>
        </div>
      </div>
      <div className="border border-gray-400 rounded-md p-2 grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5 mb-2">
        <div className="border border-stroke p-3 bg-white rounded-md">
          <div className="flex">
            <div className="w-3/4">
              <div className="mt-2 flex items-end justify-between">
                <div>
                  <h2 className="text-[16px]">AC Status</h2>
                  <h4
                    style={{ color: checked ? "green" : "red" }}
                    className="text-title-md font-bold text-black text-[24px] py-1"
                  >
                    {checked ? "ON" : "OFF"}{" "}
                  </h4>
                </div>

                <div className="w-1/4 mt-4 flex justify-center items-center rounded-sm">
                  <Switch
                    className="ml-20"
                    checked={checked}
                    onChange={handleChangeSwitch}
                    color="primary"
                    sx={{
                      "& .MuiSwitch-switchBase.Mui-checked": {
                        color: "green",
                      },
                      "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                      {
                        backgroundColor: "green",
                      },
                      "& .MuiSwitch-track": {
                        backgroundColor: "red",
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Card
          heading="Set Temperature"
          value={`${tempData1 ? tempData1 : "N/A"} \u2103`}
          img={editLogo}
          onClick={handleClickOpenSetTemp}
        />
        {sensorInfo?.['temp']?.status &&(
          <Card
            heading={sensorInfo.temp.status ? sensorInfo.temp.displaySensorName : "Temperature"}
            value={`${dashBoardSts ? dashBoardSts.temp : "N/A"} ${sensorInfo.temp.status ? sensorInfo.temp.displaySensorUnit : "\u2103"}`}
            img={temp}
          />
        )}
        {sensorInfo?.['hum']?.status &&(
          <Card
            heading={sensorInfo.hum.status ? sensorInfo.hum.displaySensorName : "Humidity"}
            value={`${dashBoardSts ? dashBoardSts.hum : "N/A"} ${sensorInfo.hum.status ? sensorInfo.hum.displaySensorUnit : "%"}`}
            img={hum}
          />
        )}
      </div>
      <div className="pt-[5px]">
        <Modal toggleModal={openSetTemp} onClose={handleClickCloseSetTemp}>
          <div className="p-6">
            <div className="flex">
              <div className="w-[96%]">
                <p className="text-lg font-sans-serif text-[20px]">
                  Edit Installation Details
                </p>
              </div>
              <div className="flex justify-end">
                <button
                  className="text-gray-500 hover:text-gray-800"
                  onClick={handleClickCloseSetTemp}
                >
                  <CloseIcon />
                </button>
              </div>
            </div>
            <div className="mt-3">
              <TextField
                margin="dense"
                name="temperature"
                label="Set Temperature"
                type="text"
                fullWidth
                value={tempData}
                onChange={handleChangeTesxt}
              />
              <div className="mt-14 flex justify-end">
                <button
                  className="border py-2 px-4 rounded-md font-sans-serif text-[14px]"
                  onClick={handleClickCloseSetTemp}
                >
                  Cancel
                </button>
                <button
                  className="border py-2 px-6 rounded-md ml-4 font-sans-serif text-[14px]"
                  onClick={handleSaveClick}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          toggleModal={openLoader}
          onClose={handleClickCloseLoader}
          sx={{
            "& .MuiDialog-paper": {
              backgroundColor: "transparent",
              boxShadow: "none",
            },
            "& .MuiBackdrop-root": {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              textAlign: "center",
            }}
          >
            <CircularProgress />
            <p style={{ fontWeight: "bold" }}>
              Processing your request... Please wait
            </p>
          </div>
        </Modal>
      </div>

      <div className="border border-gray-400 rounded-md p-2">
        <div className="relative">
          <div className="absolute top-0 right-0">
            {/* <LongMenu
              options={["Download as CSV", "Download as PDF"]}
              onOptionClick={handleExportBarChart}
              id={1}
            /> */}
          </div>
        </div>
        <p>
          Temperature & Humidity v/s Status data variation with time (between{" "}
          {data.length > 0
            ? data[data.length - 1].timeStamp +
            " to " +
            data[0].timeStamp
            : " "}
          )
        </p>

        <div ref={chartRef} style={{ marginTop: "15px" }}>
          <LineChart
            categories={acLineChart.categories || []}
            series={acLineChart.seriesData || []}
            height={450}
            yAxisTitle="Data"
          />
        </div>
      </div>

    </>
  )
}

export default DashboardACMonitoring