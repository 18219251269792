import { useEffect, useState } from 'react'
import { useNavigate, Outlet } from 'react-router-dom'

const ProtectedRoute = () => {
  const navigate = useNavigate()
  const [token] = useState(window.localStorage.getItem('token'))

  useEffect(() => {
    if (!token) {
      window.localStorage.removeItem('token')
      navigate('/')
    }
  }, [token, navigate])
  const waitTillLoaded = () => {
    if (!token) {
      window.localStorage.removeItem('token')
      navigate('/')
    } else {
      return (
        <>
          <Outlet />
        </>
      )
    }
  }

  return <>{waitTillLoaded()}</>
}

export default ProtectedRoute
