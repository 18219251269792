import { PermissionsObject } from '../../types/PermissionInterfaces'
import { decodeToken } from '../../utils/decode-token/TokenDecode'
import { checkPermissions } from '../../utils/permission/Permission'
import { PermissionAction } from './PermissionAction'

export interface PermissionState {
  permissions: PermissionsObject
}

export default function PermissionReducer(
  state: PermissionState,
  action: PermissionAction,
): PermissionState {
  const { type, payload } = action

  switch (type) {
    case 'UPDATE_PERMISSION':{
      const { permissionName, permissionValue } = payload
      const decode = decodeToken(permissionValue)
      const updatedPermissionData = checkPermissions(
        permissionName,
        decode.permissions,
      )      
      return {
        ...state,
        permissions: updatedPermissionData,
      }
    }
    default:
      return state
  }
}
