import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { DataRow } from '../../types/table';

interface LongMenuProps {
  options: string[];
  onOptionClick: (option: string, id?: string | number, state?: DataRow) => void;
  id?: string | number;
  state?: DataRow;
}

const ITEM_HEIGHT = 48;

const LongMenu: React.FC<LongMenuProps> = ({ options, onOptionClick, id, state }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (option: string) => {
    if (onOptionClick && id !== undefined && state !== undefined) {
      onOptionClick(option, id, state);
    } else if (onOptionClick) {
      onOptionClick(option); // Fallback if `id` and `state` are optional
    }
    handleClose();
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
        {Array.isArray(options) ? (
          options.map((option) => (
            <MenuItem key={option} onClick={() => handleMenuItemClick(option)}>
              {option}
            </MenuItem>
          ))
        ) : (
          <MenuItem onClick={handleClose}>Invalid options</MenuItem>
        )}
      </Menu>
    </div>
  );
};

export default LongMenu;
