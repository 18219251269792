import React, { useEffect, useState, useRef } from 'react';
import { io as socketIOClient } from 'socket.io-client';
import { baseUrlMqttWatcher, baseUrlMqttWatcher1 } from '../../utils/env/env.config';

const MqttWatcher: React.FC = () => {
    const [messages, setMessages] = useState<string[]>([]);
    const [activeTab, setActiveTab] = useState<'publish' | 'subscribe' | 'listen'>('listen');
    const [publishTopic, setPublishTopic] = useState<string>('');
    const [publishData, setPublishData] = useState<string>('{}'); // Default empty JSON object
    const [subscribeTopic, setSubscribeTopic] = useState<string>('');
    const [subscribedTopic, setSubscribedTopic] = useState<string>(''); // Track the subscribed topic
    const [subscribeMessages, setSubscribeMessages] = useState<string[]>([]); // Messages for subscribed topic
    const messagesEndRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const socket = socketIOClient(baseUrlMqttWatcher);

        socket.onAny((event, data) => {
            setMessages(prevMessages => [...prevMessages, data]);
            if (event === subscribedTopic) {
                setSubscribeMessages(prevMessages => [...prevMessages, data]);
            }
        });

        socket.on('disconnect', () => {
            // handle disconnect
        });

        return () => {
            socket.disconnect();
        };
    }, [subscribedTopic]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            scrollToBottom();
        }, 0);
        return () => clearTimeout(timeout);
    }, [messages, subscribeMessages]);

    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
    };

    const handleClearMessages = () => {
        setMessages([]);
    };

    const handleClearSubscribeMessages = () => {
        setSubscribeMessages([]);
    };

    const handlePublish = async () => {
        if (!publishTopic.trim()) {
            return;
        }
        try {
            const jsonData = JSON.parse(publishData);
            const messageString = JSON.stringify(jsonData);
            const response = await fetch(baseUrlMqttWatcher1 + 'publish', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ topic: publishTopic, message: messageString }),
            });

            if (response.ok) {
                // handle success
            } else {
                // handle failure
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleSubscribe = async () => {
        if (!subscribeTopic.trim()) {
            return;
        }
        try {
            const response = await fetch(baseUrlMqttWatcher1 + 'subscribe', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ topic: subscribeTopic }),
            });

            if (response.ok) {
                setSubscribedTopic(subscribeTopic); // Update subscribed topic
            } else {
                // handle failure
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const renderTabContent = () => {
      switch (activeTab) {
        case 'publish':
          return (
              <div className="flex flex-col h-full">
                  <div className="flex-1 flex flex-col p-4 space-y-2">
                      <div className="mb-1">
                          <label htmlFor="publishTopic" className="block text-sm font-medium text-gray-700 border-1 border-gray-400">Topic to Publish:</label>
                          <input
                              type="text"
                              id="publishTopic"
                              className="mt-1 block w-full border-blue-400 border-2 p-2 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                              value={publishTopic}
                              onChange={(e) => setPublishTopic(e.target.value)}
                          />
                      </div>
                      <div className="border-1 border-blue-600 p-2 flex-1">
                          <label htmlFor="publishData" className="block text-sm font-medium text-gray-700">JSON Data Body:</label>
                          <textarea
                              id="publishData"
                              className="border-2 border-blue-400 mt-1 block w-full rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 resize-none"
                              style={{ minHeight: '200px' }} // Adjust this value as needed
                              value={publishData}
                              onChange={(e) => setPublishData(e.target.value)}
                          />
                      </div>
                  </div>
                  <button className='text-white p-2 ml-2 mb-4 rounded-md bg-[#087db0]' onClick={handlePublish}>Publish</button>
              </div>
          );
          case 'subscribe':
              return (
                  <div className="flex flex-col h-full">
                      <div className="mb-1 border-1 border-blue-600 p-1">
                          <label htmlFor="subscribeTopic" className="block text-sm font-medium text-gray-700 border-1 border-gray-400">Topic to Subscribe:</label>
                          <input
                              type="text"
                              id="subscribeTopic"
                              className="mt-1 block w-full border-2 p-1 border-blue-400 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                              value={subscribeTopic}
                              onChange={(e) => setSubscribeTopic(e.target.value)}
                          />
                      </div>
                      <button className='bg-deepcerulan rounded-lg p-2 text-white' onClick={handleSubscribe}>Subscribe</button>
                      {subscribedTopic && <div className="mt-4">Subscribed to: {subscribedTopic}</div>}
                      <div className="flex-1 grid grid-cols-1 rounded-lg border-2 border-blue-400 gap-4 overflow-auto mt-4">
                          {subscribeMessages.map((message, index) => (
                              <div key={index} className="bg-gray-200 p-4 rounded-md">{message}</div>
                          ))}
                          <div ref={messagesEndRef} />
                      </div>
                      <button className='bg-deepcerulan rounded-lg p-2 text-white mt-4' onClick={handleClearSubscribeMessages}>Clear Messages</button>
                  </div>
              );
          case 'listen':
          default:
              return (
                  <div className="flex flex-col h-full">
                      <div className="flex-1 grid grid-cols-1 rounded-lg border-2 border-blue-400 gap-4 overflow-auto mt-4 h-full max-h-[calc(100vh-150px)]"> {/* Adjust max height based on header/footer space */}
                          {messages.map((message, index) => (
                              <div key={index} className="bg-gray-200 p-4 rounded-md">{message}</div>
                          ))}
                          <div ref={messagesEndRef} />
                      </div>
                      <button className='bg-deepcerulan rounded-lg mt-2 p-2 text-white' onClick={handleClearMessages}>Clear Messages</button>
                  </div>
              );
      }
  };
  

    return (
        <div className="flex flex-col h-screen p-4 mt-4 bg-white rounded-3xl">
            <h2 className="text-xl font-semibold mb-4">MQTT Data</h2>
            <div className="flex border-b border-gray-300 mb-4">
                <button
                    className={`px-4 py-2 text-sm font-medium ${activeTab === 'publish' ? 'border-b-2 border-blue-500 text-blue-600' : 'text-gray-500 hover:text-gray-700'}`}
                    onClick={() => setActiveTab('publish')}
                >
                    Publish
                </button>
                <button
                    className={`px-4 py-2 text-sm font-medium ${activeTab === 'subscribe' ? 'border-b-2 border-blue-500 text-blue-600' : 'text-gray-500 hover:text-gray-700'}`}
                    onClick={() => setActiveTab('subscribe')}
                >
                    Subscribe
                </button>
                <button
                    className={`px-4 py-2 text-sm font-medium ${activeTab === 'listen' ? 'border-b-2 border-blue-500 text-blue-600' : 'text-gray-500 hover:text-gray-700'}`}
                    onClick={() => setActiveTab('listen')}
                >
                    Listen
                </button>
            </div>
            <div className="flex-1">
                {renderTabContent()}
            </div>
        </div>
    );
};

export default MqttWatcher;
