import { AlertData } from "../../types/Alert"
import { AlertAction } from "./AlertAction"

export interface AlertState {
    alert: AlertData,
    loading: boolean
}

export default function AlertReducer(
    state: AlertState,
    action: AlertAction,
): AlertState {
    const { type, payload } = action

    switch (type) {
        case 'SET_LOADING':
            return {
                ...state,
                loading: payload,
            };
        case 'SET_ALERT': {
            return { ...state, alert: payload, loading: false }
        }
        case 'ADD_ALERT': {
            const { alert: newDevice, totalItems } = payload
            //   let organizat
            //   if (
            //     state.organizations.organizations.length === 10 ||
            //     state.organizations.organizations.length === 25 ||
            //     state.organizations.organizations.length === 5
            //   ) {
            //     organizat = state.organizations.organizations.slice(0, -1)
            //   } else {
            //     organizat = state.organizations.organizations
            //   }

            return {
                ...state,
                alert: {
                    ...state.alert,
                    alert: [newDevice, ...state.alert.alert],
                    totalItems: totalItems + 1,
                },
            }
        }
        case 'UPDATE_ALERT': {
            const { id: updateId, alert } = payload
            const updateAlert = state.alert.alert.map((dev) =>
                dev._id === updateId ? { ...dev, ...alert } : dev,
            )
            return {
                ...state,
                alert: {
                    ...state.alert,
                    alert: updateAlert,
                },
            }
        }
        case "UPDATE_ALERT_STATUS": {
            const { _id, status, key } = payload;
            const alertToUpdate = state.alert.alert.find((alert) => alert._id === _id);
            if (alertToUpdate) {
                if (key === "SMS_STATUS") {
                    alertToUpdate.smsStatus = status;
                } else if (key === "EMAIL_STATUS") {
                    alertToUpdate.emailStatus = status
                } else if(key === "ALERT_STATUS"){
                    alertToUpdate.alertStatus = status;
                }
                // Clone the devices array to trigger a state update
                const updatedAlertAfterStatusUpdate = [...state.alert.alert];
                // Update the state with the new devices array
                return {
                    ...state,
                    alert: {
                        ...state.alert,
                        alert: updatedAlertAfterStatusUpdate,
                    },
                };
            }
            return state;
        }
        default:
            return state
    }
}
