import React, {
    createContext,
    useReducer,
    ReactNode,
    Dispatch,
    useContext,
    useEffect,
} from 'react'
import AlertReducer, { AlertState } from './AlertReducer'
import { AlertAction } from './AlertAction'


interface AlertContextProps {
    state: AlertState
    dispatch: Dispatch<AlertAction>
}

const initialState: AlertState = {
    alert: {
        limit: 0,
        alert: [],
        currentPage: 0,
        totalPages: 0,
        previousPage: 0,
        totalItems: 0,
    },
    loading: false,
}

export const AlertContext = createContext<AlertContextProps>({
    state: initialState,
    dispatch: () => { },
})

interface AlertProviderProps {
    children: ReactNode
}

export const AlertProvider: React.FC<AlertProviderProps> = ({ children }) => {
    const [state, dispatch] = useReducer(AlertReducer, initialState)

    useEffect(() => {
        // setDevice(dispatch, {page: 1, limit: 10}) // Call setOrganization only once when the component mounts
    }, []) // Empty dependency array ensures it only runs on mount, not on updates

    return (
        <AlertContext.Provider
            value={{
                state,
                dispatch,
            }}
        >
            {children}
        </AlertContext.Provider>
    )
}

export const useAlertState = () => {
    const { state, dispatch } = useContext(AlertContext)
    return { ...state, dispatch }
}
