import React from 'react'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { SeriesData } from '../../../types/Chart';


interface SplineChartProps {
    title: string;
    categories: string[];
    series: SeriesData[];
    xAxisTitle?: string; 
    yAxisTitle?: string; 
    height?: number; 
  }

  const SplineChart: React.FC<SplineChartProps> = ({ title, categories, series,height, yAxisTitle , xAxisTitle}) => {
    const processedSeries = series.map((s) => ({
      ...s,
      data: s.data.map((value) => {
        if (typeof value === 'string') {
          const parsedValue = parseInt(value, 10);
          return isNaN(parsedValue) ? 0 : parsedValue;
        }
        return typeof value === 'number' ? Math.floor(value) : 0;
      }),
    }));
    

    const options = {
        chart: {
            type: 'spline', 
            height: height,
          },
          title: {
            text: title,
          },
          accessibility: {
            enabled: true,
          },
          xAxis: {
            categories: categories,
            title: {
              text: xAxisTitle ? xAxisTitle : 'Categories',
            },
            lineWidth: 0, // Hide x-axis line
            tickWidth: 0,
          },
          yAxis: {
            title: {
              text: yAxisTitle ? yAxisTitle : 'Values',
            },
            gridLineWidth: 0, // Hide grid lines on y-axis
            lineWidth: 0,
          },

          plotOptions: {
            spline: {
                lineWidth: 4,
                states: {
                    hover: {
                        lineWidth: 5
                    }
                },
                marker: {
                    enabled: false
                },
            }
        },
        series: processedSeries.map((s) => ({
            ...s, 
            color: s.color || undefined, 
          })),
        credits: {
          enabled: false, // Disable the watermark
        },
    }
  return (
  <HighchartsReact  highcharts={Highcharts} options={options}/>
  )
}

export default SplineChart;
