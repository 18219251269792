// src/components/ToastProvider.tsx
import React, { createContext, useState, useContext, useCallback, ReactNode } from 'react';
import CustomToast from './CustomToast';

interface ToastContextType {
  showToast: (message: string, type?: 'success' | 'error' | 'hint', duration?: number) => void;
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

export const useToast = (): ToastContextType => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};

interface ToastProviderProps {
  children: ReactNode;
}

interface ToastState {
  message: string;
  type: 'success' | 'error' | 'hint';
  duration: number;
}

const CustomToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
  const [toast, setToast] = useState<ToastState>({
    message: '',
    type: 'success',
    duration: 3000,
  });

  const showToast = useCallback((message: string, type: 'success' | 'error' | 'hint' = 'success', duration = 3000) => {
    setToast({ message, type, duration });
  }, []);

  const handleClose = () => {
    setToast({ message: '', type: 'success', duration: 3000 });
  };

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      {toast.message && (
        <CustomToast
          message={toast.message}
          type={toast.type}
          duration={toast.duration}
          onClose={handleClose}
        />
      )}
    </ToastContext.Provider>
  );
};

export default CustomToastProvider;
