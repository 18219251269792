import React from 'react';
import './loader.css';
import loader from '../../assets/loader/loader.gif'

interface CommonComponentProps {
  showGradient?: boolean;
  showLoader?: boolean;
  showCircularLoader?: boolean;
  gifLoader?: boolean;
  showCenterLoader?: boolean;
  loaderHeight?: string;
  loaderWidth?: string;
  isBlocking?: boolean;
  wrapCircularLoader?: boolean;

}

const Loader: React.FC<CommonComponentProps> = ({ showGradient, showLoader, showCircularLoader, gifLoader, showCenterLoader, isBlocking,
  loaderHeight = '50px',
  loaderWidth = '50px',
  wrapCircularLoader = false,
}) => {
  return (
    <>
      {showGradient && <div className="linear-gradient"></div>}

      {showLoader && (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      )}
      {showCircularLoader && (
        <div className="circular-loader-container">
          <div className="circular-loader"></div>
        </div>
      )}
      {gifLoader && (
        <div className="circular-loader-container">
          <img src={loader} alt="" className='h-20 w-20 bg-white rounded-full p-2' />
        </div>
      )}

      {showCenterLoader && (
        <div className="center-loader-container">
          <div className="center-loader" style={{ width: loaderWidth, height: loaderHeight }}></div>
        </div>
      )}

      {wrapCircularLoader && (
        <div className="wrap-circular-loader">
          <div className="center-loader" style={{ width: loaderWidth, height: loaderHeight }}></div>
        </div>
      )}

      {isBlocking && <div className="blocking-overlay"></div>}
    </>
  );
};

export default Loader;
