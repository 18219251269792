import React, { useEffect, useRef, useState } from 'react'
import { Device } from '../../../types/Device';
import MobileTowerIcon from '../../../components/mobile-tower/MobileTowerIcon';
import SensorCard from '../../../components/card/SensorCard';
import LineChart from '../../../components/chart/line-chart/LineChart';
import { SensorInfo } from '../../../workers/device-data-worker/dataWorker';
import { http } from '../../../utils/helper/http';
import { baseUrlDevice } from '../../../utils/env/env.config';
import Loader from '../../../components/loader/Loader';

interface DashboardWaterTotalizerJoinProps {
  deviceState: Device;
}

interface SensorData {
  [key: string]: string | number;
}

interface ResponseData {
  deviceData: SensorData[];
}
const DashboardWaterTotalizerJoin: React.FC<DashboardWaterTotalizerJoinProps> = ({
  deviceState,
}) => {
  const [data, setData] = useState<SensorData[]>([]);
  const [dashBoardSts, setDashboardSts] = useState<SensorData>();
  const [sensorInfo, setSensorInfo] = useState<SensorInfo>();
  const workerRefSensor = useRef<Worker | null>(null);
  const workerRefLineChart = useRef<Worker | null>(null);
  const [loading, setLoading] = useState(false);
  const [viewMode, setViewMode] = useState<'graphFlow' | 'graphTotalizer'>('graphFlow');
  const [totalizerFlowLineChart, setTotalizerFlowLineChart] = useState<{
    seriesData: {
      name: string;
      data: number[];
      color: string;
    }[];
    categories: string[];
  }>({
    categories: [],
    seriesData: [],
  });

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await http(
        `${baseUrlDevice}devices-data/influxData/last24hour?deviceId=${deviceState._id}`
      );
      const dataAsSensor = response as ResponseData;

      const updatedData = dataAsSensor.deviceData.map(item => {
        const posCumuFlowInt = parseFloat(item.Pos_Cumu_flow_Int as string);
        const posCumuFlowDec = parseFloat(item.Pos_Cumu_flow_Dec as string);
        const totalizer = posCumuFlowInt + posCumuFlowDec;
        return {
          ...item,
          totalizer: totalizer.toFixed(3)
        };
      });
      setData(updatedData);
      setDashboardSts(updatedData[0]);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    } finally {
      setLoading(false);

    }
  };

  console.log("dddddaaatttttaaaaaa", totalizerFlowLineChart);

  useEffect(() => {
    workerRefSensor.current = new Worker(
      new URL('../../../workers/sensor-worker/sensorWorker.ts', import.meta.url)
    );
    workerRefSensor.current.onmessage = function (e) {
      const { sensorInfo } = e.data;
      setSensorInfo(sensorInfo);

    };

    workerRefLineChart.current = new Worker(
      new URL('../../../workers/chart-worker/chartWorker.ts', import.meta.url)
    );
    workerRefLineChart.current.onmessage = function (e) {
      const { seriesData, categories } = e.data;
      setTotalizerFlowLineChart({ seriesData, categories })
    };
  }, []);

  useEffect(() => {
    if (workerRefLineChart.current && data.length > 0) {
      const names: string[] = [];
      const keyNames: string[] = [];
      const colors = ['117DAE', 'DD0000'];

      if (sensorInfo?.Inst_flow_rate.status && viewMode === 'graphFlow') {
        names.push(sensorInfo.Inst_flow_rate.displaySensorName);
        keyNames.push('Inst_flow_rate');
      } else if (sensorInfo?.Pos_Cumu_flow_Dec.status && sensorInfo?.Pos_Cumu_flow_Int.status && viewMode === 'graphTotalizer') {
        names.push("Totalizer");
        keyNames.push('totalizer');
      }

      workerRefLineChart.current.postMessage({
        data: data,
        names,
        keyNames,
        colors,
      })
    }
  }, [data, viewMode])

  const handleButtonClick = (mode: 'graphFlow' | 'graphTotalizer') => {
    setViewMode(mode);
  };

  useEffect(() => {
    if (deviceState.tags && workerRefSensor.current) {
      workerRefSensor.current.postMessage({ tags: deviceState.tags });
    }
    fetchData();
  }, [deviceState]);
  return (
    <>
      {loading && <Loader showGradient={loading} />}
      <div className="p-4 space-y-2">
        <div className="bg-white min-h-10 w-full md:w-[70%]  p-1 rounded-lg shadow-md">
          <div className="flex flex-col md:flex-row items-center w-full mt-1">
            <div className="text-deepcerulan-600 mb-0 md:mb-0 md:w-[45%]">
              <span className="font-bold">Device ID:</span>{' '}
              {deviceState.imeiNumber}
            </div>
            <div className="flex items-center md:ml-0 md:w-1/2">
              <MobileTowerIcon levels={parseInt(dashBoardSts?.rssi as string)} time={dashBoardSts?.timeStamp as string} />
              <p className="text-sm text-gray-500 ml-0 whitespace-nowrap">
                Last data received at: {dashBoardSts?.timeStamp}
              </p>
            </div>
          </div>
        </div>

        <div className="p-2 bg-white w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 md:grid-cols-3  gap-4">
          {sensorInfo?.['Inst_flow_rate']?.status && (
            <SensorCard
              sensor={sensorInfo.Inst_flow_rate.status ? sensorInfo.Inst_flow_rate.displaySensorName : "Flow"}
              value={dashBoardSts ? dashBoardSts.Inst_flow_rate : "N/A"}
              unit={sensorInfo.Inst_flow_rate.status ? sensorInfo.Inst_flow_rate.displaySensorUnit : "m³"}
            />
          )}
          {sensorInfo?.['Pos_Cumu_flow_Dec']?.status && sensorInfo?.['Pos_Cumu_flow_Int']?.status && (
            <SensorCard
              sensor={sensorInfo.Pos_Cumu_flow_Dec.status ? sensorInfo.Pos_Cumu_flow_Dec.displaySensorName : "Totalizer"}
              value={dashBoardSts ? dashBoardSts.totalizer : "N/A"}
              unit={sensorInfo.Pos_Cumu_flow_Dec.status ? sensorInfo.Pos_Cumu_flow_Dec.displaySensorUnit : "m³"}
            />
          )}

        </div>

        {/* this  is for chart */}
        {/* Line Chart with Dynamic Buttons */}
        <div className='bg-white p-4'>
          <div className="relative">
            <div className="absolute top-0 right-0">
              {/* <LongMenu
              options={["Download as CSV", "Download as PDF"]}
              onOptionClick={handleExportBarChart}
              id={1}
            /> */}
            </div>
          </div>
          <p>
            {viewMode === 'graphFlow' ? "Flow" : "Totalizer"} data variation with time (between{" "}
            {data.length > 0
              ? data[data.length - 1].timeStamp +
              " to " +
              data[0].timeStamp
              : " "}
            )
          </p>
          <div className="button-group my-4">
            <button
              onClick={() => handleButtonClick('graphFlow')}
              className={`px-4 py-1 mr-2  rounded-lg  ${viewMode === 'graphFlow' ? 'bg-blue-500 text-white' : 'bg-gray-300'}`}
            >
              Flow
            </button>
            <button
              onClick={() => handleButtonClick('graphTotalizer')}
              className={`px-4 py-1  rounded-lg ${viewMode === 'graphTotalizer' ? 'bg-blue-500 text-white' : 'bg-gray-300'}`}
            >
              Totalizer
            </button>
          </div>
          <LineChart
            title=""
            categories={totalizerFlowLineChart.categories}
            series={totalizerFlowLineChart.seriesData}
            yAxisTitle="Data"
            xAxisTitle="Time"
            height={450}
          />
        </div>

      </div>
    </>
  )
}

export default DashboardWaterTotalizerJoin